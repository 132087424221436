import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { MatRadioChange } from '@angular/material';

@Component({
  selector: 'app-info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.scss']
})
export class InfoAlertComponent implements OnInit {
  selectedOption: string = ''
  policyOptions: string[] = ['Single Policy', 'Fleet Policy'];
  filter: any;
  dialogTitle: any;
  dialogMessage: any;

  constructor(public service: DashboardService, public dialogRef: MatDialogRef<InfoAlertComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private router: Router,private _snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.dialogTitle = this.data.dialogTitle
    this.dialogMessage = this.data.dialogMessage
  }



  radioChange(event: MatRadioChange) {
   // console.log(event.source.name, event.value);
    this.selectedOption = event.value

}
  
  onProceed(){
    this.dialogRef.close()
    this.router.navigate(["/login"])
  }
















  //trigger the snackbar
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onPinInput=(evt)=>{
    isNaN(+`${evt.target.value}`)
     if(isNaN(+`${evt.target.value}`))
     evt.target.value='';
  }
}
