import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-travelers',
  templateUrl: './travelers.component.html',
  styleUrls: ['./travelers.component.scss']
})
export class TravelersComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TravelersComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }
   emptyPair: any = {first_name: '', last_name: '',phone:'',dob:'',passport:''};
   pairs: any = [{...this.emptyPair}];

  pairValueChanged(idx) {
      if (idx === this.pairs.length - 1) {
          this.pairs.push({...this.emptyPair});
      }
  }

  //prevent under age
  dobFilter(date: Date){
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'),'years')>=18;
  }
  deletePair(idx) {
      this.pairs.splice(idx, 1);

      if (this.pairs.length === 0) {
          this.pairs.push({...this.emptyPair});
      }
  }
}
