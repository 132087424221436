import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { PostsComponent } from './modules/posts/posts.component';
import { LoginComponent } from './login/login.component';
import { QuotesComponent } from './modules/quotes/quotes.component';
import { RenewPolicyComponent } from './modules/renew-policy/renew-policy.component';
import { StickerlistingsComponent } from './modules/stickerlistings/stickerlistings.component';
import { ProductsComponent } from './modules/products/products.component';
import { AgtransactionsComponent } from './modules/agtransactions/agtransactions.component';
import { NewcustomerComponent } from './modules/newcustomer/newcustomer.component';
import { SendnotificationsComponent } from './modules/sendnotifications/sendnotifications.component';
import { ClaimsportalComponent } from './modules/claimsportal/claimsportal.component';
import { ManagerSetPasswordComponent } from './manager-set-password/manager-set-password.component';
import { ManagerExpiredLinkComponent } from './manager-expired-link/manager-expired-link.component';
import { ManagerPasswordsetSuccessComponent } from './manager-passwordset-success/manager-passwordset-success.component';
import { SignupmanagerComponent } from './modules/signupmanager/signupmanager.component';
import { ManagerstblComponent } from './modules/managerstbl/managerstbl.component';
import { ManagerResetpasswordComponent } from './modules/manager-resetpassword/manager-resetpassword.component';
import { ManagerResetpasswordSuccessComponent } from './modules/manager-resetpassword-success/manager-resetpassword-success.component';
import { ManagerResetpasswordExpiredlinkComponent } from './modules/manager-resetpassword-expiredlink/manager-resetpassword-expiredlink.component';
import { PolicylistingsComponent } from './modules/policylistings/policylistings.component';
import { MotorQuoteComponent } from './modules/motor-quote/motor-quote.component';
import { TravelQuoteComponent } from './modules/travel-quote/travel-quote.component';
import {MyPoliciesComponent} from 'src/app/modules/my-policies/my-policies.component';
import {SoldStickersComponent} from 'src/app/modules/sold-stickers/sold-stickers.component';
import {CommissionComponent} from 'src/app/modules/commission/commission.component';
import {ProfileAccountComponent} from 'src/app/modules/profile-account/profile-account.component';
import {NewClaimComponent} from 'src/app/modules/new-claim/new-claim.component';
import { FireQuoteComponent } from './modules/fire-quote/fire-quote.component';
import { PolicyDocumentsComponent } from './modules/policy-documents/policy-documents.component';
import { FleetMotorQuoteComponent } from './modules/fleet-motor-quote/fleet-motor-quote.component';
import { FleetMotorAddVehicleComponent } from './modules/fleet-motor-add-vehicle/fleet-motor-add-vehicle.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ActivateAccountPageComponent } from './activate-account-page/activate-account-page.component';
import { page404Component } from './page-404/page-404.component';
import { ReportComponent } from './modules/report/report.component';
import { LoginPageStaffComponent } from './login-page-staff/login-page-staff.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';


const routes: Routes = [
  { path: '', component: HomePageComponent },
  // { path: '', component: LoginPageStaffComponent },
  
  { path: 'login', component: LoginPageComponent },
  { 
    path: 'auth/staff/login', 
    component: LoginPageStaffComponent,
  },
  { path: 'auth/staff/forgot-password', component: ForgotPasswordComponent },
  { path: 'activate-account', component: ActivateAccountPageComponent },
  { path: '404', component: page404Component },

  {path:'', component:DefaultComponent,
  children:[
    {path:'dashboard', component: DashboardComponent},
    {path:'posts', component:PostsComponent},
    {path:'quotes', component: QuotesComponent},
    {path:'quotes/:payment-channel', component: QuotesComponent},
    {path:'renewpolicy', component: RenewPolicyComponent},
    {path:'stickerlistings', component: StickerlistingsComponent},
    {path:'products', component:ProductsComponent},
    {path:'transactions', component:AgtransactionsComponent},
    {path:'newcustomer', component:NewcustomerComponent},
    {path:'notifications', component:SendnotificationsComponent},
    {path:'claimsportal', component:ClaimsportalComponent},
    {path:'signupmanager', component: SignupmanagerComponent},
    {path:'allmanagers', component: ManagerstblComponent},
    {path:'stickerlistings', component: StickerlistingsComponent},
    {path:'policylistings', component:PolicylistingsComponent},
    {path:'motor-quote', component:MotorQuoteComponent},

    {path:'travelquote', component:TravelQuoteComponent},
    {path:'travelquote/:payment-channel', component:TravelQuoteComponent},

    {path:'firequote', component:FireQuoteComponent},
    {path:'firequote/:payment-channel', component:FireQuoteComponent},

    {path:'mypolicies', component:MyPoliciesComponent},
    {path:'soldstickers', component:SoldStickersComponent},
    {path:'commission', component:CommissionComponent},
    {path:'profile', component:ProfileAccountComponent},
    {path:'newclaim', component:NewClaimComponent},
    {path:'policydocuments', component:PolicyDocumentsComponent},

    {path:'fleet-motor-quote', component:FleetMotorQuoteComponent},
    {path:'fleet-motor-quote/:payment-channel', component:FleetMotorQuoteComponent},
    
    {path:'fleet-motor-add-vehicle', component:FleetMotorAddVehicleComponent},
    {path:'report', component:ReportComponent},
    {
      path: '**', //If path doesn't match anything, reroute to /404
      redirectTo: '/404',
      pathMatch: 'full'
    },
 ]
},

//setting manager password
{path: 'confirm_manager_password', component: ManagerSetPasswordComponent},
{path:'confirm_manager_password/:email/:token', component: ManagerSetPasswordComponent},
{path:'manager_expired_link', component: ManagerExpiredLinkComponent},
{path:'manager_password_success', component:ManagerPasswordsetSuccessComponent} ,
//end of setting manager password


// reset forgot password by manager
{path: 'reset_manager_password', component:ManagerResetpasswordComponent},
{path:'reset_manager_password/:email/:token', component: ManagerResetpasswordComponent},
{path:'reset_manager_expired_link', component: ManagerResetpasswordExpiredlinkComponent},
{path:'reset_manager_password_success', component:ManagerResetpasswordSuccessComponent}

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
