import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DefaultComponent } from './default.component';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
//import { AdmitagentComponent } from 'src/app/modules/admitagent/admitagent.component';
import { PostsComponent } from 'src/app/modules/posts/posts.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSidenavModule, MatDividerModule, MatCardModule, MatPaginatorModule, MatTableModule, MatInputModule, MatGridListModule, MatButtonModule, MatIconModule, MatSortModule, MatOptionModule, MatSelectModule, MatListModule, MatTooltipModule, MatTabsModule } from '@angular/material'
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from 'src/app/login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardService } from 'src/app/modules/service/dashboard.service';
import { AuthGuard } from 'src/app/modules/service/authguard';
import { HttpClientModule } from '@angular/common/http';
import { QuotesComponent } from 'src/app/modules/quotes/quotes.component';
import { RenewPolicyComponent } from 'src/app/modules/renew-policy/renew-policy.component';
import { StickerlistingsComponent } from 'src/app/modules/stickerlistings/stickerlistings.component';
import { ProductsComponent } from 'src/app/modules/products/products.component';
import { AgtransactionsComponent } from 'src/app/modules/agtransactions/agtransactions.component';
import { NewcustomerComponent } from 'src/app/modules/newcustomer/newcustomer.component';
import { SendnotificationsComponent } from 'src/app/modules/sendnotifications/sendnotifications.component';
import { ChartService } from 'src/app/modules/service/chart.service';
import { TpagtService } from 'src/app/modules/service/tpagtservice.service';
import { StickerService } from 'src/app/modules/service/stickerservice.service';
import { PolicyService } from 'src/app/modules/service/policyservice.service';
import { ClaimsportalComponent } from '../../modules/claimsportal/claimsportal.component'
import { ManagerSetPasswordComponent } from 'src/app/manager-set-password/manager-set-password.component';
import { ManagerExpiredLinkComponent } from 'src/app/manager-expired-link/manager-expired-link.component';
import { ManagerPasswordsetSuccessComponent } from 'src/app/manager-passwordset-success/manager-passwordset-success.component';
import { SignupmanagerComponent } from 'src/app/modules/signupmanager/signupmanager.component';
import { ManagerstblComponent } from 'src/app/modules/managerstbl/managerstbl.component';
import * as moment from 'moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { ManagerResetpasswordComponent } from 'src/app/modules/manager-resetpassword/manager-resetpassword.component';
import { ManagerResetpasswordSuccessComponent } from 'src/app/modules/manager-resetpassword-success/manager-resetpassword-success.component';
import { ManagerResetpasswordExpiredlinkComponent } from 'src/app/modules/manager-resetpassword-expiredlink/manager-resetpassword-expiredlink.component';
import { PolicylistingsComponent } from 'src/app/modules/policylistings/policylistings.component';
import { NgTippyModule } from 'angular-tippy';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MotorQuoteComponent } from 'src/app/modules/motor-quote/motor-quote.component';
import { MyPoliciesComponent } from 'src/app/modules/my-policies/my-policies.component';
import { SoldStickersComponent } from 'src/app/modules/sold-stickers/sold-stickers.component';
import { CommissionComponent } from 'src/app/modules/commission/commission.component';
import { ProfileAccountComponent } from 'src/app/modules/profile-account/profile-account.component';
import { PaymentMethodComponent } from 'src/app/modules/payment-method/payment-method.component';
import { NewClaimComponent } from 'src/app/modules/new-claim/new-claim.component';
//import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { ShowPremiumTotalComponent } from 'src/app/modules/dialogs/show-premium-total/show-premium-total.component';
import { SelectPaymentMethodComponent } from 'src/app/modules/dialogs/select-payment-method/select-payment-method.component';
import { CashPaymentComponent } from 'src/app/modules/dialogs/cash-payment/cash-payment.component';
import { ChequePaymentComponent } from 'src/app/modules/dialogs/cheque-payment/cheque-payment.component';
import { CreateCustomerComponent } from 'src/app/modules/dialogs/create-customer/create-customer.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { QuickMotoQuoteComponent } from 'src/app/quick-moto-quote/quick-moto-quote.component';
import { DebitCardPaymentComponent } from 'src/app/modules/dialogs/debit-card-payment/debit-card-payment.component';
import { ScKryptoService } from 'src/app/modules/service/ScKryptoService';
import { ChangePinComponent } from 'src/app/modules/dialogs/change-pin/change-pin.component';
import { ResetPinComponent } from 'src/app/modules/dialogs/reset-pin/reset-pin.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SignupComponent } from 'src/app/modules/dialogs/signup/signup.component';
import { SelectUserTypeComponent } from 'src/app/modules/dialogs/select-user-type/select-user-type.component';
import { AgentSignUpComponent } from 'src/app/modules/dialogs/agent-sign-up/agent-sign-up.component';
import { ActivateCustomerComponent } from 'src/app/modules/dialogs/active-customer/activate-customer.component';
import { TravelQuoteComponent } from 'src/app/modules/travel-quote/travel-quote.component';
import { TravelersComponent } from 'src/app/modules/dialogs/travelers/travelers.component';
import { QuoteBenefitsComponent } from 'src/app/modules/dialogs/quote-benefits/quote-benefits.component';
import { FireQuoteComponent } from 'src/app/modules/fire-quote/fire-quote.component';
import { PolicyDocumentsComponent } from 'src/app/modules/policy-documents/policy-documents.component';
import { SelectProductDialogComponent } from 'src/app/modules/dialogs/select-product-dialog/select-product-dialog.component';
import { QuickTravelQuoteComponent } from 'src/app/quick-travel-quote/quick-travel-quote.component';
import { QuickFireQuoteComponent } from 'src/app/quick-fire-quote/quick-fire-quote.component';
import { UserIdleModule } from 'angular-user-idle';
import { MomoPaymentComponent } from 'src/app/modules/dialogs/momo-payment/momo-payment.component';
import { FinalizeMomoPaymentComponent } from 'src/app/modules/dialogs/finalize-momo-payment/finalize-momo-payment.component';
import { FinalizeRetryMomoComponent } from 'src/app/modules/dialogs/finalize-retry-momo/finalize-retry-momo.component';
import { setSecurityQuestionsComponent } from 'src/app/modules/dialogs/set-security-questions/set-security-questions.component';
import { UpdateTravelOptions } from 'src/app/modules/dialogs/update-travel-dialog/update-travel-dialog.component';
import { FleetMotorQuoteComponent } from 'src/app/modules/fleet-motor-quote/fleet-motor-quote.component';
import { uploadExcelDataDialogComponent } from 'src/app/modules/dialogs/upload-excel-data/upload-excel-data.component';
import { FleetMotorAddVehicleComponent } from 'src/app/modules/fleet-motor-add-vehicle/fleet-motor-add-vehicle.component';
import { QuoteSummaryComponent } from 'src/app/modules/dialogs/quote-summary/quote-summary.component';
import { QuoteSummaryFleetComponent } from 'src/app/modules/dialogs/quote-summary-fleet/quote-summary-fleet.component';
import { HomePageComponent } from 'src/app/home-page/home-page.component';
import { LoginPageComponent } from 'src/app/login-page/login-page.component';
import { ActivateAccountPageComponent } from 'src/app/activate-account-page/activate-account-page.component';
import { InfoAlertComponent } from 'src/app/modules/dialogs/info-alert/info-alert.component';
import { page404Component } from 'src/app/page-404/page-404.component';
import { ReportComponent } from 'src/app/modules/report/report.component';
import { TravelPolicyExtension } from 'src/app/modules/dialogs/travel-policy-extension/travel-policy-extension.component';
import { SelectPaymentPlatformComponent } from 'src/app/modules/dialogs/select-payment-platform/select-payment-platform.component';
import { PaymentOTPVerificationComponent } from 'src/app/modules/dialogs/payment-otp-verification/payment-otp-verification.component';
import { LoginPageStaffComponent } from 'src/app/login-page-staff/login-page-staff.component';
import { ForgotPasswordComponent } from 'src/app/forgot-password/forgot-password.component';
import { EcobankCheckoutComponent } from 'src/app/modules/dialogs/ecobank-checkout/ecobank-checkout.component';

export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions,
  }
};

@NgModule({
  declarations: [
    ActivateAccountPageComponent,
    LoginPageComponent,
    HomePageComponent,
    LoginComponent,
    DefaultComponent,
    DashboardComponent,
    PostsComponent,
    QuotesComponent,
    RenewPolicyComponent,
    StickerlistingsComponent,
    ProductsComponent,
    AgtransactionsComponent,
    NewcustomerComponent,
    SendnotificationsComponent,
    ClaimsportalComponent,
    SignupmanagerComponent,
    ManagerstblComponent,
    StickerlistingsComponent,
    PolicylistingsComponent,
    MotorQuoteComponent,
    TravelQuoteComponent,
    MyPoliciesComponent,
    SoldStickersComponent,
    CommissionComponent,
    ProfileAccountComponent,
    PaymentMethodComponent,
    CashPaymentComponent,
    ChequePaymentComponent,
    MomoPaymentComponent,
    FinalizeMomoPaymentComponent,
    FinalizeRetryMomoComponent,
    NewClaimComponent,
    setSecurityQuestionsComponent,
    ManagerSetPasswordComponent,
    ManagerExpiredLinkComponent,
    ManagerPasswordsetSuccessComponent,
    ManagerResetpasswordComponent,
    ManagerResetpasswordSuccessComponent,
    ManagerResetpasswordExpiredlinkComponent,
    ShowPremiumTotalComponent,
    SelectPaymentMethodComponent,
    MomoPaymentComponent,
    CashPaymentComponent,
    CreateCustomerComponent,
    QuickMotoQuoteComponent,
    DebitCardPaymentComponent,
    ChangePinComponent,
    ResetPinComponent,
    QuoteSummaryComponent,
    QuoteSummaryFleetComponent,
    SignupComponent,
    SelectUserTypeComponent,
    AgentSignUpComponent,
    ActivateCustomerComponent,
    TravelersComponent,
    QuoteBenefitsComponent,
    FireQuoteComponent,
    PolicyDocumentsComponent,
    SelectProductDialogComponent,
    QuickTravelQuoteComponent,
    QuickFireQuoteComponent,
    UpdateTravelOptions,
    FleetMotorQuoteComponent,
    uploadExcelDataDialogComponent,
    FleetMotorAddVehicleComponent,
    InfoAlertComponent,
    page404Component,
    ReportComponent,
    TravelPolicyExtension,
    SelectPaymentPlatformComponent,
    PaymentOTPVerificationComponent,
    LoginPageStaffComponent,
    ForgotPasswordComponent,
    EcobankCheckoutComponent

  ],
  entryComponents: [
    ShowPremiumTotalComponent,
    SelectPaymentMethodComponent,
    MomoPaymentComponent,
    CashPaymentComponent,
    ChequePaymentComponent,
    CreateCustomerComponent,
    QuickMotoQuoteComponent,
    DebitCardPaymentComponent,
    ChangePinComponent,
    ResetPinComponent,
    QuoteSummaryComponent,
    QuoteSummaryFleetComponent,
    SignupComponent,
    SelectUserTypeComponent,
    AgentSignUpComponent,
    ActivateCustomerComponent,
    TravelersComponent,
    QuoteBenefitsComponent,
    FireQuoteComponent,
    SelectProductDialogComponent,
    QuickTravelQuoteComponent,
    QuickFireQuoteComponent,
    FinalizeMomoPaymentComponent,
    FinalizeRetryMomoComponent,
    setSecurityQuestionsComponent,
    UpdateTravelOptions,
    uploadExcelDataDialogComponent,
    InfoAlertComponent,
    TravelPolicyExtension,
    SelectPaymentPlatformComponent,
    PaymentOTPVerificationComponent,
    EcobankCheckoutComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    FlexLayoutModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatSortModule,
    MatOptionModule,
    MatSelectModule,
    NgxPaginationModule,
    NgTippyModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatStepperModule,
    MatTooltipModule,
    MatDatepickerModule,
    //NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatTabsModule,
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
    // and `ping` is 120 (1 minutes).
    UserIdleModule.forRoot({ idle: 600, timeout: 300, ping: 60 }),
  

  ],

  providers: [
    DashboardService,
    AuthGuard,
    ChartService,
    TpagtService,
    StickerService,
    PolicyService,
    ScKryptoService,
    MatDatepickerModule,
    MatNativeDateModule,
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ]
})
export class DefaultModule { }
