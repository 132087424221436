import { Component, LOCALE_ID, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MatOption, MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { DashboardService } from '../../service/dashboard.service';
import { FinalizeMomoPaymentComponent } from '../finalize-momo-payment/finalize-momo-payment.component';
import { formatNumber } from '@angular/common';
import { PaymentOTPVerificationComponent } from '../payment-otp-verification/payment-otp-verification.component';
import swal from 'sweetalert2'
import { EcobankCheckoutComponent } from '../ecobank-checkout/ecobank-checkout.component';
import { EnvService } from '../../service/env/env.service';



@Component({
  selector: 'momo-payment',
  templateUrl: './momo-payment.component.html',
  styleUrls: ['./momo-payment.component.scss']
})
export class MomoPaymentComponent implements OnInit {
  appuser: any;

  payload: any = {
    policy_sequence: "",
    transaction_type: "MobileMoney",
    status_of_client: "new",
    policy_lob: this.data.policy_lob,
    platform: 'webapp'
  };

  constructor(
    public service: DashboardService,
    public dialogRef: MatDialogRef<MomoPaymentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string,
    public env: EnvService

  ) {
    this.appuser = this.service.currentUser();
    console.log("appuser ===>>> " + JSON.stringify(this.appuser))
    console.log("openMomoPaymentDialog ===>>> " + JSON.stringify(data))


    this.quotePayload = data.quotePayload;
    this.total_premium = data.total_premium;
    this.formatted_premium=`${'GHS'} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;

    // this.formatted_premium=`${this.quotePayload.currency_code} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;


    //   this.payload={
    //   ...this.payload,
    //   module:'agent',
    //   agent_code:this.appuser.code.toString(),
    //   client_code:this.quotePayload.client_code.toString(),
    //   broker_user_code:'',
    //   policy_sequence:this.quotePayload.policy_sequence,
    //   status_of_client: this.quotePayload.status_of_client,
    //   policy_lob: data.policy_lob
    // }

    console.log("payload ===>>> " + JSON.stringify(this.payload))




    if (this.appuser.role == 'agent' || this.appuser.role == 'broker-admin') {
      this.payload = {
        ...this.payload,
        module: 'agent',
        agent_code: this.appuser.code.toString(),
        client_code: this.quotePayload.client_code.toString(),
        broker_user_code: '',
        policy_sequence: this.quotePayload.policy_sequence,
        status_of_client: this.quotePayload.status_of_client,
        policy_lob: data.policy_lob
      }
    } else if (this.appuser.role == 'broker-user') {
      this.payload = {
        ...this.payload,
        module: 'broker-user',
        agent_code: this.appuser.broker_user_main_broker_code,
        broker_user_code: this.appuser.code,
        client_code: this.quotePayload.client_code.toString(),
        policy_sequence: this.quotePayload.policy_sequence,
        status_of_client: this.quotePayload.status_of_client,
        policy_lob: data.policy_lob
      }
    } else if (this.appuser.role == 'client') {
      this.payload = {
        ...this.payload,
        module: 'client',
        client_code: this.quotePayload.client_code.toString(),
        policy_sequence: this.quotePayload.policy_sequence,
        status_of_client: this.quotePayload.status_of_client,
        policy_lob: data.policy_lob
      }

    }else if (this.appuser.role=='staff'){

      if(data.policy_lob == '20'){
        this.payload={
          ...this.payload,
          module:'staff',
          staff: {
            staff_id:this.appuser.code
          },
          policy_sequence:this.quotePayload.policy_sequence,
          status_of_client: this.quotePayload.status_of_client,
          policy_lob:data.policy_lob
        }
      }else{
        this.payload={
          ...this.payload,
          module:'staff',
          staff_id:this.appuser.code,
          policy_sequence:this.quotePayload.policy_sequence,
          status_of_client: this.quotePayload.status_of_client,
          policy_lob:data.policy_lob
        }
      }
     
    }




  }

  appUser: any;
  method: string;
  quotePayload: any;
  total_premium: any = "0";
  formatted_premium: any = "";
  extraCharge: any = 0.00;
  amountPlusExtraCharge: any = 0.00;


  networks: any;
  paymentChannels: any;

  selectedData: any = {
    value: "",
    text: ""
  };

  ngOnInit() {
    this.networks = [
      {
        label: 'MTN',
        value: "MTN"
      },
      {
        label: 'VODAFONE',
        value: "VODAFONE"
      },
      {
        label: 'AIRTELTIGO',
        value: "ARTLTIGO"
      },
    ]

    // this.paymentChannels=[
    //   {
    //     "id": 1,
    //     "payment_name": "Calpay",
    //     "display_name": "CalPay",
    //     "status": "Active"
    //   },
    //   {
    //     "id": 2,
    //     "payment_name": "Hubtel",
    //     "display_name": "HubtelPay",
    //     "status": "Active"
    //   },
    //   {
    //     "id": 3,
    //     "payment_name": "Ecobank",
    //     "display_name": "EcobankPay",
    //     "status": "Active"
    //   }
    // ]


    this.GetPaymentPlatform()



  }



  calExtraCharge() {
    this.extraCharge = (this.total_premium.replace(/,/g, '') * (1.5 / 100)).toFixed(2);
    this.amountPlusExtraCharge = (parseFloat(this.total_premium.replace(/,/g, '')) + parseFloat(this.extraCharge)).toFixed(2)

  }



  onNetworkSelected(event: MatSelectChange) {

    this.selectedData = {
      value: event.value,
      text: event.source.triggerValue
    };

    if (this.selectedData.value == "MTN") {

      this.extraCharge = "0.00"

    } else {

      this.calExtraCharge()

    }



  }



  onPaymentPlatformSelected(event: MatSelectChange) {

    //   this.selectedData = {
    //     value: event.value,
    //     text: event.source.triggerValue
    //   };

    // if(this.selectedData.value == "MTN"){

    //   this.extraCharge = "0.00"

    // }else {

    //   this.calExtraCharge()

    // }



  }








  openOTPDialog() {
    const dialogRef = this.dialog.open(PaymentOTPVerificationComponent, {
      disableClose: true,
      width: '350px',
      data: this.payload
    });

    dialogRef.afterClosed().subscribe(response => {
      console.log("otp results>>>>>> ", response)
      if (response.OTPVerifaction) {
        this.makePayment()
      }

    });

  }




  sendOTP() {
    const payload = {
      "phone": this.payload.momo_payment_no
    }

    swal.showLoading()
    this.service.sendOTP(payload)
      .toPromise()
      .then((response: any) => {
        swal.hideLoading()
        console.log("response>>>>>>" + JSON.stringify(response))

        if (response.hasOwnProperty('responseCode') && response.responseCode === '000') {
          this.openOTPDialog()

          // swal.fire({
          //   icon: 'success',
          //   text: 'One Time Password (OTP) sent to your mobile money number',
          //   timer: 2000,
          //   showConfirmButton: true
          // });


        } else {
          swal.fire({
            title: '',
            text: response.responseMessage,
            icon: 'error',
          });
        }

      }).catch(error => {
        swal.fire({
          title: '',
          text: error,
          icon: 'error',
        });
      })

  }





  onSubmit = () => {
    if (this.appuser.role == 'client') {
      this.sendOTP()
    }else{
      this.makePayment()
    }
  

  }


  makePayment() {
    let callbackUrl = ""
    if(this.router.url.includes('/quotes')){
      callbackUrl = this.env.host + "/quotes/ecobankpay"

    }else if(this.router.url.includes('/fleet-motor-quote')){
      callbackUrl = this.env.host + "/fleet-motor-quote/ecobankpay"
    
    }else if(this.router.url.includes('/travelquote')){
      callbackUrl = this.env.host + "/travelquote/ecobankpay"
    
    }else if(this.router.url.includes('/firequote')){
      callbackUrl = this.env.host + "/firequote/ecobankpay"
    }


    this.payload = { ...this.payload, 
      app_callback_url: callbackUrl
    }

    this.service.swalAlart.showLoading();
    this.service.subPayment(this.payload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close()

        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {

          this.quotePayload.module = "PAID";

          if (response.hasOwnProperty('transactionToken') && response.hasOwnProperty('transactionUrl') && this.payload.payment_channel=='Ecobank') {
            
            this.payload = { ...this.payload, transactionUrl: response.transactionUrl, transactionToken: response.transactionToken }
            this.openEcobankCheckoutDialog(this.payload)

            // window.open(response.transactionUrl,'_blank');
            // this.dialogRef.close({ event: 'close', data: {quotePayload:{module:this.quotePayload.module}} });
          
          }else {

            this.payload = { ...this.payload, transaction_token: response.transactionToken }
            this.openfinalizeMomoPaymentDialog(this.payload)
            this.dialogRef.close({ event: 'close', data: { quotePayload: { ...this.payload, module: this.quotePayload.module } } });

            // this.service.swalAlart.fire({
            //   title: 'Failed',
            //   text: 'Checkout Page not provided',
            //   icon: 'error',
            // });

          }

        }else if (response.hasOwnProperty('responseCode') && response.responseCode == '001') {
          this.quotePayload.module = "PAID";
          this.service.swalAlart.close();
          this.service.swalAlart.fire({
            title: 'Processing Payment...',
            html: 'Please wait. An sms and email of policy and sticker details will be sent to you after processing payment.',
            icon: 'success',
            confirmButtonText: 'Done',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result) => {
            if (result.value)
              this.router.navigate(['transactions']);
            this.dialogRef.close({ event: 'close', data: { quotePayload: this.quotePayload, total_premium: this.total_premium } });
          });
        }
        else if (response.hasOwnProperty('responseCode') && response.responseCode == 'E31') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'This vehicle already exist under a policy\n.Please check vehicle registration number and try again.',
            icon: 'error',
          });

        } else if (response.hasOwnProperty('responseCode') && response.responseCode == 'E61') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: response.responseMessage,
            icon: 'error',
          });
        }
        else {
          let errors = response.hasOwnProperty('responseMessage') && response.responseCode == '400 BAD_REQUEST' ? JSON.parse(response.responseMessage) : null;
          this.service.swalAlart.fire({
            title: 'Failed',
            text: errors && errors.errors.length > 0 ? `MID::${errors.errors[0]}` : 'Payment failed!',
            icon: 'error',
          });
        }
      }).catch((error: any) => {
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Failed',
          text: 'Kindly check connection and retry!',
          icon: 'error',
        });
      })
  }


  confirmPayment = (event) => {
    event.preventDefault();

    var textMessage = ""

    if (this.selectedData.value != "MTN") {
      textMessage = `You are about to make payment of ${this.amountPlusExtraCharge} including 1.5% mobile money extra charge of your payable premium (${this.total_premium})\nDo you want to proceed?`
    } else {
      textMessage = `Do you really want to proceed with the payment of ${this.formatted_premium} \nusing ${this.payload.momo_type === 'ARTLTIGO' ? 'Airtel Tigo' : (this.payload.momo_type == 'VODAFONE' ? 'Vodafone' : 'MTN')} wallet number: ${this.payload.momo_payment_no} ?`
    }



    this.service.swalAlart.fire({
      title: "Confirm Payment",
      text: textMessage,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: `Yes,Proceed`,
      reverseButtons: true,
      onAfterClose: () => {
        //this.service.swalAlart.showLoading();
      }
    }).then((dialogResponse) => {
      if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
        this.service.swalAlart.showLoading();

        // setTimeout(()=>{
        //   this.service.swalAlart.close();
        // },10000)

        this.onSubmit();

      }
      else
        this.service.swalAlart.close();

    })



  }




  openfinalizeMomoPaymentDialog(payload) {
    const dialogRef = this.dialog.open(FinalizeMomoPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: payload }
    });
    dialogRef.afterClosed().subscribe(result => {

      // if(result.hasOwnProperty('data')){
      //   result.data.quotePayload.module=this.service.currentUser().role.toLowerCase();

      //  // console.log('Data',result.data)
      //   /* if(this.confirmedQuote)
      //   {
      //     setTimeout(()=>{
      //       this.router.navigate(['transactions']);
      //     },3000)
      //   } */
      // }

    });
  }


  openEcobankCheckoutDialog(payload) {
    window.open(payload.transactionUrl,'_self');
    this.dialogRef.close({ event: 'close', data: {quotePayload:{module:this.quotePayload.module}} });

    //save quote payload
    localStorage.removeItem('quotePayload')
    localStorage.setItem('quotePayload', JSON.stringify(payload))



    // const dialogRef = this.dialog.open(EcobankCheckoutComponent, {
    //   disableClose: true,
    //   width: '500px',
    //   height: '800px',
    //   data: { quotePayload: payload }
    // });
    // dialogRef.afterClosed().subscribe(result => {

    //   // if(result.hasOwnProperty('data')){
    //   //   result.data.quotePayload.module=this.service.currentUser().role.toLowerCase();

    //   //  // console.log('Data',result.data)
    //   //   /* if(this.confirmedQuote)
    //   //   {
    //   //     setTimeout(()=>{
    //   //       this.router.navigate(['transactions']);
    //   //     },3000)
    //   //   } */
    //   // }

    // });


  }




  GetPaymentPlatform() {
    this.service.swalAlart.showLoading();
    this.service.GetPaymentPlatform(this.payload)
      .toPromise()
      .then((response: any) => {
        console.log("content>>>" + response.data)

        this.paymentChannels = response.data

        this.service.swalAlart.close();
      }).catch(error => {
        this.service.swalAlart.close();
      })

  }


}
