import { Component, OnInit , ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-agtransactions',
  templateUrl: './agtransactions.component.html',
  styleUrls: ['./agtransactions.component.scss']
})
export class AgtransactionsComponent implements OnInit {

  userdata;
  transactions:any = [];
  searchdata;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  length: number;
  pageSize: number= 5;
  pageSizeOptions = [ 5, 10, 20, 50, 100];

  transactionSum:string='GHS 0.00';

  dataSource = new MatTableDataSource();
  displayedColumns = ['no','transaction_id', 'invoice_no', 'first_name','email', 'transaction_type','mobile_no','amount', 'status', 'date_createdd']

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(private router:Router, private service:DashboardService) {
    this.userdata = this.service.currentUser();

   
    if(this.userdata.role == "staff"){
        this.getStaffTransactions()
    }else{
        this.getTransactions();
    }
   


   }

  ngOnInit() {}

  search (query: string){
    this.searchdata = (query) ?this.transactions.filter( data => data.transaction_id.toLowerCase().includes(query.toLowerCase()) || data.invoice_no.toLowerCase().includes(query.toLowerCase()) || data.client_id.toLowerCase().includes(query.toLowerCase()) || data.transaction_type.toLowerCase().includes(query.toLowerCase()) || data.email.toUpperCase().includes(query.toUpperCase())) : this.transactions;
  }
  
  
getTransactions(){
  let payload={};
    

      if(this.userdata.role==='client'){
        payload={
          module:'client',
          client_code:this.userdata.code
        }
      }else if(this.userdata.role==='agent' || this.userdata.role==='broker-admin'){
        payload={
          module:'agent',
          agent_code:this.userdata.code
        }
      }else if(this.userdata.role==='broker-user'){
        payload={
          module:'broker-user',
          broker_user_code:this.userdata.code
        }
      } else if(this.userdata.role==='staff'){
        payload={
          module:'staff',
          staff: {
            staff_id: this.userdata.code,
            staff_email: this.userdata.email
          }
        }
      }
      
      else { }
      


    this.service.swalAlart.showLoading();
    this.service.getTransaction(payload)
    .toPromise()
    .then((data: any)=>{

    if(data.hasOwnProperty('transactions') && data.hasOwnProperty('responseCode') && data.responseCode=='000'){
      this.transactions = data.transactions;

      this.searchdata = this.transactions;
      this.dataSource.data = this.transactions;
      this.dataSource.paginator = this.paginator;
      this.transactionSum=`GHS ${data.transactionSum.length? data.transactionSum[0].total:'0.00'}`;

    }
  }).catch(error=>{
    this.service.swalAlart.fire({
      title:'Loading failed',
      text:'Transactions loading failed',
      icon:'error'
    });
  })
  .finally(()=>{
    this.service.swalAlart.close();
  });

}

getStaffTransactions(){
  let payload={
    module:'staff',
    staff_id: this.userdata.code
  }

    this.service.swalAlart.showLoading();
    this.service.getStaffTransaction(payload)
    .toPromise()
    .then((data: any)=>{

    if(data.hasOwnProperty('transactions') && data.hasOwnProperty('responseCode') && data.responseCode=='000'){
      this.transactions = data.transactions;

      this.searchdata = this.transactions;
      this.dataSource.data = this.transactions;
      this.dataSource.paginator = this.paginator;
      this.transactionSum=`GHS ${data.transactionSum.length? data.transactionSum[0].total:'0.00'}`;

    }
  }).catch(error=>{
    this.service.swalAlart.fire({
      title:'Loading failed',
      text:'Transactions loading failed',
      icon:'error'
    });
  })
  .finally(()=>{
    this.service.swalAlart.close();
  });

}


}
