import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Directive, HostListener ,ViewChild} from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  sideBaropen = true;

  showToggle: string;
  mode: string;
  openSidenav:boolean;
  //sideBaropen:boolean;
  
  private screenWidth$ = new BehaviorSubject<number>
    (window.innerWidth);

  constructor() { }

  ngOnInit():void {
    this.getScreenWidth().subscribe(width => {
      if (width < 767) {
       this.showToggle = 'show';
       this.mode = 'over';
      // this.openSidenav = false;
       this.sideBaropen = false;

     }
     else if
     (width > 767) {
       this.showToggle = 'hide';
       this.mode = 'side';
       //this.openSidenav = true;
       this.sideBaropen = true;

     }
   });


  }

  sideBarToggler(event:any){
    this.sideBaropen = !this.sideBaropen;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth$.next(event.target.innerWidth);
  }



  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }

}
