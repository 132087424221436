import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import * as moment from 'moment';


interface CustomerProfile {
  CLIENT_CODE: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?: boolean;
}

interface CustomerResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}
@Component({
  selector: 'app-renew-policy',
  templateUrl: './renew-policy.component.html',
  styleUrls: ['./renew-policy.component.scss']
})


export class RenewPolicyComponent implements OnInit {
  appuser: any;
  userdata;
  agent_id;
  customers: any = [];
  searchdata;

  policyFilterFloat: string;

  length: number = 0
  pageIndex: number = 0
  pageSize: number = 5
  pageSizeOptions = [5, 10, 20, 50, 100]
  pageChange = false;


  searchCustomerCtrl = new FormControl();
  isCustomerLoading: boolean;

  policies: any = [];

  coverTypes = new Map();

  filteredCustomers: CustomerProfile[]
  selectedCustomer: CustomerProfile
  dataSource = new MatTableDataSource();
  // displayedColumns = ['no','LEGACY_POLICY_SEQUENCE', 'PRODUCT_CODE', 'VEHICLE_REGISTRATION','SUM_INSURED','DURATION_TYPE', 'INCEPTION_DATE', 'EXPIRY_DATE', 'link','renew']
  displayedColumns = ['no', 'policy_sequence', 'customer_first_name', 'customer_phone', 'product_description', 'duration_type', 'total_premium', 'inception_date', 'expiry_date', 'date_created', 'link']



  filterClientPayload: any = {};

  requestPayload: any = {
    module: 'client',
    page_size: "5",
    current_page: "0"
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private service: DashboardService, public dialog: MatDialog) {
    this.appuser = this.service.currentUser();

    this.isCustomerLoading = false

    this.coverTypes.set('203', 'Third Party')
    this.coverTypes.set('201', 'Comprehensive')
    this.coverTypes.set('202', 'Third Party Fire & Theft')

    this.selectedCustomer = {
      ...this.selectedCustomer,
      CLIENT_CODE: "",
      CLIENT_FAMILY_NAME: "",
      CLIENT_FIRST_NAME: "",
      DISPLAY_NAME: ""
    };
  }

  renewFirePolicySummaryDialog(selectedQuote) {
    let summary = [
      { label: 'Date Prepared', value: moment().format('DD/MM/YYYY') },

      { label: 'Prepared By', value: selectedQuote.PREPARED_BY },

      { label: 'Customer Name', value: (`${selectedQuote.CUSTOMER_FIRST_NAME || ''} ${selectedQuote.CUSTOMER_LAST_NAME}`).trim() },

      { label: 'Customer Email', value: selectedQuote.CUSTOMER_EMAIL },

      { label: 'Customer Code', value: selectedQuote.CLIENT_CODE },

      { label: 'Mobile Number', value: selectedQuote.CUSTOMER_PHONE },

      { label: 'Product Plan', value: selectedQuote.PRODUCT_DESCRIPTION },

      { label: 'Location of Building/Proprty', value: selectedQuote.FIRE_LOCATION },

      { label: 'Construction Type', value: selectedQuote.FIRE_CONSTRUCT_TYPE_DESC },

      { label: 'Duration', value: (selectedQuote.DURATION_TYPE == 'annual' ? 'One Year(365)' : selectedQuote.DURATION_TYPE) },

      { label: 'Building Sum Insured', value: (selectedQuote.BUILDING_SUM_INSURED ? selectedQuote.BUILDING_SUM_INSURED : 0) },

      { label: 'Content Sum Insured', value: (selectedQuote.CONTENT_SUM_INSURED ? selectedQuote.CONTENT_SUM_INSURED : 0) },

      { label: 'Lost of Rent', value: selectedQuote.LOSS_OF_RENT_VALUE },

      { label: 'Public Liability', value: selectedQuote.FIRE_ADD_PL },

      { label: 'Start Date', value: selectedQuote.INCEPTION_DATE },

      { label: 'End Date', value: selectedQuote.EXPIRY_DATE }
    ];

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'fire',
        hide_foreign_currency: 0,
        quote: summary,

        total_premium: selectedQuote.COST_OF_INSURANCE_CV,
        foreign_currency: selectedQuote.CURRENCY_CODE,
        foreign_amount: selectedQuote.COST_OF_INSURANCE,
        confirmedQuote: false,
        confirmedRenewal: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data') && result.data.edit) {
        this.editQuoteSummaryDialog(selectedQuote);
      }
    });
  }
  renewPolicySummaryDialog(policy_no: string) {
    let selectedPolicy = this.policies.find((r: any) => (r.LEGACY_POLICY_SEQUENCE == policy_no || r.POLICY_NUMBER == policy_no));

    if (selectedPolicy.POLICY_LOB == '10')
      this.renewFirePolicySummaryDialog(selectedPolicy);
    else {
      let policy = {
        ...selectedPolicy,
        DATE_COMPLETED: selectedPolicy.DATE_COMPLETED,
        PREPARED_BY: null,
        CUSTOMER_FIRST_NAME: selectedPolicy.CUSTOMER_FIRST_NAME,
        CUSTOMER_LAST_NAME: selectedPolicy.CUSTOMER_LAST_NAME,
        CUSTOMER_EMAIL: selectedPolicy.CUSTOMER_EMAIL,
        CUSTOMER_PHONE: selectedPolicy.CUSTOMER_PHONE,
        YEAR_OF_MANUFACTURE: selectedPolicy.YEAR_OF_MANUFACTURE,
        MAKE: selectedPolicy.MAKE,
        QUOTE_MODEL: selectedPolicy.QUOTE_MODEL,
        CUBIC_CAPACITY: selectedPolicy.CUBIC_CAPACITY,
        CURRENCY_CODE: selectedPolicy.CURRENCY_CODE,
        SUM_INSURED: ((selectedPolicy.SUM_INSURED.trim() == '' || selectedPolicy.SUM_INSURED.trim() == '.00') ? '0.00' : selectedPolicy.SUM_INSURED.trim()),
        QUOTE_DAYS: selectedPolicy.QUOTE_DAYS,
        DURATION_TYPE: selectedPolicy.DURATION_TYPE,
        EXCESS_TYPE_CODE: selectedPolicy.EXCESS_TYPE_CODE,
        TPPD_LIMIT: selectedPolicy.TPPD_LIMIT,
        RISK_DESCRIPTION: selectedPolicy.RISK_DESCRIPTION,
        COST_OF_INSURANCE: selectedPolicy.COST_OF_INSURANCE,
        confirmedRenewal: true,
        isPolicy: true
      }
      const dialogRef = this.dialog.open(QuoteSummaryComponent, {
        disableClose: true,
        width: '500px',
        data: {
          flow: 'moto',
          hide_foreign_currency: 0,
          quote: policy,

          total_premium: selectedPolicy.COST_OF_INSURANCE_CV,
          foreign_currency: selectedPolicy.CURRENCY_CODE,
          foreign_amount: selectedPolicy.COST_OF_INSURANCE,
          confirmedQuote: false,
          hideMailStatus: true
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.hasOwnProperty('data') && result.data.edit) {
          this.editQuoteSummaryDialog(selectedPolicy);
        }
        else if (result.hasOwnProperty('data') && result.data.confirmedQuote) {
          this.onSelectPaymentMethod(policy);
        }
      });
    }
  }

  openQuoteSummaryDialog(policy_no: string) {

    const selectedQuote = this.policies.find((r: any) => r.policy_sequence === policy_no);
    console.log('selected Quote', selectedQuote.legacy_total_discount)

    //check if selected policy is eligible for renewal (comprehensive)
    if(selectedQuote.product_description.product_code == 201){
    if(selectedQuote.legacy_total_discount != 0 || selectedQuote.legacy_total_discount != 0.0 || selectedQuote.legacy_total_loadings != 0 || selectedQuote.legacy_total_loadings != 0.0){
        //if(!(selectedQuote.legacy_total_discount == 0 || selectedQuote.legacy_total_discount == 0.00 || selectedQuote.legacy_total_loadings == 0 || selectedQuote.legacy_total_loadings == 0.00) ){
        this.service.swalAlart.fire({
          title: 'Alert',
          html: 'Sorry, this policy cannot be renewed on this application. Kindly contact your branch office for assistance',
          icon: 'warning',
          confirmButtonText: 'Ok',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
         
        });

      }else{

            localStorage.setItem('motor-quote', JSON.stringify(selectedQuote))
            localStorage.setItem('quote_activity', 'policy_renewals')
        
            this.router.navigate(["/fleet-motor-quote"], {
              queryParams: {
                previous: 'renewals'
              },
              queryParamsHandling: 'merge',
              skipLocationChange: false
            });

      }
      
      
    }else{

      localStorage.setItem('motor-quote', JSON.stringify(selectedQuote))
      localStorage.setItem('quote_activity', 'policy_renewals')
  
      this.router.navigate(["/fleet-motor-quote"], {
        queryParams: {
          previous: 'renewals'
        },
        queryParamsHandling: 'merge',
        skipLocationChange: false
      });

    }




  }


  openFireSummary(selectedQuote) {
    let summary = [
      { label: 'Date Prepared', value: moment().format('DD/MM/YYYY') },

      { label: 'Prepared By', value: selectedQuote.PREPARED_BY },

      { label: 'Customer Name', value: (`${selectedQuote.CUSTOMER_FIRST_NAME || ''} ${selectedQuote.CUSTOMER_LAST_NAME}`).trim() },

      { label: 'Customer Email', value: selectedQuote.CUSTOMER_EMAIL },

      { label: 'Customer Code', value: selectedQuote.CLIENT_CODE },

      { label: 'Mobile Number', value: selectedQuote.CUSTOMER_PHONE },

      { label: 'Product Plan', value: selectedQuote.PRODUCT_DESCRIPTION },

      { label: 'Location of Building/Proprty', value: selectedQuote.FIRE_LOCATION },

      { label: 'Construction Type', value: selectedQuote.FIRE_CONSTRUCT_TYPE_DESC },

      { label: 'Duration', value: (selectedQuote.DURATION_TYPE == 'annual' ? 'One Year(365)' : selectedQuote.DURATION_TYPE) },

      { label: 'Building Sum Insured', value: (selectedQuote.BUILDING_SUM_INSURED ? selectedQuote.BUILDING_SUM_INSURED : 0) },

      { label: 'Content Sum Insured', value: (selectedQuote.CONTENT_SUM_INSURED ? selectedQuote.CONTENT_SUM_INSURED : 0) },

      { label: 'Lost of Rent', value: selectedQuote.LOSS_OF_RENT_VALUE },

      { label: 'Public Liability', value: selectedQuote.FIRE_ADD_PL },

      { label: 'Start Date', value: selectedQuote.INCEPTION_DATE },

      { label: 'End Date', value: selectedQuote.EXPIRY_DATE }
    ];

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'fire',
        hide_foreign_currency: 0,
        quote: summary,

        total_premium: selectedQuote.COST_OF_INSURANCE_CV,
        foreign_currency: selectedQuote.CURRENCY_CODE,
        foreign_amount: selectedQuote.COST_OF_INSURANCE,
        confirmedQuote: false
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }
  openTravelSummary(selectedQuote) {

    let summary = [
      { label: 'Date Prepared', value: selectedQuote.DATE_COMPLETED },
      { label: 'Prepared By', value: selectedQuote.PREPARED_BY },
      { label: 'Customer Name', value: `${selectedQuote.CUSTOMER_FIRST_NAME} ${selectedQuote.CUSTOMER_LAST_NAME}` },
      { label: 'Customer Email', value: selectedQuote.CUSTOMER_EMAIL },
      { label: 'Mobile Number', value: selectedQuote.CUSTOMER_PHONE },
      { label: 'ID Type', value: 'Passport' },
      { label: 'ID Number', value: selectedQuote.PASSPORT_NUMBER },
      { label: 'Plan Type', value: selectedQuote.PRODUCT_DESCRIPTION },
      //{label:'Coverage',value:''},
      { label: 'Destination', value: selectedQuote.DESTINATION_COUNTRY },
      { label: 'Date of Departure', value: selectedQuote.INCEPTION_DATE },
      { label: 'Date of Return', value: selectedQuote.EXPIRY_DATE }
    ]

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'travel',
        hide_foreign_currency: (selectedQuote.PRODUCT_DESCRIPTION == 'VANGUARD SAFE TRAVEL (VST)' ? 1 : 0),
        quote: summary,

        total_premium: selectedQuote.COST_OF_INSURANCE_CV,
        foreign_currency: selectedQuote.CURRENCY_CODE,
        foreign_amount: selectedQuote.COST_OF_INSURANCE,
        confirmedQuote: false
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  onSelectPaymentMethod(policy) {
    const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
      disableClose: true,
      //width: '500px',
      data: { total_premium: policy.COST_OF_INSURANCE, quotePayload: policy, method: null }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('quote payload', result)
    });
  }
  editQuoteSummaryDialog(policy) {

    if (policy.POLICY_LOB == '10')
      this.router.navigate(['/firequote'], { state: { editableQuote: policy, renewal: true } })
    else if (policy.POLICY_LOB == '20')
      this.router.navigate(['/motorquote'], { state: { editableQuote: policy, renewal: true } })
    else
      this.service.swalAlart.fire({
        title: "You cannot renew a travel policy",
        icon: 'warning'
      });
  }

  ngOnInit() {

    this.userdata = this.service.currentUser()
    this.agent_id = this.userdata.code;


    if (this.userdata.role == 'client') {
      console.log("client>>>>>")
      this.selectedCustomer = { ...this.selectedCustomer, CLIENT_CODE: this.userdata.code };
      this.getPoliciesForRenewal();
      this.policyFilterFloat = "is-customer";
    }
    else if (this.userdata.role == 'agent' || this.userdata.role == 'broker-user'  || this.userdata.role == 'staff') {
      console.log("agent/broker>>>>>")
      this.policyFilterFloat = "is-agent";
      this.onFilterCustomer();


      try {
        let alreadySelectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'))

        if (alreadySelectedCustomer) {
          this.searchCustomerCtrl = new FormControl({ value: alreadySelectedCustomer.DISPLAY_NAME, disabled: false });
          this.onFilterCustomer()
          this.onSelectCustomer(alreadySelectedCustomer)
        }
      } catch (err) {
        console.log(err)
      }



    }




  }


  Cusinfo() {
    this.router.navigate(['/customers'])
  }

  Transactions() {
    this.router.navigate(['/transactions'])
  }


  newclient() {
    this.router.navigate(['/newcustomer']);
  }

  Policylists() {
    this.router.navigate(['/policylistings'])
  }



  //autocomplete search customer
  onFilterCustomer() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCustomers = [];
          this.isCustomerLoading = true;
        }),
        switchMap(value => {
          if (value && value.length) {

            if (this.appuser.role == "agent") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                agent_code: this.appuser.code,
                search_parameter: value
              }

            } else if (this.appuser.role == "broker-user") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                broker_user_code: this.appuser.code,
                search_parameter: value
              }
            
            } else if (this.appuser.role == "staff") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                search_parameter: value,
                module: 'staff'
              }
            }

            console.log("filterClientPayload>>>>>>" + this.filterClientPayload)
            return this.service.searchCustomer(this.filterClientPayload)
              .pipe(
                finalize(() => {
                  this.isCustomerLoading = false
                }),
              )
          } else {
            this.isCustomerLoading = false
            return new Observable<[]>();
          }


        }
        )
      )
      .subscribe((response: CustomerResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
         
          if (this.appuser.role == "staff") {
            response.responseData.map(r => {

              r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);
  
              let name_parts = [];
              if (r.CLIENT_FIRST_NAME)
                name_parts.push(r.CLIENT_FIRST_NAME);
  
              if (r.CLIENT_FAMILY_NAME)
                name_parts.push(r.CLIENT_FAMILY_NAME);
  
              if (r.CLIENT_HOME_MOBILE)
                name_parts.push(r.CLIENT_HOME_MOBILE);
  
              //if(r.CLIENT_CODE)
              //name_parts.push(`(${r.CLIENT_CODE})`);
  
              r.DISPLAY_NAME = name_parts.join(',');
  
              /*  let temp=name_parts[0];
               name_parts[0]=name_parts[name_parts.length-1];
               name_parts[name_parts.length-1]=temp;
  
               r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
              r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;
  
              r.isNewCustomer = false;
  
  
              this.filteredCustomers.push(r);
            })

          }else{
            response.profile.map(r => {

              r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);
  
              let name_parts = [];
              if (r.CLIENT_FIRST_NAME)
                name_parts.push(r.CLIENT_FIRST_NAME);
  
              if (r.CLIENT_FAMILY_NAME)
                name_parts.push(r.CLIENT_FAMILY_NAME);
  
              if (r.CLIENT_HOME_MOBILE)
                name_parts.push(r.CLIENT_HOME_MOBILE);
  
              //if(r.CLIENT_CODE)
              //name_parts.push(`(${r.CLIENT_CODE})`);
  
              r.DISPLAY_NAME = name_parts.join(',');
  
              /*  let temp=name_parts[0];
               name_parts[0]=name_parts[name_parts.length-1];
               name_parts[name_parts.length-1]=temp;
  
               r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
              r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;
  
              r.isNewCustomer = false;
  
  
              this.filteredCustomers.push(r);
            })
          }
        }


      });
  }


  onSelectCustomer(customer: CustomerProfile) {
    this.selectedCustomer = customer;
    localStorage.setItem('selectedCustomer', JSON.stringify(this.selectedCustomer))

    this.getPoliciesForRenewal();
  }
  
  onCustomerChange(event) {
    console.log("onCustomerChange>>>> " + event)
    if (!event.target.value) {
      this.policies = [];
      this.selectedCustomer = {
        ...this.selectedCustomer,
        CLIENT_CODE: "",
        CLIENT_FAMILY_NAME: "",
        CLIENT_FIRST_NAME: "",
        DISPLAY_NAME: ""
      };
    }
  }

  getPoliciesForRenewal() {
    this.service.swalAlart.showLoading();
    // let payload={module:this.userdata.role,status_of_client:'renew', client_code:this.selectedCustomer.CLIENT_CODE}

    if (this.userdata.role === 'client') {
      this.requestPayload = {
        ...this.requestPayload,
        module:'client',
        client_code: this.selectedCustomer.CLIENT_CODE
      }
    } else if (this.userdata.role === 'agent') {
      this.requestPayload = {
        ...this.requestPayload,
        module:'agent',
        agent_code: this.userdata.code,
        client_code: this.selectedCustomer.CLIENT_CODE
      }
    } else if (this.userdata.role === 'broker-user') {
      this.requestPayload = {
        ...this.requestPayload,
        module:'broker-user',
        broker_user_code: this.userdata.code,
        client_code: this.selectedCustomer.CLIENT_CODE
      }

   } else if(this.appuser.role==='staff'){
      this.requestPayload={
        ...this.requestPayload,
        module:'staff',
        staff: {
          staff_id: this.appuser.code,
          staff_email: this.appuser.email
        },
        client_code: this.selectedCustomer.CLIENT_CODE
      }
    }
    
    else { }


    this.service.swalAlart.showLoading();
    this.service.getPoliciesForRenewal(this.requestPayload)
      .toPromise()
      .then((res: any) => {

        if(res.hasOwnProperty('responseCode') && res.responseCode=='000'){
          this.policies = res.pageData.content;
          this.dataSource.data = res.pageData.content;
          this.pageSize = res.pageData.size;
          this.length = res.pageData.totalElements;
          this.searchdata = res.pageData.content;
  
          this.service.swalAlart.close();

        }else if(res.hasOwnProperty('responseCode') && res.responseCode=='E42'){
          localStorage.removeItem('selectedCustomer')
          this.policies = []
          this.dataSource.data = []

          this.service.swalAlart.fire({
            title: 'Failed',
            text: res.responseMessage,
            icon: 'error',
          });
        }
      
      }).catch(error => {
        this.service.swalAlart.fire({
          title: 'Failed',
          text: error.error,
          icon: 'error',
        });
        this.service.swalAlart.close();
      })

  }



  handlePageChange(event) {
    console.log("pageIndex>>>> " + event.pageIndex)
    console.log("pageSize>>>> " + event.pageSize)

    this.requestPayload.page_size = event.pageSize
    this.requestPayload.current_page = event.pageIndex

    this.pageChange = true;
    this.getPoliciesForRenewal();

  }





}
