import * as CryptoJS from 'crypto-js';
export class ScKryptoService{
   key: string="23g[0FbV7zx&:*8obZI&lm!uC3'Y:kt:czzSAAH`xVyn>.tj90nrj6?m,m^uIPK";
   constructor(){
   }

   encrypt(plainText,key?){
     return CryptoJS.AES.encrypt(plainText,key?key: this.key).toString();
   }

   decrypt(cipherText,key?){
     return CryptoJS.AES.decrypt(cipherText,key?key:this.key).toString(CryptoJS.enc.Utf8);
   }

   

   transFormLoginResponse(response: any){
    if(response.hasOwnProperty('AGENT_CODE'))
       return {
            first_name:response.AGENT_FIRST_NAME,
            code:response.AGENT_CODE,
            last_name:response.AGENT_FAMILY_NAME,
            email:response.AGENT_WORK_EMAIL,
            phone:response.AGENT_WORK_MOBILE,
            dob:null,
            role:'agent',
            agency:response.AGENT_AGENCY
        }
    else if (response.hasOwnProperty('CLIENT_CODE'))
       return {
           first_name:response.CLIENT_FIRST_NAME,
           code:response.CLIENT_CODE,
           last_name:response.CLIENT_FAMILY_NAME,
           email:response.CLIENT_HOME_EMAIL,
           phone:response.CLIENT_HOME_MOBILE,
           dob:response.CLIENT_DATE_OF_BIRTH,
           role:'client',
           agency:response.CLIENT_AGENCY || null
       }
    else if (response.hasOwnProperty('broker_admin_email'))
       return {
        broker_admin_main_code: response.broker_admin_main_code,
        code:response.broker_admin_main_code,
        first_name: response.broker_admin_first_name,
        last_name: response.broker_admin_last_name,
        email: response.broker_admin_email,
        phone: response.broker_admin_phone,
        broker_admin_status: response.broker_admin_status,
        broker_admin_pin_set: response.broker_admin_pin_set,
        broker_admin_answers_set: response.broker_admin_answers_set,
        role:'broker-admin',

    }
    else if (response.hasOwnProperty('broker_user_code'))
        return {
          first_name:response.broker_user_first_name,
          code:response.broker_user_code,
          last_name:response.broker_user_last_name,
          email:response.broker_user_email,
          phone:response.broker_user_phone,
          broker_user_main_broker_code:response.broker_user_main_broker_code,
          broker_user_pin_set:response.broker_user_pin_set,
          broker_user_answers_set:response.broker_user_answers_set,
          role:'broker-user',
    
      }
      else if (response.hasOwnProperty('staff_id'))
      return {
        first_name:response.staff_name,
        code:response.staff_id,
        last_name:'',
        email:response.staff_email,
        phone:'',
        role:'staff',
  
    }
    else
    return{
      
    }

  }
}
