import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import swal from 'sweetalert2';
import {Router} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Directive, HostListener ,ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UserIdleService } from 'angular-user-idle';
import { DashboardService } from 'src/app/modules/service/dashboard.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {

  // @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  // constructor( private router: Router) { }

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  @ViewChild('sidenav') matSidenav: MatSidenav;

  constructor(private userIdle: UserIdleService, private service: DashboardService,  private router: Router) { }



  ngOnInit() {
    // //Start watching for user inactivity.
    // this.userIdle.startWatching();

    // // Start watching when user idle is starting.
    // this.userIdle.onTimerStart().subscribe(count => console.log(count));

    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(()=>{
    //   console.log('Time is up!')
    //   this.service.logoutUser();
    //   this.stop();
    //   this.router.navigate([''])
    //   location.reload();
    // });

  }


  //Idle ts functions

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  toggleSidebar(){
    // const canvas: any = document.getElementById('nav').style.width = "250px";;
     //const ctx = canvas.getContext('2d').style.width = "250px";;

   //  document.getElementById('nav').style.width = "250px";;
     this.toggleSideBarForMe.emit()
     setTimeout(() =>{
       window.dispatchEvent(
         new Event('resize ')
       )
     }, 18);
   }



  logout(){
    this.stop();

    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'logout ?',
      text: "You are logging out...",
      showCancelButton: true,
      confirmButtonText: 'Yes Confirm',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        // swalWithBootstrapButtons.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )


    window.localStorage.clear();
    this.router.navigate(['']);

      } else (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      )

    })

}


userprofile(){
  // router here.
}


}
