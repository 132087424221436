import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/modules/service/dashboard.service';
import swal from 'sweetalert2'

@Component({
  selector: 'app-widget-cashinflow',
  templateUrl: './cashinflow.component.html',
  styleUrls: ['./cashinflow.component.scss']
})
export class CashinflowComponent implements OnInit {

  total:any;
  transact:any;
  pending:any;
  transacttoday:any;
  policytoday:any;

  loaderror:any='';
  loading:boolean = false;
  userdata :any //get users email




  constructor(private service:DashboardService) {
    this.userdata=this.service.currentUser();
  }

  ngOnInit() {

    this.loading = true
    let payload: any={}

    if(this.userdata.role==='client'){
      payload={
        role:'client',
        client_code:this.userdata.code
      }
    }else if(this.userdata.role==='agent' || this.userdata.role==='broker-admin'){
      payload={
        role:'agent',
        agent_code:this.userdata.code
      }
    }else if(this.userdata.role==='broker-user'){
      payload={
        role:'broker-user',
        broker_user_code: this.userdata.code
      }
   
    }else if(this.userdata.role==='staff'){
      payload={
        role:'staff',
        staff_id: this.userdata.code
      }
   
    }
    else{}

      this.service.Cashsegregate(payload).subscribe(data=>{
        this.loading = false
        this.total = parseFloat(data['cash'][0]['totalAmount'].replace(",",""))

      }, error => {
        this.loading = false
        this.loaderror = 'failed to fetch data. Reload'
        swal.fire({
          title: 'Oops...',
          text: error.name,
          footer: ''
        });
      });

  }



}
