import { Component, OnInit,  ElementRef, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe} from '@angular/common';

import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import swal, { SweetAlertResult } from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ShowPremiumTotalComponent } from '../modules/dialogs/show-premium-total/show-premium-total.component';
import { SelectPaymentMethodComponent } from '../modules/dialogs/select-payment-method/select-payment-method.component';
import { CashPaymentComponent } from '../modules/dialogs/cash-payment/cash-payment.component';
import { ChequePaymentComponent } from '../modules/dialogs/cheque-payment/cheque-payment.component';
import { CreateCustomerComponent } from '../modules/dialogs/create-customer/create-customer.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { DashboardService } from '../modules/service/dashboard.service';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { QuoteSummaryComponent } from '../modules/dialogs/quote-summary/quote-summary.component';
import { SignupComponent } from '../modules/dialogs/signup/signup.component';
import Swal from 'sweetalert2';
import { DebitCardPaymentComponent } from '../modules/dialogs/debit-card-payment/debit-card-payment.component';
import { MomoPaymentComponent } from '../modules/dialogs/momo-payment/momo-payment.component';


interface CustomerProfile{
  CLIENT_CODE: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?:boolean;
}
interface MakeResponse{
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: any;
  responseData: Make[];
}
interface Make{
  VEHICLE_MAKE_CODE: string;
  VEHICLE_MAKE_DESC: string;
  DISPLAY_SHORT_NAME: string;
}
interface MakeResponse{
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: any;
  responseData: Make[];
}
interface Model{
  VEHICLE_MODEL_CODE: string;
  VEHICLE_MODEL_MAKE_CODE: string;
  VEHICLE_MODEL_DESC: string;
  VEHICLE_MODEL_SECOND_DESC: string;
  DISPLAY_SHORT_NAME: string;
}
interface ModelResponse{
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: any;
  responseData: Model[];
}
interface coverType {
  value: string;
  viewValue: string;
}

interface scheduleTypeResponse{
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: scheduleType[];
}

interface scheduleType {
  TABLED_CODE: string;
  TABLED_NAME: string;
  TABLED_DESIGNATION: string;
  HIDE:boolean;
}

interface CurrencyResponse{
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Currency[];
}

interface Color{
  TABLED_NAME: string;
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
  DISPLAY_SHORT_NAME: string;
}
interface colorResponse{
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Color[]
}
interface duration {
  value: string;
  viewValue: string;
  days: string;
}

interface BodyType {
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
}

interface QuoteResponse{
  responseCode: string;
  responseMessage: string;
  responseData: string;
  responseNumber: string;
}

interface BodyTypeResponse{
  responseCode: string;
  responseData: BodyType[];
}

interface year {
  value: string;
  viewValue: string;
}


interface Currency{
  CURRENCY: string;
  DATE_UPDATED: string;
  CURRENCY_VALUE: number;
}
interface accessList{
  value: string;
  viewValue: string;
}


var i:number;

var arrayYear = [];

@Component({
  selector: 'app-quick-motor-quote',
  templateUrl: './quick-moto-quote.component.html',
  styleUrls: ['./quick-moto-quote.component.scss'],
  providers: [DatePipe]
})

export class QuickMotoQuoteComponent implements OnInit {

  minCapacity:String;
  maxCapacity:String;

   //make
   searchMakeCtrl=new FormControl();
   loadedMakes: Make[];
   filteredMakes: Make[];

   //model
   searchModelCtrl=new FormControl();
   loadedModels: Model[]=[];
   filteredModels: Model[]=[];

  //color
  searchColorCtrl=new FormControl();
  filteredColors: Color[];
  isColorLoading=false;

  isMakeLoading:boolean=false;
  isModelLoading:boolean=false;

  //payload for submitting quote
  payload: any;

  coverData: coverType[] = [
    {value: '203', viewValue: 'MOTOR THIRD PARTY ONLY'},
    {value: '201', viewValue: 'MOTOR COMPREHENSIVE'},
    {value: '202', viewValue: 'MOTOR THIRD PARTY FIRE & THEFT'},
  ];

  dobStartAt=new Date(moment(moment().format('YYYY-MM-DD')).subtract(18,'years').format('YYYY-MM-DD'))

  scheduleData: scheduleType[] = [];

  isNewCustomer:true;
  bodyData: BodyType[] = [];

  durationData: duration[] = [
    {value: '01', viewValue: 'Annual Term', days: '365'},
    {value: '02', viewValue: 'Short Term', days: '1'}
    // {value: '03', viewValue: 'Prorata Term', days: '1'},
  ];

  yearData: year[] = arrayYear;

  currencyData: Currency[]=[]

  accessData: accessList[]=[
    {value:"0", viewValue:"0"},
    {value:"1", viewValue:"1"},
    {value:"2", viewValue:"2"},
    {value:"3", viewValue:"3"},
    {value:"4", viewValue:"4"},
    {value:"5", viewValue:"5"},
    {value:"6", viewValue:"Above 5"},
  ]

//selected records from dialogs
selectedCustomer: CustomerProfile={
  CLIENT_CODE: "",
  CLIENT_FAMILY_NAME: "",
  CLIENT_HOME_MOBILE: "",
  CLIENT_FIRST_NAME: "",
  CLIENT_HOME_EMAIL: "",
  CLIENT_DATE_OF_BIRTH: "",
  DISPLAY_NAME: "",
  DISPLAY_SHORT_NAME: "",
  isNewCustomer:false
};
selectedMake: Make={
  VEHICLE_MAKE_CODE: "",
  VEHICLE_MAKE_DESC: "",
  DISPLAY_SHORT_NAME: ""
}
selectedModel: Model={
  VEHICLE_MODEL_CODE: "",
  VEHICLE_MODEL_MAKE_CODE: "",
  VEHICLE_MODEL_DESC: "",
  VEHICLE_MODEL_SECOND_DESC: "",
  DISPLAY_SHORT_NAME :""
}
selectedColor: Color={
  TABLED_NAME:"",
  TABLED_CODE:"",
  TABLED_DESIGNATION:"",
  DISPLAY_SHORT_NAME:""
}
//searchCustomerDialogValue :any;
  //prepare quote json
  confirmedQuote:boolean=false;
  errors:any={
    customer_code:false,
      customer_phone:false,
      date_of_birth:false,
      year_of_manufacture:false,
      cubic_capacity:false,
      mileage:false,
      vehicle_registration:false,
      seats:false,
      chasis_number:false,
      body_type_code:false,
      cover_type_code:false,
      schedule_code:false,
      start_date:false,
      end_date:false,
      make:false,
      model:false,
      color:false,
      currency_code:false,
      sum_insured:false,
      customer_first_name:false,
      customer_last_name:false,
      customer_email:false
  };
  selectedMethod:string;

  //is processing
  processing=false;
  quote: any={
    //customer_id:"",
    agent_id:"VAN-900913",
    prepared_by: "",
    module : "Agent",
    status_of_client : "new",
    vehicle_registration: "",
    make: "",
    model : "",
    chasis_number: "",
    year_of_manufacture: "",
    body_type_code: "",
    seats: 5,
    currency_code: "GHC",
    cubic_capacity: "",
    fleet: null,
    exchange_rate: '1',
    cover_type_code: "",
    cover_name:"",
    schedule_code: "",
    schedule_name:"",
    computation_type_code: "01",

    days_label: "Days",
    days: "365",
    _days: "365",
    _months:'',

    comment: "",
    mileage: "",

    loadings: null,
    total_loadings: "0.0",
    short_rate: "100.0",
    color: "",
    fleet_import_id: null,
    schedule_id: null,

    customer_date_of_birth:"29-01-1988",
    customer_first_name: "",
    customer_last_name: "",
    customer_other_names: "",
    customer_email: "",
    customer_phone: "",
    customer_reference: "",
    customer_branch_code: "H1",
    customer_type_code: "01",

    intermediary_number: "AGT-MAXk-0000022",
    intermediary_nic_registration_number: "NIC/MC/020",
    intermediary_first_name: "dd",
    intermediary_last_name: "sd",
    intermediary_other_names: "",
    intermediary_phone: "",
    intermediary_email: "",
    intermediary_type_code: "04",
    intermediary_branch_code: "502",
    branch_code: "",

    cost_of_car: "",
    insurance_type: "",
    rate:  "",
    managerEmail: "",

    total_premium: "",
    invoice: "",
    date: "",
    date_of_birth: "",
    term:"",
    start_date:new Date(),
    end_date:"",
    sum_insured:"",
    applicable_accesses:[],
    excess_buy_back:false,
    tppd:false,
    tppd_value:0,
    pa:false,
    pa_value:0,
    ncd:false,
    ncd_value:"0",
    fd:false,
    vehicle_age_loading:false,
    cc_loading:false,
    customer_code:"",

    //toggle applicable access
    can_excess_buy:true,
    can_tppd_limit:true,
    can_pa:true,
    can_ncd:true,
    can_fd:true,
    can_vehicle_age_loading:true,
    can_cc_loading:true,
    type:"",
    digital_address:"",
    id_type:"",
    id_number:"",
    tin:"",
    policy_no:""
}


  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;

  isOptional = false;

  formattedAmount;
  amount;

  todayDate = new Date();
  dateTxt: string;

  formStep:number=1;

  daysTextFieldInput: any;
  annual:boolean=true;


  constructor(private datePipe: DatePipe, private _formBuilder: FormBuilder, private service:DashboardService, private router:Router, private currencyPipe : CurrencyPipe,public dialog: MatDialog,public dialogRef: MatDialogRef<QuickMotoQuoteComponent>,private _snackBar: MatSnackBar) {
   this.dateTxt = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');

   this.quote.agent_id = ""
    this.quote.intermediary_first_name = ""
    this.quote.intermediary_last_name = ""

    this.quote.intermediary_email = ""
    this.quote.managerEmail =null //this.appuser.managerEmail
    this.quote.prepared_by = ""


    this.minCapacity="1";
    this.maxCapacity="";


    this.quote={
      ...this.quote,
      customer_first_name:this.selectedCustomer.CLIENT_FIRST_NAME,
      customer_last_name:this.selectedCustomer.CLIENT_FAMILY_NAME,
      customer_email:this.selectedCustomer.CLIENT_HOME_EMAIL,
      customer_phone:this.selectedCustomer.CLIENT_HOME_MOBILE,
      policy_no:"",
    }

    this.quote.days=this.durationData[0].days;
    this.quote.term=this.durationData[0].value;

    this.selectedMethod='Mobile';

    this.onDaysChanged(parseInt(this.quote.days));

    this.showSchedulesAndRisks();
    this.showCurrencies();
    this.showBodyTypes();

     //Load car Year
     for (i = (new Date()).getFullYear(); i > 1950; i--) {
      arrayYear.push(i)
     }

  }

  ngOnInit() {
     //Load makes
  this.onLoadMake();

  //on Make Filter
  this.onFilterMake();

  //on Model Filter
  this.onFilterModel();

  //on Model Filter
  this.onFilterColor();

   //autoload all models
    setTimeout(()=>{
      this.onLoadModel()
    },1);

  }

  onCoverSelected(code: string)  {
   //console.log("coverType=======" + code);
    this.quote.cover_type_code = code;

    //toggle applicable accesses
    this.quote.can_cc_loading=this.quote.can_vehicle_age_loading=this.quote.can_fd=this.quote.can_ncd=this.quote.can_pa=this.quote.can_tppd_limit=this.quote.can_excess_buy=true;

    if(this.quote.cover_type_code.toUpperCase()==='203')
    {
      this.quote.can_excess_buy=false;

      this.quote.can_ncd=false;
      this.quote.can_fd=false;
      this.quote.can_vehicle_age_loading=false;
      this.quote.can_cc_loading=false;

      this.quote.sum_insured="0";
    }
    else
    this.quote.sum_insured="";


   this.validateForm();
  }

  onScheduleSelected(code: string){
    this.minCapacity='1';
    this.maxCapacity='';

    let selectedSchedule:any=this.scheduleData.filter(r=>r.TABLED_CODE==code);

    if(selectedSchedule){
      selectedSchedule=selectedSchedule[0];

      if(selectedSchedule.TABLED_DESIGNATION=='OWN GOODS CARRYING Z.300 (UPTO - 3000 CC)'){
        this.minCapacity='1';
        this.maxCapacity='3000';
      }
      else if(selectedSchedule.TABLED_DESIGNATION=='OWN GOODS CARRYING Z.300 (ABOVE - 3000 CC)'){
        this.minCapacity='3000';
        this.maxCapacity='';
      }
    }

   // console.log("scheduleType=======" + code);
    this.quote.schedule_code = code;

    this.validateForm();
  }

  //on term selection
  onDurationSelected(){

    //Get the associated days to the term
    let durations= this.durationData.filter((duration: duration)=>duration.value==this.quote.term);

    if(durations.length>0)
    this.quote.days=durations[0].days;

    //recalculate the end date
    if(!isNaN(+`${this.quote.days}`) && this.quote.start_date)
      this.quote.end_date=moment(this.quote.start_date).subtract((this.quote.term=='02'?1:0),'days').add(parseInt(this.quote.days)>=364?364:parseInt(this.quote.days),(this.quote.term=='02'?'months':'days')).format('YYYY-MM-DD');

    //change the days label to months when the term changes to short term
    if(this.quote.term=='02'){

      this.quote.days_label='Days';
      let days=this.quote.days=Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'),'days'));
      this.quote._days=days?days:1;
      this.quote._months='';

      this.daysTextFieldInput = parseInt(this.quote.days) + 1;
      this.annual = false;

      // this.quote.days_label='Months';
      // let months=Math.round(Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'),'days'))/31);
      // this.quote._months=this.quote.days=months?months:1;

      // let days=Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'),'days'));
      // this.quote._days=days?days:1;

    }else if(this.quote.term=='01' && this.quote.days!='' && parseInt(this.quote.days)>=364){
      
      this.quote.days=365;
      this.daysTextFieldInput = parseInt(this.quote.days);
      this.annual = true;

    }else{
      this.quote.days_label='Days';
      let days=this.quote.days=Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'),'days'));
      this.quote._days=days?days:1;
      this.quote._months='';

    }

    
    // //hide thirdparty for prorata
    // for(let i=0;i<this.coverData.length;i++){
    //   if(this.quote.term=='03' && this.coverData[i].value=='203')
    //   this.coverData[i].hide=true;
    //   else
    //   this.coverData[i].hide=false;
    // }

   
  }
  onBodySelected(code: string){
    //console.log("body=======" + code);
    this.quote.body_type_code = code;
     this.validateForm();
  }

  onYearSelected(code: string){
    //console.log("year=======" + code);
    this.quote.year_of_manufacture = code;
    this.validateForm();
  }

  onCurrencySelected(code: string){
    let selectedCurrency= this.currencyData.filter((currency: Currency)=>currency.CURRENCY==code);

    if(selectedCurrency.length){
      this.quote.exchange_rate=selectedCurrency[0].CURRENCY_VALUE.toString();
      this.quote.currency_code=code;
    }

    //console.log('Currency',this.quote.currency_code);
    this.validateForm();
  }


  onNCDSelected(code: string){
    this.quote.ncd_value=code;
  }


  onStartDateChanged(){
    let start_date= this.quote.start_date?moment(this.quote.start_date).format('YYYY-MM-DD'):null;

    let days=parseInt(this.quote.days);

    if(days>=365)
    days=364;

    let end_date= moment(start_date).add(!isNaN(+`${days}`)?days:1 ,'days').format('YYYY-MM-DD');

    this.quote.end_date=end_date;


    if(this.quote.start_date && this.quote.end_date){
      if(this.quote.term=='01')
      this.quote.days='365';
      else{
       this.quote.days=this.quote._days=moment(end_date).diff(start_date,'days').toString();
       
       this.daysTextFieldInput = parseInt(this.quote.days) + 1;


       //  if(this.quote.term=='02')
      //  this.quote.days=this.quote._months=Math.round(moment(end_date).diff(start_date,'days')/31);
      
      }
    }
    this.validateForm();


  

 }
 onEndDateChanged(date: Date){
   let start_date= this.quote.start_date?moment(this.quote.start_date).format('YYYY-MM-DD'):null;

   let days=0,months=0;
   if(start_date){
    if(this.quote.term=='02')
    {
      days=moment(moment(date).format('YYYY-MM-DD')).diff(start_date,'days');
      this.quote.days=days.toString();

      this.daysTextFieldInput = parseInt(this.quote.days) + 1;


      // months=Math.round(moment(moment(date).format('YYYY-MM-DD')).add(1,'days').diff(start_date,'days')/31);
      // this.quote.days=this.quote._months=this.quote._months=months;

      // if(this.quote.term=='02' && months==0){
      //  this.quote.term='03';
      //  this.quote.days_label="Days"
      // }
    }
     if(this.quote.term!='02')
    {
       days=moment(moment(date).format('YYYY-MM-DD')).diff(start_date,'days');
       this.quote.days=days.toString();

       this.daysTextFieldInput = parseInt(this.quote.days);

    }
   }

  if(days && days<365 && this.quote.term=='01'){
    months=moment(moment(date).format('YYYY-MM-DD')).diff(start_date,'months');

    if(months){
      this.quote.days_label="Days";
      this.quote.term='02';
     

    //this.quote.days_label="Months";
    //this.quote.term='02';
    //this.quote.days=months;

    }
    else{
      this.quote.days_label="Days";
      this.quote.term='03';
    }
  }
  else if(days==365)
  this.quote.term='01';

  this.validateForm();

 


 }



//Start Date dateFilters
startDateFilter =(date: Date)=>{
  return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'),'days')>=0;
}
  //End Date dateFilters
endDateFilter =(date: Date)=>{
    let diff=moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.quote.start_date).format('YYYY-MM-DD'),'days');
    return  diff>0 && diff<=364;
}


/* handle access changes===*/
onExcessBuyBackChange(checked: boolean){
    this.quote.excess_buy_back=checked;
  }
  onTppdChange(checked: boolean){
    this.quote.tppd=checked;
  }
  onPaChange(checked: boolean){
    this.quote.pa=checked;
  }
  onNcdChange(checked: boolean){
    this.quote.ncd=checked;
  }
  onFdChange(checked: boolean){
    this.quote.fd=checked;
  }
  onVehicleAgeLoadingChange(checked: boolean){
    this.quote.vehicle_age_loading=checked;
  }
  onCCLoadingChange(checked: boolean){
    this.quote.cc_loading=checked;
  }



    //choose payment method
    choosePaymentMethod(){
      const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
        width: '350px',
        data: { quotePayload: this.payload,method:this.selectedModel,total_premium:this.quote.total_premium}
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result.hasOwnProperty('data')){
          this.confirmedQuote = result.data.confirmedQuote;
          this.selectedMethod=result.data.method;

          if(this.selectedMethod)
             this.confirmPin();


          this.validateForm();
        }
      });
    }


     //open momo Payment Dialog
     openMomoPaymentDialog(){
      const dialogRef = this.dialog.open(MomoPaymentComponent, {
        disableClose :true,
        width: '350px',
        data: { quotePayload: this.payload,total_premium:this.quote.total_premium,policy_lob: "20"}
      });

      
    }

  //open card Payment Dialog
  openCardPaymentDialog(){
    const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
      disableClose :true,
      width: '350px',
      data: { quotePayload: this.payload,total_premium:this.quote.total_premium,policy_lob:"20"}
    });
    dialogRef.afterClosed().subscribe(result => {

      if(result.hasOwnProperty('data')){
        this.confirmedQuote = result.data.quotePayload.module=='PAID';

        if(this.confirmedQuote)
        {
          setTimeout(()=>{
            this.router.navigate(['transactions']);
          },3000)
        }
      }

    });
  }





    //confirm login pin to continue
confirmPin(){
  this.service.swalAlart.fire({
    //title: "Identity Confirmation",
    icon:"warning",
    allowOutsideClick:false,
    showCancelButton: true,
    inputPlaceholder: "Confirm your Pin",
    input:"password",
    reverseButtons: true
  })
  .then((result:SweetAlertResult)=>{

       if(result.value){
        let currentUser= this.service.currentUser();
        //console.log('Current user',currentUser)
        //prepare payload
        let loginPayload={};
        if (currentUser.role == "client")
            loginPayload={phone:currentUser.phone,pin:result.value,module:'client'};
        else
            loginPayload={agent_code:currentUser.code,pin:result.value,module:'agent'};

          Swal.showLoading()
          this.service.webLogin(loginPayload)
          .toPromise()
          .then((responseData: any)=>{
            Swal.hideLoading()

            if(responseData.hasOwnProperty('responseCode') && responseData.responseCode==='000'){
              if(this.selectedMethod=='Mobile')
              this.openMomoPaymentDialog();
              else if(this.selectedMethod=='Cash')
              this.openCashPaymentDialog();
              else if(this.selectedMethod=='Cheque')
              this.openChequePaymentDialog();
              else if(this.selectedMethod=='Card')
              this.openCardPaymentDialog();
            }
            else{
              Swal.fire({
                title: 'Wrong credentials!',
                text: 'Please check and try again',
                icon: 'error',
              });
              setTimeout(()=>{this.confirmPin()},2000);
            }

          }).catch(error=>{
            Swal.fire({
              title: 'Connection Failed!',
              text: 'Login Failed',
              icon: 'error',
            });
            setTimeout(()=>{this.confirmPin()},2000);
          })
       }
       else
       this.alertSnackBar('No Pin was entered','User Not Verified');
     });
   }

        //open cash Payment Dialog
        openCashPaymentDialog(){
          const dialogRef = this.dialog.open(CashPaymentComponent, {
            width: '350px',
            data: { quotePayload: this.payload,total_premium:this.quote.total_premium}
          });
          dialogRef.afterClosed().subscribe(result => {

            if(result.hasOwnProperty('data')){
              this.confirmedQuote = result.data.quotePayload.module=='PAID';
              this.validateForm();

              if(this.confirmedQuote)
              {
                this.quote={};
                this.payload={};
              }
            }

          });
        }
        //open Cheque Payment Dialog
        openChequePaymentDialog(){
          const dialogRef = this.dialog.open(ChequePaymentComponent, {
            width: '600px',
            data: { quotePayload: this.payload,total_premium:this.quote.total_premium}
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result.hasOwnProperty('data')){
              this.confirmedQuote = result.data.quotePayload.module=='PAID';
              this.validateForm();

              if(this.confirmedQuote)
              {
                this.quote={};
                this.payload={};
              }
            }
          });
        }

    //confirm quote
    confirmQuote(){
      let selectedRisk: any=this.scheduleData.filter((risk: scheduleType)=> risk.TABLED_CODE==this.payload.risk);

      if(selectedRisk)
      selectedRisk=selectedRisk[0];

      let summary={
        INCEPTION_DATE:moment(this.quote.start_date).format('DD/MM/YYYY'),
        EXPIRY_DATE:moment(this.quote.end_date).format('DD/MM/YYYY'),
        DATE_COMPLETED:moment().format('DD/MM/YYYY'),
        PREPARED_BY:this.payload.prepared_by,
        CUSTOMER_FIRST_NAME:this.payload.customer_first_name,
        CUSTOMER_LAST_NAME:this.payload.customer_last_name,
        CUSTOMER_EMAIL:this.payload.customer_email,
        CUSTOMER_PHONE:this.payload.customer_phone,
        YEAR_OF_MANUFACTURE:this.payload.year_of_manufacture,
        VEHICLE_REGISTRATION:this.payload.vehicle_registration,
        MAKE:this.payload.make,
        QUOTE_MODEL:this.payload.model,
        CUBIC_CAPACITY:this.payload.cubic_capacity,
        SUM_INSURED:this.payload.sum_insured,
        QUOTE_DAYS:(this.payload.duration_type=='shortrate'?this.payload.months:this.payload.days),
        DURATION_TYPE:this.payload.duration_type,
        EXCESS_TYPE_CODE:this.payload.excess_type_code,
        CURRENCY_CODE:this.payload.currency_code,
        RISK_DESCRIPTION: selectedRisk?selectedRisk.TABLED_DESIGNATION:null,
        COST_OF_INSURANCE:this.quote.total_premium,
        TPPD_LIMIT:this.quote.tppd_value,
        MONTHS: this.payload.months?this.payload.months:1,
        PRODUCT_CODE: this.payload.product,
        INPUTTED_DISCOUNT:this.payload.inputted_discount
      }
      const dialogRef = this.dialog.open(QuoteSummaryComponent, {
        disableClose :true,
        width: '500px',
        data:  {
          flow:'moto',
          hide_foreign_currency:0,
          quote:summary,
          total_premium: this.quote.total_premium,
          foreign_currency:this.quote.foreign_currency,
          foreign_amount:this.quote.foreign_amount,
          confirmedQuote:true
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.hasOwnProperty('data')){
           //ask client to register in order to proceed
           this.service.swalAlart.fire({
            icon:"question",
            html:"To make payment for this policy,you need to create an account.<br/>Do you wish to proceed?",
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:"Yes",
            cancelButtonText:"NO",
            reverseButtons:true
          })
          .then((result: SweetAlertResult)=>{
              //require registration on payment
              if(result.value)
                 this.openSignUpDialog();
          })
          /* this.confirmedQuote = result.data.confirmedQuote;
          this.choosePaymentMethod();
          this.validateForm(); */
        }
      });
    }

    openSignUpDialog(){
      const dialogRef = this.dialog.open(SignupComponent, {
        disableClose :true,
        width: '860px',
        data: {
          customer_first_name:this.quote.customer_first_name,
          customer_last_name:this.quote.customer_last_name,
          customer_email:this.quote.customer_email,
          customer_phone:this.quote.customer_phone,
          idNumber:this.quote.passport_no,
          idType: 'Passport',
          via_quick_quote:true
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log('Response Data',result)
        if(result.hasOwnProperty('data') && result.data['client_code']){
          //console.log('Response Data',result.data)
          this.quote.customer_code=this.payload.client_code=result.data['client_code']
          this.quote.customer_email=this.payload.customer_email=result.data['customer_email']
          this.quote.customer_phone=this.payload.customer_phone=result.data['customer_phone']

            //update the quote with the generated client_code
            this.service.swalAlart.showLoading();
            this.service.submitMotoQuote(this.payload)
            .toPromise()
            .then((response: any)=>{
                //create a client profile
                let client={
                  CLIENT_FIRST_NAME:this.quote.customer_first_name,
                  CLIENT_CODE:result.data['client_code'],
                  CLIENT_FAMILY_NAME:this.quote.customer_last_name,
                  CLIENT_HOME_EMAIL:this.quote.customer_email,
                  CLIENT_HOME_MOBILE:this.quote.customer_phone,
                  CLIENT_DATE_OF_BIRTH:result.data['dob'],
                  role:'client',
                  agency:null
                }

                this.service.currentUser(client,true)
                this.choosePaymentMethod();
                this.service.swalAlart.close();
                this.processing=false;
            }).
            catch(error=>{
              //console.log('submit error',error)
              this.processing=false;
              this.service.swalAlart.close()
              this.service.swalAlart.fire({
                title: 'Error',
                text: 'Server Error,retry later',
                icon: 'error',
              });
            })
        }
      });
    }

    
    onDaysChanged(days: number){
      //days=!isNaN(+`${this.quote.days}`)?parseInt(this.quote.days):1;
        if(days>=365){
          days=364;
          this.quote.days="365";
          this.quote.term="01";
  
          this.daysTextFieldInput = "365";
          this.annual = true;
  
         // console.log('Days changed is annual',days);
        
        }
        else
        this.quote.term="02";
  
        //this.daysTextFieldInput = parseInt(this.quote.days) + 1;
  
        //restrict negative days
        if(days<=0)
        this.quote.days='1';
  
  
  
        if(this.quote.start_date){
          let start_date= this.quote.start_date?moment(this.quote.start_date):null;
  
          let end_date= moment(start_date).add(days ,'days').format('YYYY-MM-DD');
  
          this.quote.end_date=end_date;
        }
  
  
  
  
    }
  

/*=== //handle Access changes==*/
  transformAmount(element){
    this.formattedAmount = this.currencyPipe.transform(this.formattedAmount, 'GHS ');
    element.target.value = this.formattedAmount;
}

onDaysInput(event){
    let days=!isNaN(+`${event.target.value}`)?parseInt(event.target.value):0;

    if(!days){
      event.target.value=1;
    }
    else if(days>365)
    event.target.value=365;
}


  onBackClicked(){
    this.router.navigate(["/products"])
  }

  //http requests
  showSchedulesAndRisks(){
    this.service.getSchedulesAndRisks()
    .toPromise()
    .then((response :scheduleTypeResponse)=>{
      if(response.responseCode=='000'){
        //console.log('schedules',response.profile);
         response.profile.map((schedule :scheduleType)=>{
             this.scheduleData.push(schedule);
         })
      }
    }).catch(err =>{
          // console.log('Schedules load error',err);
      });
  }

  //open customer creation
  openCustomerCreationDialog(){
    const dialogRef = this.dialog.open(CreateCustomerComponent, {
      width: '800px',
      data: { newCustomerDetails: {} }
    });

    dialogRef.afterClosed().subscribe(result => {

        this.quote={
          ...this.quote,
          customer_code:result.data.customer_code,
          type:result.data.type,
          customer_first_name:result.data.customer_first_name,
          customer_other_names:result.data.customer_other_names,
          customer_last_name:result.data.customer_last_name,
          date_of_birth:moment(result.data.date_of_birth).format('YYYY-MM-DD'),
          customer_email:result.data.customer_email,
          customer_phone:result.data.customer_phone,
          digital_address:result.data.digital_address,
          id_type:result.data.id_type,
          id_number:result.data.id_number,
          tin:result.data.tin
        }

        if(this.quote.type=='0')
        this.selectedCustomer.DISPLAY_SHORT_NAME=`${this.quote.customer_first_name},${this.quote.customer_last_name},${this.quote.customer_phone}`;
        else
        this.selectedCustomer.DISPLAY_SHORT_NAME=`${this.quote.customer_first_name},${this.quote.customer_phone}`;

    });
  }
  //http requests
  showCurrencies(){
    this.service.getCurrencies()
    .toPromise()
    .then((response :CurrencyResponse)=>{
      if(response.responseCode=='000' && response.responseData){
         response.responseData.map((currency :Currency)=>{
             this.currencyData.push(currency);
         })
      }
    }).catch(err =>{
          // console.log('Currencies load error',err);
      });
  }

  showBodyTypes(){
    this.service.getBodyTypes()
    .toPromise()
    .then((response :BodyTypeResponse)=>{
      if(response.responseCode=='000'){
         response.responseData.map((body :BodyType)=>{
             this.bodyData.push(body);
         })
      }
    }).catch(err =>{
       // console.log('Body Type load error',err);
      });
  }

  //run validation
  validateForm(input? :any) :boolean{
    let hasError=false;
    this.errors={
      customer_code:false,
      customer_phone:false,
      date_of_birth:false,
      year_of_manufacture:false,
      cubic_capacity:false,
      mileage:false,
      vehicle_registration:false,
      seats:false,
      chasis_number:false,
      body_type_code:false,
      cover_type_code:false,
      schedule_code:false,
      start_date:false,
      end_date:false,
      make:false,
      model:false,
      color:false,
      currency_code:false,
      sum_insured:false,
      customer_first_name:false,
      customer_last_name:false,
    }
    if(this.formStep==1 && !this.quote.customer_first_name){
      hasError=true;
      this.errors={...this.errors,customer_first_name:true};
    }
    if(this.formStep==1 && !this.quote.customer_last_name){
      hasError=true;
      this.errors={...this.errors,customer_last_name:true};
    }
    if(this.formStep==1 && !this.service.validatePhone(this.quote.customer_phone)){
      hasError=true;
      this.errors={...this.errors,customer_phone:true};
    }
    if(this.formStep==1 && this.quote.customer_email && !this.service.validateEmail(this.quote.customer_email)){
      hasError=true;
      this.errors={...this.errors,customer_email:true};
    }

    if(this.formStep==1 && !(moment(this.quote.date_of_birth).isValid() && moment(moment().format('YYYY-MM-DD')).diff(moment(this.quote.date_of_birth).format('YYYY-MM-DD'),'years')>=18)){
      hasError=true;
      this.errors={...this.errors,date_of_birth:true};
       this.alertSnackBar(`Date of birth must be greater or equal to:${moment(this.dobStartAt).format('LL')}`,'Invalid Date');
    }
    if(this.formStep==1)
    {
       if(!hasError)
       return hasError;
    }


    if(this.formStep==2 && !this.quote.schedule_code){
      hasError=true;
      this.errors={...this.errors,schedule_code:true};
    }
    if(this.formStep==2 && !moment(this.quote.start_date).isValid()){
      hasError=true;
      this.errors={...this.errors,start_date:true};
    }

    if(this.formStep==2 && !moment(this.quote.end_date).isValid()){
      hasError=true;
      this.errors={...this.errors,end_date:true};
    }

    if(this.formStep==2 && !this.quote.cover_type_code.trim()){
      hasError=true;
      this.errors={...this.errors,cover_type_code:true};
    }

    if(this.formStep==2)
    {
       if(!hasError)
       return hasError;
    }

    if(this.formStep==3 && !this.quote.year_of_manufacture){
      hasError=true;
      this.errors={...this.errors,year_of_manufacture:true};
    }

    if(this.formStep==3 && !this.quote.make){
      hasError=true;
      this.errors={...this.errors,make:true};
    }

    if(this.formStep==3 && !this.quote.model){
      hasError=true;
      this.errors={...this.errors,model:true};
    }

    if(this.formStep==3 && !this.quote.color){
      hasError=true;
      this.errors={...this.errors,color:true};
    }

    if(this.formStep==3)
    {
       if(!hasError)
       return hasError;
    }



    if(this.formStep==4 && !this.quote.cubic_capacity){
      hasError=true;
      this.errors={...this.errors,cubic_capacity:true};
    }
   /*  if(this.formStep==4 && !this.quote.mileage){
      hasError=true;
      this.errors={...this.errors,mileage:true};
    } */
    if(this.formStep==4 && this.quote.vehicle_registration.trim().length==0){
      hasError=true;
      this.errors={...this.errors,vehicle_registration:true};
    }
    if(this.formStep==4 && !this.quote.seats){
      hasError=true;
      this.errors={...this.errors,seats:true};
    }
    if(this.formStep==4 && !this.quote.chasis_number){
      hasError=true;
      this.errors={...this.errors,chasis_number:true};
      setTimeout(()=>{
        this.alertSnackBar("Vehicle chasis number is required","Note:: Chasis number");
      },2000)
    }
    else if(this.formStep==4 && (!this.quote.chasis_number && this.quote.chasis_number.length>17)){
      hasError=true;
      this.errors={...this.errors,chasis_number:true};
      setTimeout(()=>{
        this.alertSnackBar("Vehicle chasis number should be a maximum of 17 characters long","Note:: Chasis number");
      },2000)
    }

    if(this.formStep==4 && this.quote.body_type_code.trim().length==0){
      hasError=true;
      this.errors={...this.errors,body_type_code:true};
    }

    if(this.formStep==4){
      if(!hasError)
       return hasError;
    }

    if(this.formStep==5 && this.quote.currency_code.trim().length==0){
      hasError=true;
      this.errors={...this.errors,currency_code:true};
    }


    if(this.formStep==5){
      if(!hasError)
       return hasError;
    }


    //console.log('step and error',{formStep:this.formStep,error:this.errors});

    return hasError;
  }

  //on previous
  onPrevious=()=>{
    if(this.formStep>1)
    this.formStep--;
  }
  //On Form submit
  onSubmit=()=>{
        //event.preventDefault();
        if(this.formStep==1 && !this.validateForm())
        this.formStep=2;
        else if(this.formStep==2 && !this.validateForm())
        this.formStep=3;
        else if(this.formStep==3 && !this.validateForm())
        this.formStep=4;
        else if(this.formStep==4 && !this.validateForm())
        this.formStep=5;
        else if(this.formStep==5 && !this.validateForm())
        this.formStep=6;
        else if(this.validateForm()){
          if(this.errors.customer_phone)
          this.alertSnackBar('Phone number is invalid','Invalid Phone');
          else if(this.errors.customer_email)
          this.alertSnackBar('Email address is invalid','Invalid Email');

          this.service.swalAlart.fire({
            title: 'Validation Alert',
            text: 'Kindly complete the highlighted fields',
            icon: 'warning',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
          })
        }
        else{
          this.service.swalAlart.fire({
            title:"Do you want to proceed with quote?",
            icon:"question",
            showCancelButton: true,
            confirmButtonText: `Yes,Proceed`,
            reverseButtons:true
          })
          .then((dialogResponse)=>{
            if(dialogResponse.hasOwnProperty('value') && dialogResponse.value==true){
                //start processing
                this.processing=true;

                let prepared_by=''

                this.payload={
                  customer_first_name: this.quote.customer_first_name,
                customer_last_name: this.quote.customer_last_name,
                customer_other_names: this.quote.customer_other_names,
                customer_email: this.quote.customer_email,
                customer_phone: this.quote.customer_phone,
                prepared_by: prepared_by,

                status:'incomplete',
                module:'client',
                status_of_client:'new',
                customer_date_of_birth:moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
                customer_branch_code:null,
                customer_type_code:this.quote.customer_type_code,
                policy_lob: "20",
                platform: "Webapp",
                inputted_discount:this.quote.inputted_discount,
                inputted_loading:this.quote.inputted_loading
                }

                  this.payload={
                    ...this.payload,
                    agent_code:'',
                    client_code:''
                  }


                this.service.swalAlart.showLoading();
                this.service.submitMotoQuote(this.payload)
                .toPromise()
                .then((response: QuoteResponse)=>{
                   if(response.responseCode=='000'){

                    this.service.swalAlart.close();

                      //finalizing the quote creation
                      this.payload={
                        prepared_by:prepared_by,
                        policy_sequence:response.responseData,
                        module:'client',
                        agent_code:'',
                        status_of_client:"new",
                        vehicle_registration:this.quote.vehicle_registration,
                        make:this.selectedMake.VEHICLE_MAKE_DESC,
                        model:this.selectedModel.VEHICLE_MODEL_DESC,
                        chasis_number:this.quote.chasis_number,
                        year_of_manufacture:this.quote.year_of_manufacture.toString(),
                        body_type_code:this.quote.body_type_code,
                        seats_number:this.quote.seats.toString(),
                        currency_code:this.quote.currency_code,
                        cubic_capacity:this.quote.cubic_capacity.toString(),
                        fleet:"",
                        exchange_rate:parseFloat(this.quote.exchange_rate).toFixed(2).toString(),
                        duration_type:"",
                        sum_insured:this.quote.sum_insured.toString(),
                        excess_type_code:(this.quote.excess_buy_back?'EIB':'ENA'),
                        tppd_limit:this.quote.tppd_value?this.quote.tppd_value:"0",
                        legacy_policy_number:"",
                        color:this.selectedColor.DISPLAY_SHORT_NAME,
                        color_code:parseInt(this.selectedColor.TABLED_CODE),
                        customer_first_name:this.quote.customer_first_name,
                        customer_last_name:this.quote.customer_last_name,
                        customer_other_names:"",
                        customer_email:this.quote.customer_email,
                        customer_phone:this.quote.customer_phone,
                        customer_date_of_birth:moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
                        client_code:this.quote.customer_code.toString(),
                        customer_branch_code:"",
                        customer_type_code:"01",
                        additional_pa_amt:this.quote.pa_value?this.quote.pa_value:"0",
                        product:this.quote.cover_type_code,
                        risk:this.quote.schedule_code,
                        fleet_number:"",
                        age_added:this.quote.vehicle_age_loading?'1':'0',
                        cc_added:this.quote.cc_loading?'1':'0',
                        intermediary_number:"AGT-MAXk-0000022",
                        intermediary_nic_registration_number:"NIC/MC/020",
                        intermediary_first_name:'',
                        intermediary_last_name:'',
                        intermediary_other_names:"",
                        intermediary_phone:'',
                        intermediary_email:'',
                        intermediary_type_code:"04",
                        intermediary_branch_code:"502",
                        ncd_discount_year:this.quote.ncd_value?this.quote.ncd_value:"0",
                        inception_date:moment(this.quote.start_date).format('DD/MM/YYYY'),
                        expiry_date:moment(this.quote.end_date).format('DD/MM/YYYY'),
                        make_code:parseInt(this.selectedMake.VEHICLE_MAKE_CODE),
                        model_code:parseInt(this.selectedModel.VEHICLE_MODEL_CODE)
                       }

                       if(this.quote.days_label.toLowerCase()=='months'||this.quote.days_label.toLowerCase()=='month(s)')
                          this.payload={...this.payload,months:this.quote.days}
                       else
                          this.payload={...this.payload,days:this.quote.days}

                       //if(this.service.currentUser().role.toLowerCase()=='agent')
                       //this.payload={...this.payload,policy_agency:this.service.currentUser().AGENT_AGENCY}

                       let selectedBody: any=this.bodyData.filter(r=>r.TABLED_CODE==this.quote.body_type_code);

                       if(selectedBody)
                          this.payload.body_type_description=selectedBody[0].TABLED_DESIGNATION

                       if(this.quote.term=='01')
                       this.payload.duration_type= 'annual';
                       else if(this.quote.term=='02')
                       this.payload.duration_type= 'shortrate';
                       else
                       this.payload.duration_type= 'prorata';

                       console.log("RequestPayload 2:  " + JSON.stringify(this.payload))
                     
                       this.service.swalAlart.showLoading();
                       this.service.submitMotoQuote(this.payload)
                       .toPromise()
                       .then((response: QuoteResponse)=>{
                           this.quote.total_premium=response.responseData.replace(",","");
                           this.confirmQuote();
                           this.service.swalAlart.close();
                           this.processing=false;
                           this.dialogRef.close({ event: 'close', data: {confirmedQuote:this.confirmedQuote} });
                       }).
                       catch(error=>{
                       // console.log('Error1',error)

                        this.processing=false;
                         this.service.swalAlart.close()
                         this.service.swalAlart.fire({
                           title: 'Error',
                           text: 'Server Error,retry later',
                           icon: 'error',
                         });
                       })
                   }
                }).catch(error=>{

                 // console.log('Error2',error)

                  this.processing=false;
                  this.service.swalAlart.close();
                  this.service.swalAlart.fire({
                    title: 'Error',
                    text: 'Server Error,retry later',
                    icon: 'error',
                  });
                })
                //end processing
            }
          });


        }
      }
//autocomplete search make
onLoadMake(){
  this.filteredMakes=[];
  this.loadedMakes=[];

  this.service.searchMake('','','')
   .toPromise()
   .then((response : MakeResponse) => {
    if(response.hasOwnProperty('responseCode') && response.responseCode=='000')
    {
      response.responseData.map((response :Make)=>{
        let make: Make={...response,DISPLAY_SHORT_NAME:`${response.VEHICLE_MAKE_DESC}`};
        this.loadedMakes.push(make);
        this.filteredMakes.push(make);
      })
    }
  });

}
//load models
onLoadModel(){
  this.filteredModels = [];
  this.loadedModels=[];
  this.service.searchModel('',this.selectedMake.VEHICLE_MAKE_CODE,'','')
  .toPromise()
  .then((response: any)=>{
    if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
      response.responseData.map((model :Model)=>{
        //console.log('loaded models',model)
        model.DISPLAY_SHORT_NAME=model.VEHICLE_MODEL_DESC;
        this.loadedModels.push(model);
        this.filteredModels.push({...model,DISPLAY_SHORT_NAME:`${model.VEHICLE_MODEL_DESC}`});

      });
    }
  })
}
      dobFilter(date: Date){
        return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'),'years')>=18;
      };
      //trigger the snackbar
      alertSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
          duration: 2000,
        });
     }

     //autocomplete search make
     onFilterMake(){
      this.searchMakeCtrl.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredMakes = [];
          this.isMakeLoading = true;
        }),
        switchMap(value =>{
          if(value.length){
            return Observable.create(observer=>{
              let payload=this.loadedMakes.filter((response : Make)=>response.DISPLAY_SHORT_NAME.trim().toLowerCase().includes(value.trim().toLowerCase())==true)
              observer.next(payload)
            })
            .pipe(
              finalize(() => {
                this.isMakeLoading = false
              }),
            )
          }
          else{
            this.isMakeLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any)=>{
        if(models)
        models.map((response : Make)=>{

          this.filteredMakes.push({...response,DISPLAY_SHORT_NAME:`${response.VEHICLE_MAKE_DESC}`});
        })
      })
    }
    //autocomplete search model
    onFilterModel(){
      this.searchModelCtrl.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredModels = [];
          this.isModelLoading = true;
        }),
        switchMap((value: string) =>{
          if(value){

            return   Observable.create(observer=>{
              let payload=this.loadedModels.filter((response : Model)=>response.DISPLAY_SHORT_NAME.trim().toLowerCase().includes(value.trim().toLowerCase())==true)
              observer.next(payload)
            })
            .pipe(
              finalize(() => {
                this.isModelLoading = false
              }),
            )
          }
          else{
            this.isModelLoading = false;
            this.filteredModels=this.filteredModels;
            return new Observable<[]>();
          }
         }
        )
      ).subscribe((models: any)=>{
        if(models)
        models.map((response : Model)=>{

          this.filteredModels.push({...response,DISPLAY_SHORT_NAME:`${response.VEHICLE_MODEL_DESC}`});
        })
      })
    }
    //autocomplete search color
    onFilterColor(){
      this.searchColorCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredColors = [];
          this.isColorLoading = true;
        }),
        switchMap(value =>{
          if(value.length)
          return this.service.searchColor(value)
          .pipe(
            finalize(() => {
              this.isColorLoading = false
            }),
          )
          else{
            this.isColorLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((response : colorResponse) => {
        if(response.hasOwnProperty('responseCode') && response.responseCode=='000')
        {
          response.responseData.map((response :Color)=>{
            this.filteredColors.push({...response,DISPLAY_SHORT_NAME:`${response.TABLED_DESIGNATION}`})
          })
        }
      });
    }
     //selected selected customer
    onSelectMake(make: Make){
     // console.log('make selected',make)
      this.errors={...this.errors,make:false};

      this.selectedMake=make;
      this.quote.make_code=this.selectedMake.VEHICLE_MAKE_CODE
      this.quote.make=this.selectedMake.VEHICLE_MAKE_DESC

      this.onLoadModel()
    }
    //selected selected customer
    onSelectModel(model: Model){
      //console.log('model selected',model)

      this.errors={...this.errors,model:false};

      this.selectedModel=model;
      this.quote.model_code=this.selectedModel.VEHICLE_MODEL_CODE
      this.quote.model=this.selectedModel.VEHICLE_MODEL_DESC
    }
    //selected selected color
    onSelectColor(color: Color){
      this.errors={...this.errors,color:false};
      this.selectedColor=color;
      this.quote.color_code=this.selectedColor.TABLED_CODE
      this.quote.color=this.selectedColor.TABLED_DESIGNATION
    }

    //make change event
    onMakeChange(event){
      if(!event.target.value){
        this.selectedMake={
            VEHICLE_MAKE_CODE: "",
            VEHICLE_MAKE_DESC: "",
            DISPLAY_SHORT_NAME:""
         }
         this.quote.make_code=this.selectedMake.VEHICLE_MAKE_CODE
         this.quote.make=this.selectedMake.VEHICLE_MAKE_DESC
      }
    }
    //make change event
    onModelChange(event){
      if(!event.target.value){
        this.selectedModel={
          VEHICLE_MODEL_CODE: "",
          VEHICLE_MODEL_MAKE_CODE: "",
          VEHICLE_MODEL_DESC: "",
          VEHICLE_MODEL_SECOND_DESC: "",
          DISPLAY_SHORT_NAME :""
         }
         this.quote.model_code=this.selectedModel.VEHICLE_MODEL_CODE
         this.quote.model=this.selectedModel.VEHICLE_MODEL_DESC
      }
    }
    //color change event
    onColorChange(event){
      if(!event.target.value){
        this.selectedColor={
          TABLED_NAME:"",
          TABLED_CODE:"",
          TABLED_DESIGNATION:"",
          DISPLAY_SHORT_NAME:""
         }
         this.quote.color_code=this.selectedColor.TABLED_CODE
         this.quote.color=this.selectedColor.TABLED_DESIGNATION
      }
    }


    //autocomplete search model

  }


