import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import {PolicyService} from '../../../modules/service/policyservice.service';
import {DashboardService} from '../../../modules/service/dashboard.service';
import {Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import {Chart} from 'chart.js';
import { MatSelectChange } from '@angular/material';

interface DurationPeriod {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-widget-policygraph',
  templateUrl: './policygraph.component.html',
  styleUrls: ['./policygraph.component.scss']
})
export class PolicygraphComponent implements OnInit {
  selected = '30';

  days: DurationPeriod[] = [
    {value: '7', viewValue: 'Last 7 Days'},
    {value: '30', viewValue: 'Last 30 Days'},
    {value: 'months', viewValue: 'Last Year'}
  ];

  charts:any;
  ud :any //get users email


  total:any;
  transact:any;
  pending:any;

  loaderror:any=''

  sday:boolean=true;
  thday:boolean=false;
  yrday:boolean=false;


  loading:boolean = false;
  topperformstartdate:any;
  topperformenddate:any;
  selectmonth: any;

  periodchart:any=[];



  constructor(public service: DashboardService, public router:Router,  public policyservice: PolicyService, private formbuilder:FormBuilder) {
    this.ud=this.service.currentUser();
  }

  ngOnInit() {

    if (this.ud.role == "agent" || this.ud.role == "broker-user" || this.ud.role == "broker-admin" || this.ud.role == "staff"){

        //Chart.defaults.scale.gridLines.drawOnChartArea = true;

    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - 30) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start date

    this.perfomanceDetails()

    }else{

    }



  }


  perfomanceDetails(){

    let minDate = new Date(this.topperformstartdate)
    let maxDate = new Date(this.topperformenddate)


    var days ;
     days = (maxDate.getTime()-minDate.getTime())/(1000 * 3600 * 24)
    //let month = (maxDate.getMonth()-minDate.getMonth())/(1000*3600*24)

    if (days > 30 ){

      days = "months";
    }

    if(this.charts!=null){

      console.log("we go destroyyyy")
      this.charts.destroy();
  }

    // if (days === 7 || days === 30) {
      if (this.topperformstartdate === undefined || this.topperformenddate === undefined) {
        swal.fire({ icon: 'error',title: 'Oops...',text: 'Start Date or End Date was not selected.',footer: 'Please, make the necessary changes and try again.'});
      } else if(this.topperformstartdate > this.topperformenddate) {
        swal.fire({ icon: 'error',title: 'Oops...',text: 'Start Date is more than End Date.',footer: 'Please, make the necessary changes and try again.'});
      } else {
        this.loading = true;
        this.service.policiesGraphapi({email:this.ud.email,minDate:this.topperformstartdate,maxDate:this.topperformenddate,duration:days.toString(), role:this.ud.role})
          .subscribe(data => {
            this.loading = false;
            this.periodchart = data['responseData'];

            // if(days===7){
            //   this.sday = true;
            //   this.thday = false;

            // }else if(days===30){
            //   this.thday = true;
            //   this.sday = false;
            // }

            let labels = [],amounts=[];
            data['responseData'].forEach(element => {
              labels.push(element.date);
              //amounts.push(parseFloat(element.total.replace(/,/g,'')));
              amounts.push(element.total);



            });

            this.charts = this.policyservice.getPolicyChart(labels,amounts,'policytrans');

            // this.topperformenddate = ''
            // this.topperformstartdate = ''

          },error => {
            this.loading = false;
            swal.fire({ icon: 'error',title: 'Oops...',text: error.message,footer: 'Please, make the necessary changes and try again.'});
          })
    //  }
    }
    // else {
    //   return  swal.fire({ icon: 'error',title: 'Oops...',text: 'Interval Should be 7 or 30 days',footer: 'Please, make the necessary changes and try again.'});
    // }

  }

  day_7(){
    this.sday = true;
     this.thday = false;
     this.yrday = false;
    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - 7) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start date

    this.perfomanceDetails()
  }

  day_30(){
    this.sday = false;
    this.thday = true;
    this.yrday = false;
    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - 30) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start date

    this.perfomanceDetails()
  }

  day_365(){
    this.sday = false;
    this.thday = false;
    this.yrday = true;
    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - 365) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start date

    this.perfomanceDetails()
  }


  changeRatio(event: MatSelectChange) {
    console.log(event.value);

if (event.value == "7"){
  console.log("doing 7");
  this.day_7()

}
else if (event.value == "30"){

  console.log("doing 30");
  this.day_30()

}
else {

  console.log("doing 365");
  this.day_365()
}


  }


}
