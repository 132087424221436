import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { CashPaymentComponent } from '../modules/dialogs/cash-payment/cash-payment.component';
import { ChequePaymentComponent } from '../modules/dialogs/cheque-payment/cheque-payment.component';
import { DebitCardPaymentComponent } from '../modules/dialogs/debit-card-payment/debit-card-payment.component';
import { MomoPaymentComponent } from '../modules/dialogs/momo-payment/momo-payment.component';
import { QuoteSummaryComponent } from '../modules/dialogs/quote-summary/quote-summary.component';
import { SelectPaymentMethodComponent } from '../modules/dialogs/select-payment-method/select-payment-method.component';
import { SignupComponent } from '../modules/dialogs/signup/signup.component';
import { DashboardService } from '../modules/service/dashboard.service';

interface CustomerProfile{
  CLIENT_CODE: string;
  CLIENT_TYPE?: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?:boolean;
}
interface CustomerResponse{
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}


interface Cover {
  PRODUCT_DESC: string;
  PRODUCT_CURRENCY: string;
  PRODUCT_CODE:string;
}


interface CurrencyResponse{
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Currency[];
}

interface Country{
  id: number;
  country_name: string;
  country_code: string;
  call_code: string;
  currency: string;
}
interface coverResponse{
  responseCode: string;
  responseMessage: string;
  code:string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: Cover[]
}

interface countryResponse{
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: Country[]
}
interface occupationResponse{
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: Occupation[]
}

interface Occupation {
  PROFESSION_CODE: string;
  PROFESSION_DESC: string;
}

interface QuoteResponse{
  responseCode: string;
  responseMessage: string;
  responseData: string;
  responseNumber: string;
}


interface year {
  value: string;
  viewValue: string;
}


interface Currency{
  CURRENCY: string;
  DATE_UPDATED: string;
  CURRENCY_VALUE: number;
}
interface accessList{
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-quick-travel-quote',
  templateUrl: './quick-travel-quote.component.html',
  styleUrls: ['./quick-travel-quote.component.scss']
})

export class QuickTravelQuoteComponent implements OnInit {

  formStep:number=1;
  //is processing
  processing=false;

  //profile data
  appuser: any;

  //is renewal
  renewal:false;

  quote:any={
      customer_first_name:"",
      customer_last_name:"",
      customer_email:"",
      customer_phone:"",
      date_of_birth:"",
      occupation:"",
      status_of_client: "new"
   }
   errors:any={}
   searchCountryCtrl=new FormControl;
   searchOccupationCtrl=new FormControl;


   //Filtered data containers
   filteredCustomers: CustomerProfile[];
   filteredOccupations: Occupation[];
   filteredCountries: Country[];

   //loadings
   isCustomerLoading:boolean=false;
   isOccupationLoading:boolean=false;
   isCountryLoading:boolean=false;

   payload: any={}
   coverData: Cover[] = []
   yearData: year[]=[]
   currencyData: Currency[]=[]
   countryData: Country[]=[]
   occupationData: Occupation[]=[]

   //initially selected country
   selectedCountry: Country={
    id: 0,
    country_name: "",
    country_code: "",
    call_code: "",
    currency: ""
  }

  //selected payment method
  selectedMethod:string;

  // is payment confirmed from the summary dialoged?
  confirmedQuote:boolean=false;

  constructor(
    private service:DashboardService,
    private router:Router,
    private currencyPipe : CurrencyPipe,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public quickTravelDialogRef: MatDialogRef<QuickTravelQuoteComponent>
  ) { }

  ngOnInit() {
    this.searchCountryCtrl=new FormControl({value:null,disabled: false});
    this.searchOccupationCtrl=new FormControl({value:null,disabled: false});

    //Load covers
   this.onLoadCovers();

   //Load Occupations
   this.loadOccupations();

   //Load countries
   this.onLoadCountries();

   this.selectedMethod='Mobile';

    this.onFilterOccupation();
    this.onFilterCountry();
  }

  //load covers
  onLoadCovers(){
    this.countryData=[];
    //this.service.swalAlart.showLoading();
    this.service.getCovers()
    .toPromise()
    .then((response: coverResponse)=>{
        if(response.responseCode=='000'){
          response.profile.map((cover: Cover)=>{
            //if(cover.PRODUCT_DESC!='VANGUARD SAFE TRAVEL (VST)')
            this.coverData.push({PRODUCT_CODE: cover.PRODUCT_CODE,PRODUCT_CURRENCY: cover.PRODUCT_CURRENCY,PRODUCT_DESC:cover.PRODUCT_DESC});
          })
          this.service.swalAlart.close();
        }
    })
    .catch((error: any)=>{
      this.service.swalAlart.close();
      console.log('Error',error);
    })
  }

  //Load countries
  onLoadCountries(){
    this.countryData=[];
    this.service.getCountries()
    .toPromise()
    .then((response: countryResponse)=>{
        if(response.responseCode=='000'){
          if(response['profile']){
            response.profile.map((country: Country)=>{
              this.countryData.push({id: country.id,call_code: country.call_code,country_code:country.country_code,country_name: country.country_name,currency: country.currency});
            })
          }
          this.service.swalAlart.close();
        }
    })
    .catch((error: any)=>{
      this.service.swalAlart.close();
      console.log('Error',error);
    })
  }


    //load occupations
    loadOccupations(){
      this.service.getOccupation()
      .toPromise()
      .then((response :occupationResponse)=>{
        if(response.responseCode=='000'){
           response.profile.map((occupation :Occupation)=>{
               this.occupationData.push(occupation);
           })
        }
      }).catch(err =>{
             console.log('occupations load error',err);
        });
    }

    onFilterOccupation(){
      this.searchOccupationCtrl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          this.filteredOccupations = [];
          this.isOccupationLoading = true;
        }),
        switchMap((value: string) =>{
          if(value && value.length>0){

            return   Observable.create(observer=>{
              let occupations=this.occupationData.filter((response : Occupation)=>response.PROFESSION_DESC.trim().toLowerCase().includes(value.trim().toLowerCase())==true)
              observer.next(occupations)
            })
            .pipe(
              finalize(() => {
                this.isOccupationLoading = false
              }),
            )
          }
          else{
            this.isOccupationLoading = false
            return new Observable<[]>();
          }
         }
        )
      ).subscribe((occupations: any)=>{
        occupations.map((response : Occupation)=>{
          this.filteredOccupations.push(response);
        })
      })
    }

    onFilterCountry(){
      this.searchCountryCtrl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          this.filteredCountries = [];
          this.isCountryLoading = true;
        }),
        switchMap((value: string) =>{
          if(value && value.length>0){

            return   Observable.create(observer=>{
              let countries=this.countryData.filter((response : Country)=>response.country_name.trim().toLowerCase().includes(value.trim().toLowerCase())==true)
              observer.next(countries)
            })
            .pipe(
              finalize(() => {
                this.isCountryLoading = false
              }),
            )
          }
          else{
            this.isCountryLoading = false
            return new Observable<[]>();
          }
         }
        )
      ).subscribe((countries: any)=>{
        countries.map((response : Country)=>{
          this.filteredCountries.push(response);
        })
      })
    }


  //run validation
  validateForm(input? :any) :boolean{
    let hasError=false;
    this.errors={
      start_date:false,
      end_date:false,
      // destination_country:false,
      cover_type_code:false,
      // occupation:false,
      // passport_no:false,
      // date_of_birth:false,
      // customer_first_name:false,
      // customer_last_name:false,
      // customer_phone:false,
      // client_code:false,
      // customer_email:false
    };

    //==Step1==
    if(this.formStep==1){
      // if(!this.quote.customer_first_name){
      //   hasError=true;
      //   this.errors={...this.errors,customer_first_name:true};
      // }
      // if(!this.quote.customer_last_name){
      //   hasError=true;
      //   this.errors={...this.errors,customer_last_name:true};
      // }
      // if(!this.quote.customer_phone){
      //   hasError=true;
      //   this.errors={...this.errors,customer_phone:true};
      // }
      // if(this.quote.customer_email && !this.service.validateEmail(this.quote.customer_email)){
      //   hasError=true;
      //   this.errors={...this.errors,customer_email:true};
      // }
      // if(!this.quote.date_of_birth){
      //   hasError=true;
      //   this.errors={...this.errors,date_of_birth:true};
      // }
      // if(!this.quote.passport_no){
      //   hasError=true;
      //   this.errors={...this.errors,passport_no:true};
      // }
      // if(!this.quote.occupation){
      //   hasError=true;
      //   this.errors={...this.errors,occupation:true};
      // }

      if(!this.quote.cover_type_code){
        hasError=true;
        this.errors={...this.errors,cover_type_code:true};
      }
      if(!moment(this.quote.start_date).isValid()){
        hasError=true;
        this.errors={...this.errors,start_date:true};
      }
      if(!moment(this.quote.end_date).isValid()){
        hasError=true;
        this.errors={...this.errors,end_date:true};
      }
    }
    //==end of Step1==

    //==Step2==
    // if(this.formStep==2){
    //   if(!this.quote.cover_type_code){
    //     hasError=true;
    //     this.errors={...this.errors,cover_type_code:true};
    //   }
    //   if(!this.quote.destination_country){
    //     hasError=true;
    //     this.errors={...this.errors,destination_country:true};
    //   }
    //   if(!moment(this.quote.start_date).isValid()){
    //     hasError=true;
    //     this.errors={...this.errors,start_date:true};
    //   }
    //   if(!moment(this.quote.end_date).isValid()){
    //     hasError=true;
    //     this.errors={...this.errors,end_date:true};
    //   }
    // }

    //==end of Step2==
    console.log('Errors',this.errors,hasError)
    return hasError;
  }
  onScheduleSelected(code: string){
    console.log("scheduleType=======" + code);
    this.quote.schedule_code = code;

    this.validateForm();
  }
  onCoverSelected(code: string)  {

  }
  //on term selection
  onSelectCountry(country: Country){
    this.quote.destination_country = country.country_name;

    this.validateForm();
  }
  onSelectOccupation (occupation: Occupation){
    this.quote.occupation=occupation;

    if(occupation)
    this.quote.occupation=occupation.PROFESSION_DESC.trim();

    this.validateForm();
  }
  onCurrencySelected(code: string){
    let selectedCurrency= this.currencyData.filter((currency: Currency)=>currency.CURRENCY==code);

    if(selectedCurrency.length){
      this.quote.exchange_rate=selectedCurrency[0].CURRENCY_VALUE.toString();
      this.quote.currency_code=code;
    }

    console.log('Currency',this.quote.currency_code);
    this.validateForm();
  }
  onNCDSelected(code: string){
    this.quote.ncd_value=code;
  }
  onStartDateChanged(){
    let start_date= this.quote.start_date?moment(this.quote.start_date).format('YYYY-MM-DD'):null;

    let days=parseInt(this.quote.days);


    let end_date= moment(start_date).add(!isNaN(+`${days}`)?days:1 ,'days').format('YYYY-MM-DD');

    this.quote.end_date=end_date;

    if(this.quote.start_date && this.quote.end_date)
       this.quote.days=this.quote._days=(moment(end_date).diff(start_date,'days')+1).toString();


    this.validateForm();
 }
 onEndDateChanged(date: Date){
   let start_date= this.quote.start_date?moment(this.quote.start_date).format('YYYY-MM-DD'):null;

   let days=0;
   if(start_date){
       days=moment(moment(date).format('YYYY-MM-DD')).diff(start_date,'days');
       this.quote.days=(days+1).toString();
   }
  this.validateForm();
 }
//Start Date dateFilters
startDateFilter =(date: Date)=>{
  return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'),'days')>=0;
}
  //End Date dateFilters
endDateFilter =(date: Date)=>{
    let diff=moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.quote.start_date).format('YYYY-MM-DD'),'days');
    return  diff>=0 && diff<=364;
}

dobFilter(date: Date){
  return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'),'years')>=18;
};
//trigger the snackbar
alertSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {
    duration: 2000,
  });
}
/* handle access changes===*/
onExcessBuyBackChange(checked: boolean){
    this.quote.excess_buy_back=checked;
  }
  onTppdChange(checked: boolean){
    this.quote.tppd=checked;
  }
  onPaChange(checked: boolean){
    this.quote.pa=checked;
  }
  onNcdChange(checked: boolean){
    this.quote.ncd=checked;
  }
  onFdChange(checked: boolean){
    this.quote.fd=checked;
  }
  onVehicleAgeLoadingChange(checked: boolean){
    this.quote.vehicle_age_loading=checked;
  }
  onCCLoadingChange(checked: boolean){
    this.quote.cc_loading=checked;
  }
  dobStartAt=new Date(moment(moment().format('YYYY-MM-DD')).subtract(18,'years').format('YYYY-MM-DD'))

  onOccupationChange(event){
    let occupation=this.occupationData.find((occupation: Occupation)=>occupation.PROFESSION_DESC==event.target.value);
     if(!occupation)
      this.quote.occupation='';

  }
  onCountryChange(event){
    let country=this.countryData.find((country: Country)=>country.country_name==event.target.value);
     if(!country)
      this.quote.destination_country='';
  }

  // on proceed
  onProceed(){
    if(this.validateForm())
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
      else if(this.formStep==1)
      this.onSubmit();
      else
      this.formStep++;
  }

  onPrevious(){
    if(this.formStep>1)
    this.formStep--;
  }
  //On Form submit
  onSubmit=()=>{
    //event.preventDefault();
      this.service.swalAlart.fire({
        title:"Do you want to proceed with quote?",
        icon:"question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons:true
      })
      .then((dialogResponse)=>{
        if(dialogResponse.hasOwnProperty('value') && dialogResponse.value==true) {

          //start of processing
          this.processing=true;
          let prepared_by=`${this.quote.customer_first_name} ${this.quote.customer_last_name}`.trim();

          let profession=this.occupationData.find((job: Occupation)=>job.PROFESSION_DESC.trim()==this.quote.occupation.trim());

          this.payload={
            // customer_first_name: this.quote.customer_first_name,
            // customer_last_name: this.quote.customer_last_name,
            // customer_other_names: this.quote.customer_other_names,
            // customer_email: this.quote.customer_email,
            // customer_phone: this.quote.customer_phone,
            // prepared_by: prepared_by,

            status:'incomplete',
            module:'client',
            status_of_client:(this.renewal?'renew':'new'),
            //customer_date_of_birth:moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
            // customer_branch_code:null,
            // customer_type_code:this.quote.customer_type_code,
            platform:'webapp',
            // passport_number:this.quote.passport_no,
            // profession_desc:profession? profession.PROFESSION_DESC: null,
            // profession_code: profession?profession.PROFESSION_CODE:null,
            policy_lob: "50"
          }

            this.payload={
              ...this.payload,
              agent_code:'',
              //client_code:this.appuser.code.toString()
            }

          this.service.swalAlart.showLoading();

          if(!this.renewal && this.quote.edit_mode){
          this.quote = {...this.quote, policy_sequence: this.quote.policy_no};
          this.finalizeQuote(this.quote.policy_no)
        }else
          this.service.submitTravelQuote(this.payload)
          .toPromise()
          .then((response: QuoteResponse)=>{
            if(response.responseCode=='000'){
              this.service.swalAlart.close();

              this.quote = {...this.quote, policy_sequence: response.responseData}
              
              this.finalizeQuote(response.responseData)

            }
          })
          .catch(error=>{
            console.log('Error',error)
            this.processing=false;
            this.service.swalAlart.close();
            this.service.swalAlart.fire({
              title: 'Error',
              text: 'Server Error,retry later',
              icon: 'error',
            });
          })
        }
      })


  }
      finalizeQuote=(policy_no)=>{
        let product=this.coverData.find((cover: Cover)=>cover.PRODUCT_CODE==this.quote.cover_type_code)
        let prepared_by=`${this.quote.customer_first_name} ${this.quote.customer_last_name}`.trim();
        let profession=this.occupationData.find((job: Occupation)=>job.PROFESSION_DESC.trim()==this.quote.occupation.trim());

        this.payload={
          // customer_first_name: this.quote.customer_first_name,
          // customer_last_name: this.quote.customer_last_name,
          // customer_other_names: this.quote.customer_other_names,
          // customer_email: this.quote.customer_email,
          // customer_phone: this.quote.customer_phone,
          policy_sequence: policy_no.toString(),
          currency_code: product.PRODUCT_CURRENCY,
          // prepared_by: prepared_by,
          // customer_date_of_birth:moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
          passport_number:this.quote.passport_no,

          fleet: null,
          inception_date:moment(this.quote.start_date).format('DD/MM/YYYY'),
          expiry_date:moment(this.quote.end_date).format('DD/MM/YYYY'),
          product: product.PRODUCT_CODE.toString(),
          duration_type: "Travel Term",
          //policy_office: null,
          //policy_agency: this.appuser.agency || '502',
          destination_country: this.quote.destination_country.toString(),
          policy_lob: "50",
          module:'client',
          platform:'webapp',
          status_of_client:(this.renewal?'renew':'new'),
          // profession_desc:profession ? profession.PROFESSION_DESC : null,
          // profession_code: profession ? profession.PROFESSION_CODE : null,

          // intermediary_first_name:'',
          // intermediary_last_name:'',
          // intermediary_phone:'',
          // intermediary_email:''

         }


         this.service.swalAlart.showLoading();
         this.service.submitMotoQuote(this.payload)
         .toPromise()
         .then((response: any)=>{
             //console.log('quote final response',response);
             this.quote.total_premium=response.responseConvertedValue.toString().replace(",","").trim();
             this.quote.foreign_currency=response.responseCurrencyValue;
             this.quote.foreign_amount=response.responseData.toString().replace(",","").trim();
             this.confirmQuote();
             this.service.swalAlart.close();
             this.processing=false;
         }).
         catch(error=>{
          this.processing=false;
          this.service.swalAlart.close()
           this.service.swalAlart.fire({
             title: 'Error',
             text: 'Server Error,retry later',
             icon: 'error',
           });
         })
      }

      //confirm quote
    confirmQuote(){
      let product=this.coverData.find((cover: Cover)=>cover.PRODUCT_CODE==this.quote.cover_type_code)
      let prepared_by=`${this.quote.customer_first_name} ${this.quote.customer_last_name}`.trim();

      let summary=[
        // {label:'Date Prepared',value:moment().format('DD/MM/YYYY')},
        // {label:'Prepared By',value:prepared_by},
        // {label:'Customer Name',value:`${this.quote.customer_first_name} ${this.quote.customer_last_name}`},
        // {label:'Customer Email',value:this.quote.customer_email},
        // {label:'Customer Code',value:this.payload.client_code},
        // {label:'Mobile Number',value:this.quote.customer_phone},
        // {label:'ID Type',value:'Passport'},
        // {label:'ID Number',value:this.quote.passport_no},
        {label:'Cover Plan', value:product.PRODUCT_DESC},
        //{label:'Coverage',value:''},
        {label:'Destination',value: this.payload.destination_country },
        {label:'Date of Departure',value:this.payload.inception_date},
        {label:'Date of Return',value:this.payload.expiry_date}
      ]

      //console.log('summary',summary)
      const dialogRef = this.dialog.open(QuoteSummaryComponent, {
        disableClose :true,
        width: '600px',
        data:  {
          flow:'travel',
          hide_foreign_currency:(product.PRODUCT_DESC=='VANGUARD SAFE TRAVEL (VST)'? 1:0),
          quote:summary,

          total_premium: this.quote.total_premium,
          foreign_currency:this.quote.foreign_currency,
          foreign_amount:this.quote.foreign_amount,
          confirmedQuote:true}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result.hasOwnProperty('data')){
          this.confirmedQuote = result.data.confirmedQuote;

          //ask client to register in order to proceed
          this.service.swalAlart.fire({
            icon:"question",
            html:"To make payment for this policy,you need to create an account.<br/>Do you wish to proceed?",
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:"Yes",
            cancelButtonText:"NO",
            reverseButtons:true
          })
          .then((result: SweetAlertResult)=>{
              //require registration on payment
              if(result.value)
                 this.openSignUpDialog();
          })

        }
      });
    }

    openSignUpDialog(){
      const dialogRef = this.dialog.open(SignupComponent, {
        disableClose :true,
        width: '860px',
        data: {
          customer_first_name:this.quote.customer_first_name,
          customer_last_name:this.quote.customer_last_name,
          customer_email:this.quote.customer_email,
          customer_phone:this.quote.customer_phone,
          idNumber:this.quote.passport_no,
          idType: 'Passport',
          via_quick_quote:true
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('Response Data',result)

        if(result.hasOwnProperty('data') && result.data['client_code']){
          console.log('Response Data',result.data)

          this.quote.client_code=this.payload.client_code=result.data['client_code']
          this.quote.customer_email=this.payload.customer_email=result.data['customer_email']
          this.quote.customer_phone=this.payload.customer_phone=result.data['customer_phone']
          this.quote.customer_first_name=this.payload.customer_first_name=result.data['customer_first_name']
          this.quote.customer_last_name=this.payload.customer_last_name=result.data['customer_last_name']
          this.quote.date_of_birth=this.payload.date_of_birth=result.data['dob']
          this.quote.passport_number=this.payload.passport_number=result.data['passport_number']

            //update the quote with the generated client_code
            console.log('Payload===>', this.payload)
            

            this.service.swalAlart.showLoading();
            this.service.submitMotoQuote(this.payload)
            .toPromise()
            .then((response: any)=>{
                //create a client profile

                let client={
                  CLIENT_FIRST_NAME:this.quote.customer_first_name,
                  CLIENT_CODE:result.data['client_code'],
                  CLIENT_FAMILY_NAME:this.quote.customer_last_name,
                  CLIENT_HOME_EMAIL:this.quote.customer_email,
                  CLIENT_HOME_MOBILE:this.quote.customer_phone,
                  CLIENT_DATE_OF_BIRTH:result.data['dob'],
                  role:'client',
                  agency:null
                }

                this.service.currentUser(client,true)
                this.choosePaymentMethod();
                this.service.swalAlart.close();
                this.processing=false;
            }).
            catch(error=>{
              console.log('submit error',error)
              this.processing=false;
              this.service.swalAlart.close()
              this.service.swalAlart.fire({
                title: 'Error',
                text: 'Server Error,retry later',
                icon: 'error',
              });
            })
        }
      });


    }

    //choose payment method
    choosePaymentMethod(){
      console.log("this.quote ===> " + JSON.stringify(this.quote))

      const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
        disableClose :true,
        width: '350px',
        data: { 
          quotePayload: {
            ...this.payload,currency_code:'GHS',
            client_code:this.quote.client_code.toString()},
            method:this.selectedMethod,
            total_premium:this.quote.total_premium,
            policy_lob: "50",
            policy_sequence: this.quote.policy_sequence,
            status_of_client: "new"

          }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result.hasOwnProperty('data')){
          this.confirmedQuote = result.data.confirmedQuote;
          this.selectedMethod=result.data.method;

          console.log("paymentMethod ===>>> " + result.data.method)

          if(this.selectedMethod)
            this.confirmPin();

          this.validateForm();
        }
      });
    }

    //confirm pin
    //confirm login pin to continue
confirmPin(){
  this.service.swalAlart.fire({
    //title: "Identity Confirmation",
    icon:"warning",
    allowOutsideClick:false,
    showCancelButton: true,
    inputPlaceholder: "Confirm your Pin",
    input:"password",
    reverseButtons: true
  })
  .then((result:SweetAlertResult)=>{

       if(result.value){
        let currentUser= this.service.currentUser();
        console.log('Current user',currentUser)
        //prepare payload
        let loginPayload={};
        if (currentUser.role == "client")
            loginPayload={phone:currentUser.phone,pin:result.value,module:'client'};
        else
            loginPayload={agent_code:currentUser.code,pin:result.value,module:'agent'};

          Swal.showLoading()
          this.service.webLogin(loginPayload)
          .toPromise()
          .then((responseData: any)=>{
            Swal.hideLoading()

            if(responseData.hasOwnProperty('responseCode') && responseData.responseCode==='000'){
              this.quote = {...this.quote, currency_code: 'GHS'}

              
              if (responseData.hasOwnProperty('allow_cash') && responseData.allow_cash == true){
                localStorage.setItem('allow_cash', 'true')
              }else{
                localStorage.setItem('allow_cash', 'false')
              }

              
              if(this.selectedMethod=='Mobile')
              this.openMomoPaymentDialog();
              else if(this.selectedMethod=='Cash')
              this.openCashPaymentDialog();
              else if(this.selectedMethod=='Cheque')
              this.openChequePaymentDialog();

              
            }
            else{
              Swal.fire({
                title: 'Wrong credentials!',
                text: 'Please check and try again',
                icon: 'error',
              });
              setTimeout(()=>{this.confirmPin()},2000);
            }

          }).catch(error=>{
            Swal.fire({
              title: 'Connection Failed!',
              text: 'Login Failed',
              icon: 'error',
            });
            setTimeout(()=>{this.confirmPin()},2000);
          })
       }
       else
       this.alertSnackBar('No Pin was entered','User Not Verified');
     });
   }


  openMomoPaymentDialog() {
    console.log("this.quote ===>>> " + JSON.stringify(this.quote))

    const dialogRef = this.dialog.open(MomoPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.quote, total_premium: this.quote.total_premium, policy_lob: "50" }
    });
  }


  openCardPaymentDialog() {
    const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.quote, total_premium: this.quote.total_premium, policy_lob: "50" }
    });
  }


  openCashPaymentDialog() {
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.quote, total_premium: this.quote.total_premium, policy_lob: "50" }
    });

  }

  openChequePaymentDialog() {
    const dialogRef = this.dialog.open(ChequePaymentComponent, {
      disableClose: true,
      width: '600px',
      data: { quotePayload: this.quote, total_premium: this.quote.total_premium, policy_lob: "50" }
    });

  }

}
