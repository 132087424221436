import { Component, OnInit,  ElementRef, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe} from '@angular/common';

import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import swal from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';


@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  digiPay = {
    tx_ref:"",
    amount:"10",
    currency:"GHS",
    redirect_url:"http://localhost:4200/",
    payment_options:"mobilemoney",
    meta:{
       "consumer_id":23,
       "consumer_mac":"92a3-912ba-1192a"
    },
    customer:{
       email:"",
       phonenumber:"",
       name:""
    },
    customizations:{
       title:"smartCover Premium",
       description:"",
       logo:"https://assets.piedpiper.com/logo.png"
    }
 }


 quote = JSON.parse(localStorage.getItem('appquote'));
 user = JSON.parse(localStorage.getItem('appuser'));

  constructor(private _formBuilder: FormBuilder, private service:DashboardService, private router:Router, private currencyPipe : CurrencyPipe) {

  }



  ngOnInit() {

  }

  onBackClicked(){
    this.router.navigate(["/quotesummary"])
  }

  onPayCash(){
    this.router.navigate(["/paycash"])
  }

  onPayCheque(){
    this.router.navigate(["/paycheque"])
  }

  onPayMomo(){
    this.digiPay.tx_ref = this.quote.invoice
    this.digiPay.meta.consumer_id = this.user.id
    this.digiPay.customer.email = this.quote.customer_email
    this.digiPay.customer.phonenumber = this.quote.customer_phone
    this.digiPay.customer.name = this.quote.customer_first_name + " " + this.quote.customer_last_name


  let unformattedAmount = this.quote.total_premium
      unformattedAmount = unformattedAmount.replace(/GHS /g, '')
      unformattedAmount = unformattedAmount.replace(/,/g, '')

      let formattedAmount = unformattedAmount
      this.digiPay.amount = formattedAmount



    swal.showLoading()
    var payload = JSON.stringify(this.digiPay)
    console.log(payload)

    this.service.DigiPay(payload).subscribe(data => {
      console.log(data)
      swal.hideLoading()

      if (data['status'] === 'success') {

        let payLink = data['data']['link']

        window.location.href = payLink
        //window.open("payLink", "_blank");


      } else {
        swal.showLoading()
        swal.fire({
          title: 'Error',
          text: 'Server Error,retry later',
          icon: 'error',
        });
      }


    });

  }


}
