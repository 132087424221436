import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { DashboardService } from '../modules/service/dashboard.service';
import swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-manager-set-password',
  templateUrl: './manager-set-password.component.html',
  styleUrls: ['./manager-set-password.component.scss']
})
export class ManagerSetPasswordComponent implements OnInit {

 
  private httpHeaders = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Access-Control-Allow-Origin', '*')
  .set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT")
  .set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization");

private options = {
  headers: this.httpHeaders
};

//LocalDomain = 'https://vanguard-api.herokuapp.com/vanguard/api';
LocalDomain = 'https://smartcover-api-dev.azurewebsites.net/smartCover/api';
//LocalDomain= ' https://3816a00a.ngrok.io/smartCover/api';
baseURL = this.LocalDomain;

pwdsetform: FormGroup;
  email: any
  response: any;

  reset = {
    email: '',
    token: '',
    new_password: '',
    confirm_password: ''
   // confirmPassword:''
}

 postreset = {
  email: '',
  token: '',
  password:''
}



postToken = {
    email:'',
    token:''
}



login = {

  password: ''

}



hide = true;


  constructor(public http: HttpClient, private route: ActivatedRoute, private router: Router,public formbuilder: FormBuilder, public service:DashboardService) {

    this.pwdsetform = this.formbuilder.group({
      new_password: new FormControl('',[Validators.required]),
      confirm_password: new FormControl('',[Validators.required])
    })
  }
   //URL PARAMETERS
 getEmail:string;
 getToken:string;
 getPassword:string;

  ngOnInit() {

//console.log("begining ......................");


    try{

      this.route.paramMap.subscribe(
        params => {
            this.getEmail = params.get('email');
            this.getToken = params.get('token');
        }
  
      );

    }catch(e){
     // console.log(e)
    }


    //console.log("start")
    //CHECK VALIDITY OF TOKEN FOR CLIENT
    this.validateToken();

   
}

isFieldValid(field: string) {
  return !this.pwdsetform.get(field).valid && this.pwdsetform.get(field).touched;
}

displayFieldCss(field: string) {
  return {
    'has-error': this.isFieldValid(field),
    'has-feedback': this.isFieldValid(field)
  };

  }


onSubmit() {
  swal.showLoading()
  

  if (this.pwdsetform.valid) {

    this.reset.email=this.getEmail
    this.reset.token = this.getToken
    //this.login.password = this.getPassword 
    this.reset.confirm_password=this.pwdsetform.controls.confirm_password.value
    this.reset.new_password= this.pwdsetform.controls.new_password.value
    //console.log(this.reset.new_password)
    //console.log(this.reset.confirm_password)
    // this.reset.password_login=this.form.value.password
    // this.reset.confirmPassword=this.form.value.confirmPassword

    this.postreset.email = this.getEmail
    this.postreset.token = this.getToken
    this.postreset.password = this.reset.confirm_password

if (this.reset.new_password!= this.reset.confirm_password)
{
  swal.hideLoading()

swal.fire({
  title: "Error",
  text: "Password mismatch!",
  width: '250px',
  heightAuto: true,
  animation:true,

  showCancelButton: false,
  showConfirmButton:true
});
}
else
{
//   swal("Success!", 'Passwords match', "success");
// }
delete this.reset.confirm_password


 // console.log( group.controls.password.value)

  //this.spinner.show();

  // console.log(this.postreset);

      var data=JSON.stringify(this.postreset)
      this.http.post(this.baseURL+'/managers/set_password', this.postreset, this.options).subscribe(res => {

      this.response = res;
      //console.log(this.response);

    if (this.response.responseCode == '000') {

      //swal("Success", 'Proceed to Login with your new password');
      swal.fire({
        title: "Set Password",
        text:  "Password Set successfully.",
        width: '250px',
        heightAuto: true,
        animation:true,
        showCancelButton: false,
        showConfirmButton:true
    });
    swal.hideLoading()

      this.router.navigate(['manager_password_success']);

    }
    else if (this.response.responseCode == 'R01') {
     
     
   
    this.router.navigate(['manager_expired_link']);
    // this.router.navigate(['/reset']);

    }
    else {
      swal.hideLoading()

    //  this.spinner.hide();
      //swal("Error!", 'Check your Internet Connection', "error");
      swal.fire({
        title: "Error!",
        text:  "Check your Internet connection",
        width: '250px',
        heightAuto: true,
        animation:true,
        showCancelButton: false,
        showConfirmButton:true
    });
      
     
    }

  })

}

}
else {
  this.validateAllFormFields(this.pwdsetform);
 // console.log('form error');
}


}

validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
   // console.log(field);
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    }
  });
  }


validateToken(){
//this.spinner.show();
this.postToken.email = this.getEmail;
this.postToken.token  = this.getToken;

//console.log("token  email ::: "+ this.postToken.email);
//console.log("token reset  ::: "+ this.postToken.token);

var data=JSON.stringify(this.postToken)
      this.http.post(this.baseURL+'/managers/validate_token',this.postToken, this.options).subscribe(res => {

      this.response = res;
      //console.log("RESPONSE ::: "+JSON.stringify(res));

    if (this.response.responseCode == '000') {
     // this.spinner.hide();

      //swal("Success", 'VALID TOKEN', "success");
      //this.router.navigate(['/log-in']);

    }
    else if (this.response.responseCode == 'R01') {
     
     // this.spinner.hide();
     // this.router.navigate(['/expired-link'])
      this.router.navigate(['manager_expired_link']);
      //swal("Error!", 'Oops!...Looks like this reset link is no longer valid or expired', "error");
    }
    else {
     
     
     
    }

  })
  
}

}
