import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-select-product-dialog',
  templateUrl: './select-product-dialog.component.html',
  styleUrls: ['./select-product-dialog.component.scss']
})
export class SelectProductDialogComponent implements OnInit {

  productsData=[];

  product:string=null;

  constructor(public dialogRef: MatDialogRef<SelectProductDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    this.productsData=[
      {value:'Motor Insurance',icon:'assets/img/products/motor-icon.png'},
      {value:'Travel Insurance',icon:'assets/img/products/travel-icon.png'},
      {value:'Fire Insurance',icon:'assets/img/products/home-icon.png'}
    ];

    this.product=this.productsData[0].value
  }

  ngOnInit() {

  }
  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {product: this.product} });
  }
}
