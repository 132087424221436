import { formatNumber } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-debit-card-payment',
  templateUrl: './debit-card-payment.component.html',
  styleUrls: ['./debit-card-payment.component.scss']
})
export class DebitCardPaymentComponent implements OnInit {
  appuser: any;

  constructor(
    public service: DashboardService, 
    public dialogRef: MatDialogRef<DebitCardPaymentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
     @Inject(LOCALE_ID) public locale: string
    ) {
      this.appuser = this.service.currentUser();

      this.quotePayload = data.quotePayload;
      this.total_premium =data.total_premium;
      this.formatted_premium=`${'GHS'} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;

      // this.formatted_premium=`${this.quotePayload.currency_code} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;



      if(this.appuser.role=='agent' || this.appuser.role=='broker-admin'){
        this.payload={
          ...this.payload,
          module:'agent',
          agent_code:this.appuser.code.toString(),
          client_code:this.quotePayload.client_code.toString(),
          broker_user_code:'',
          policy_sequence:this.quotePayload.policy_sequence,
          status_of_client: this.quotePayload.status_of_client,
          policy_lob: data.policy_lob
        }
      }else if(this.appuser.role=='broker-user'){
        this.payload={
          ...this.payload,
          module:'broker-user',
          agent_code:this.appuser.broker_user_main_broker_code,
          broker_user_code:this.appuser.code,
          client_code:this.quotePayload.client_code.toString(),
          policy_sequence:this.quotePayload.policy_sequence,
          status_of_client: this.quotePayload.status_of_client,
          policy_lob: data.policy_lob
        }
      }else if(this.appuser.role=='client'){
        this.payload={
          ...this.payload,
          module:'client',
          client_code:this.appuser.code.toString(),
          policy_sequence:this.quotePayload.policy_sequence,
          status_of_client: this.quotePayload.status_of_client,
          policy_lob: data.policy_lob
        }

      }else if (this.appuser.role=='staff'){

        if(data.policy_lob == '20'){
          this.payload={
            ...this.payload,
            module:'staff',
            staff: {
              staff_id:this.appuser.code
            },
            policy_sequence:this.quotePayload.policy_sequence,
            status_of_client: this.quotePayload.status_of_client,
            policy_lob:data.policy_lob
          }
        }else{
          this.payload={
            ...this.payload,
            module:'staff',
            staff_id:this.appuser.code,
            policy_sequence:this.quotePayload.policy_sequence,
            status_of_client: this.quotePayload.status_of_client,
            policy_lob:data.policy_lob
          }
        }
       
      }

    }

   appUser:any;
    method: string;
    quotePayload:any;
    total_premium: any="0";
    formatted_premium: any="";
    extraCharge:any = 0.00;
    amountPlusExtraCharge:any = 0.00;


    payload: any={
      policy_sequence:"",
      transaction_type:"Card",
      status_of_client:"new",
      policy_lob: this.data.policy_lob,
      platform:'webapp'
    };

    networks:any;
    paymentChannels:any;

  ngOnInit() {
    this.GetPaymentPlatform()
  }


  onSubmit=()=>{
     this.service.swalAlart.showLoading();
     this.service.subPayment(this.payload)
      .toPromise()
      .then((response: any)=>{
        if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
          this.quotePayload.module="PAID";

          this.service.swalAlart.close();

          if(response.hasOwnProperty('transactionUrl')){
            window.open(response.transactionUrl,'_blank');
            this.dialogRef.close({ event: 'close', data: {quotePayload:{module:this.quotePayload.module}} });
          }
          else
          this.service.swalAlart.fire({
            title: 'Failed',
            text:'Checkout Page not provided',
            icon: 'error',
          });
        }else if(response.hasOwnProperty('responseCode') && response.responseCode=='001'){
          this.quotePayload.module="PAID";
          this.service.swalAlart.close();
          this.service.swalAlart.fire({
            title: 'Processing Payment...',
            html: 'Please wait. An sms and email of policy and sticker details will be sent to you after processing payment.',
            icon: 'success',
            confirmButtonText:'Done',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result)=>{
            if(result.value)
              this.router.navigate(['transactions']);
              this.dialogRef.close({ event: 'close', data: {quotePayload:this.quotePayload,total_premium: this.total_premium} });
           });
        }
        else if(response.hasOwnProperty('responseCode') && response.responseCode=='E31')
          this.service.swalAlart.fire({
            title: 'Failed',
            text:  'Vehicle Already Exists Under Different Policy',
            icon:  'error',
          });
        else {
          let errors=response.hasOwnProperty('responseMessage') && response.responseCode=='400 BAD_REQUEST'?JSON.parse(response.responseMessage):null;
          this.service.swalAlart.fire({
            title: 'Failed',
            text:errors && errors.errors.length>0?`MID::${errors.errors[0]}`: 'Payment failed!',
            icon: 'error',
          });
        }
      }).catch(error=>{
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Failed',
          text: 'Kindly check connection and retry!',
          icon: 'error',
        });
      })

  }





  GetPaymentPlatform(){
    this.service.swalAlart.showLoading();
    this.service.GetPaymentPlatform(this.payload)
      .toPromise()
      .then((response: any) => {
        console.log("content>>>" + response.data)

        this.paymentChannels = response.data

        this.service.swalAlart.close();
      }).catch(error => {
        this.service.swalAlart.close();
      })

  }
}
