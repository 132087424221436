import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../modules/service/dashboard.service';
import swal from 'sweetalert2';
import {ChartService} from '../../../modules/service/chart.service';
import {Chart} from 'chart.js';
import { Duration } from 'moment';
import { MatSelectChange } from '@angular/material';

import { MatAutocompleteSelectedEvent } from '@angular/material';

interface DurationPeriod {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-widget-transactionalgraph',
  templateUrl: './transactionalgraph.component.html',
  styleUrls: ['./transactionalgraph.component.scss']
})
export class TransactionalgraphComponent implements OnInit {

  selected = '30';
  selectedValue: string;
  globaldropDownValue = 30;
  module = "agents"

  days: DurationPeriod[] = [
    {value: '7', viewValue: 'Last 7 Days'},
    {value: '30', viewValue: 'Last 30 Days'},
    {value: 'months', viewValue: 'Last Year'}
  ];


  charts:any;
  userdata :any //get users email


  total:any;
  transact:any;
  pending:any;

  loaderror:any=''

  sday:boolean=true;
  thday:boolean=false;
  yrday:boolean=false;


  loading:boolean = false;
  topperformstartdate:any;
  topperformenddate:any;
  selectmonth: any;

  periodchart:any=[];

  constructor(private service:DashboardService, private router:Router,  private chartservice: ChartService) {
    this.userdata=this.service.currentUser();
  }

  ngOnInit() {

    if (this.userdata.role == "agent" || this.userdata.role == "broker-user" || this.userdata.role == "broker-admin"){

      //console.log("value" + this.days )

      //hide grid lines
      // Chart.defaults.scale.gridLines.drawOnChartArea = false;

      //x and y axis


      var today = new Date(); //get todays date
      // var month = new Date();
      this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date



      //default use monthly date to plot
      today.setDate(today.getDate() - 30) // subtract 7 days from todays date
      this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start date

      // this.monthyDetails()
      //this.monthyDetails()
      this.perfomanceDetails(30 ,this.module);

    }else{

    }

  }


  perfomanceDetails( passedDays:number , module:String){

 //get today and respective past day
    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - passedDays) // subtract respective days from todays date
    this.topperformstartdate = today.toISOString().substring(0,10)



    //calculate days
    let minDate = new Date(this.topperformstartdate)
    let maxDate = new Date(this.topperformenddate)

    var days ;

     days = (maxDate.getTime()-minDate.getTime())/(1000 * 3600 * 24)
   // console.log("days" + days);
    //let month = (maxDate.getMonth()-minDate.getMonth())/(1000*3600*24*30)
    //let month = (maxDate.getMonth()-minDate.getMonth())/(1000*3600*24*365

    if (days > 30 ){

      days = "months";
    }

    //if(days===7||days === 30){
      if (this.topperformstartdate === undefined || this.topperformenddate === undefined) {
        swal.fire({icon:"warning", title: 'Oops...',text: 'Start Date or End Date was not selected.',footer: 'Please, make the necessary changes and try again.'});
      } else if(this.topperformstartdate > this.topperformenddate) {
        swal.fire({ icon: 'warning',title: 'Oops...',text: 'Start Date is more than End Date.',footer: 'Please, make the necessary changes and try again.'});
      } else {
        this.loading = true
        let payload: any={}

    if(this.userdata.role==='client'){
      payload={
        role:'client',
        client_code:this.userdata.code,
        minDate:this.topperformstartdate,
        maxDate:this.topperformenddate,
        duration:days.toString(),
        module:this.module
      }
    }else if(this.userdata.role==='agent' || this.userdata.role==='broker-admin'){
      payload={
        role:'agent',
        agent_code:this.userdata.code,
        minDate:this.topperformstartdate,
        maxDate:this.topperformenddate,
        duration:days.toString(),
        module:this.module
      }
    }else if(this.userdata.role==='broker-user'){
      payload={
        role:'broker-user',
        broker_user_code:this.userdata.code,
        minDate:this.topperformstartdate,
        maxDate:this.topperformenddate,
        duration:days.toString(),
        module:this.module
      }

    }else if(this.userdata.role==='staff'){
      payload={
        role:'staff',
        staff_id: this.userdata.code,
        minDate:this.topperformstartdate,
        maxDate:this.topperformenddate,
        duration:days.toString(),
        module:this.module
      }

    }else{}

        this.service.gettransactionDetails(payload)
          .subscribe(data => {
            this.loading = false;
            this.periodchart = data['responseData'];

            let labels = [],counts=[],amounts=[];

            if(data['responseData'])
            data['responseData'].forEach(element => {
              labels.push(element.date);
              amounts.push(parseFloat(element.total.replace(/,/g,'')));
            });
            
            this.charts = this.chartservice.perfomanceChart(labels,amounts,'detailstrans',this.module);
            // this.topperformenddate = ''
            // this.topperformstartdate = ''

          },error => {
            this.loading = false;
            swal.fire({ icon: 'error',title: 'Oops...',text: error.message,footer: 'Please, make the necessary changes and try again.'});
          })
      }

  //  }

    // } else {
    //   return  swal.fire({ icon: 'error',title: 'Oops...',text: 'Interval Should be 7 or 30 days',footer: 'Please, make the necessary changes and try again.'});
    // }

  }


  changeRatio(event: MatSelectChange) {
    console.log(event.value);

if (event.value == "7"){
  console.log("doing 7");
  this.globaldropDownValue = 7
  //this.day_7()
  this.perfomanceDetails(7,this.module)


}
else if (event.value == "30"){

  console.log("doing 30");
  this.globaldropDownValue = 30
  //this.day_30()
  this.perfomanceDetails(30, this.module)

}
else {

  console.log("doing 365");
  this.globaldropDownValue = 365
  //this.day_365()
  this.perfomanceDetails(365, this.module)
}


  }




  agent(){
    this.module = "agent";

    this.perfomanceDetails(this.globaldropDownValue, this.module)

  }

  customer(){
    this.module = "customer";
    this.perfomanceDetails(this.globaldropDownValue, this.module)

  }


  // monthyDetails(){

  //   let minDate = new Date(this.topperformstartdate)
  //   let maxDate = new Date(this.topperformenddate)

  //   let month = (maxDate.getTime()-minDate.getTime())/(1000 * 3600 * 24)


  //     if (this.topperformstartdate === undefined || this.topperformenddate === undefined) {
  //       swal.fire({icon:"warning", title: 'Oops...',text: 'Start Date or End Date was not selected.',footer: 'Please, make the necessary changes and try again.'});
  //     } else if(this.topperformstartdate > this.topperformenddate) {
  //       swal.fire({ icon: 'warning',title: 'Oops...',text: 'Start Date is more than End Date.',footer: 'Please, make the necessary changes and try again.'});
  //     } else {
  //       this.loading = true;
  //       this.service.gettransactionDetails({email:this.ud.email,minDate:this.topperformstartdate,maxDate:this.topperformenddate,duration:month.toString(), role:this.ud.role})
  //         .subscribe(data => {
  //           this.loading = false;
  //           this.periodchart = data['responseData'];

  //           let labels = [],counts=[],amounts=[];
  //           data['responseData'].forEach(element => {
  //             labels.push(element.date);
  //             amounts.push(parseFloat(element.total.replace(/,/g,'')));
  //           });

  //           this.charts = this.chartservice.perfomanceChart(labels,amounts,'detailstrans');
  //           // this.topperformenddate = ''
  //           // this.topperformstartdate = ''

  //         },error => {
  //           this.loading = false;
  //           swal.fire({ icon: 'error',title: 'Oops...',text: error.message,footer: 'Please, make the necessary changes and try again.'});
  //         })

  //   }
  //   // } else {
  //   //   return  swal.fire({ icon: 'error',title: 'Oops...',text: 'Interval Should be 7 or 30 days',footer: 'Please, make the necessary changes and try again.'});
  //   // }

  // }


  //get results from button




// days_general(days:number){
//   // this.sday = false;
//   // this.thday = false;
//   // this.yrday = true;
//   var today = new Date(); //get todays date
//   this.topperformenddate = today.toISOString().substring(0,10) //convert todays date to format eg.2019-08.02 and set it to end date

//   today.setDate(today.getDate() - days) // subtract respective days from todays date
//   this.topperformstartdate = today.toISOString().substring(0,10) //set that date to start date

//   this.perfomanceDetails();
// }




}
