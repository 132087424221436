import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { setSecurityQuestionsComponent } from '../set-security-questions/set-security-questions.component';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss']
})
export class ChangePinComponent implements OnInit {

  constructor(
    public service: DashboardService, 
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<ChangePinComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private router: Router,
    private _snackBar: MatSnackBar
    ) {

    this.appuser=this.service.currentUser();

    if(this.appuser.role=='client'){
    this.payload={...this.payload,client_code:this.appuser.code};
    this.payload.module=this.appuser.role;

    }else if (this.appuser.role=='agent'){

      this.payload={...this.payload,agent_code:this.appuser.code};
      this.payload.module=this.appuser.role;

    }else if (this.appuser.role=='broker-user'){

      this.payloadBrokerUser={
        ...this.payloadBrokerUser,
                broker_user_code:this.appuser.code,
                broker_user_modified_by:this.appuser.email
      };

      this.payloadBrokerUser.module=this.appuser.role;


    } else if (this.appuser.role=='broker-admin'){

      this.payloadBrokerAdmin={
        ...this.payloadBrokerAdmin,
        broker_admin_email:this.appuser.email,
        broker_user_modified_by:this.appuser.email
      };
      
      this
    
    } else{}
    

  }

  ngOnInit() {}

  appuser: any
  confirm_new_pin:""
  hasError=false;
  pin_error:boolean=false;
  payload: any={
    module:'',
    pin:'',
    newpin:''
  }

  payloadBrokerUser: any={
    module:'',
    broker_user_pin:'',
    broker_user_new_pin:''
  }

  payloadBrokerAdmin: any={
    module:'',
    broker_admin_pin:'',
    broker_admin_new_pin:''
  }



  onSubmit=()=>{
   
    var loggedInAs = this.appuser.role
    console.log("module>>>>" + loggedInAs)

    if(loggedInAs == "broker-user"){
      this.changePINBrokerUser()
    
    }else if(loggedInAs == "broker-admin"){
      this.changePINBrokerAdmin()

    }
    
    else{
      this.changePINAgentClient()
    }


  }

  changePINAgentClient(){
    this.hasError=false;
      if(this.payload.pin=='' || this.payload.newpin=='')
       this.hasError=true;
      else if(this.payload.newpin!=this.confirm_new_pin){
        this.alertSnackBar('New pins do not match','Validation failed')
        this.hasError=true;
      }
      else if(!this.service.isValidatePin(this.payload.newpin)){
        this.alertSnackBar('PIN must not contain sequential nor contain all repeating digits.','Validation failed');
        this.pin_error=this.hasError=true;
      }

      if(this.hasError)
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      })
      else{
        this.service.swalAlart.showLoading();

        this.service.changePin(this.payload)
        .toPromise()
        .then((response: any)=>{
            if(response.hasOwnProperty('responseCode') && response.responseCode=='000')
            {
              this.service.swalAlart.fire({
                title: 'Successful',
                text: 'Your new PIN is set.',
                icon: 'success',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((dialogResponse)=>{
                 if(dialogResponse.hasOwnProperty('value') && dialogResponse.value==true){
                  this.dialogRef.close({ event: 'close', data: {} });
    
                  var account_activation_requested = localStorage.getItem('account_activation_requested')
                  console.log("account_activation_requested>>>>>" + localStorage.getItem('account_activation_requested'))

                  if(account_activation_requested == '1' || this.appuser.broker_user_answers_set == 0){

                    console.log("show set security question dialog")
                    this.openSetSecurityQuestionsFormDialog()
                    
                  }else{
                    this.router.navigate(['']);
                    window.localStorage.clear();
                  }
                 
                 
                 }
                
              });
            }
            else if(response.hasOwnProperty('responseCode') && response.responseCode=='098'){
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Invalid Pin!',
                icon: 'warning',
              });
            }
            else
            this.service.swalAlart.fire({
              title: 'Failed',
              text: 'Pin Chane Failed!',
              icon: 'error',
            });
        }).catch(error=>{
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Pin Change Failed!',
            icon: 'error',
          });
        })
      }

  }

  changePINBrokerUser(){
    this.hasError=false;
      if(this.payload.pin=='' || this.payload.newpin=='')
       this.hasError=true;
      else if(this.payload.newpin!=this.confirm_new_pin){
        this.alertSnackBar('New pins do not match','Validation failed')
        this.hasError=true;
      }
      else if(!this.service.isValidatePin(this.payload.newpin)){
        this.alertSnackBar('PIN must not contain sequential nor contain all repeating digits.','Validation failed');
        this.pin_error=this.hasError=true;
      }

      if(this.hasError)
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      })
      else{
        this.payloadBrokerUser.broker_user_pin = this.payload.pin
        this.payloadBrokerUser.broker_user_new_pin = this.payload.newpin
        console.log("payload>>>>>" + this.payloadBrokerUser)

        
        this.service.swalAlart.showLoading();

        this.service.changePinBrokerUser(this.payloadBrokerUser)
        .toPromise()
        .then((response: any)=>{
            if(response.hasOwnProperty('responseCode') && response.responseCode=='000')
            {
              this.service.swalAlart.fire({
                title: 'Successful',
                text: 'Your new PIN is set.',
                icon: 'success',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((dialogResponse)=>{
                 if(dialogResponse.hasOwnProperty('value') && dialogResponse.value==true){
                  this.dialogRef.close({ event: 'close', data: {} });
    
                  var account_activation_requested = localStorage.getItem('account_activation_requested')
                  console.log("account_activation_requested>>>>>" + localStorage.getItem('account_activation_requested'))

                  if(account_activation_requested == '1' || this.appuser.broker_user_answers_set == 0){

                    console.log("show set security question dialog")
                    this.openSetSecurityQuestionsFormDialog()
                    
                  }else{
                    this.router.navigate(['']);
                    window.localStorage.clear();
                  }
                 
                 
                 }
                
              });
            }
            else if(response.hasOwnProperty('responseCode') && response.responseCode=='098'){
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Invalid Pin!',
                icon: 'warning',
              });
            }
            else
            this.service.swalAlart.fire({
              title: 'Failed',
              text: 'Pin Chane Failed!',
              icon: 'error',
            });
        }).catch(error=>{
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Pin Change Failed!',
            icon: 'error',
          });
        })
      }

  }

  changePINBrokerAdmin(){
    this.hasError=false;
      if(this.payload.pin=='' || this.payload.newpin=='')
       this.hasError=true;
      else if(this.payload.newpin!=this.confirm_new_pin){
        this.alertSnackBar('New pins do not match','Validation failed')
        this.hasError=true;
      }
      else if(!this.service.isValidatePin(this.payload.newpin)){
        this.alertSnackBar('PIN must not contain sequential nor contain all repeating digits.','Validation failed');
        this.pin_error=this.hasError=true;
      }

      if(this.hasError)
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      })
      else{
        console.log("payload newpin >>>>>" + JSON.stringify(this.payload.newpin))
        this.payloadBrokerAdmin.broker_admin_pin = this.payload.pin
        this.payloadBrokerAdmin.broker_admin_new_pin = this.payload.newpin
        console.log("payload>>>>>" + JSON.stringify(this.payloadBrokerAdmin))

        
        this.service.swalAlart.showLoading();

        this.service.changePinBrokerAdmin(this.payloadBrokerAdmin)
        .toPromise()
        .then((response: any)=>{
            if(response.hasOwnProperty('responseCode') && response.responseCode=='000')
            {
              this.service.swalAlart.fire({
                title: 'Successful',
                text: 'Your new PIN is set.',
                icon: 'success',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((dialogResponse)=>{
                 if(dialogResponse.hasOwnProperty('value') && dialogResponse.value==true){
                  this.dialogRef.close({ event: 'close', data: {} });
    
                  // var account_activation_requested = localStorage.getItem('account_activation_requested')
                  // console.log("account_activation_requested>>>>>" + localStorage.getItem('account_activation_requested'))

                  if(this.appuser.broker_admin_answers_set == 0 || this.appuser.broker_admin_answers_set == null){

                    console.log("show set security question dialog")
                    this.openSetSecurityQuestionsFormDialog()
                    
                  }else{
                    this.router.navigate(['']);
                    window.localStorage.clear();
                  }
                 
                 
                 }
                
              });
            }
            else if(response.hasOwnProperty('responseCode') && response.responseCode=='098'){
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Invalid Pin!',
                icon: 'warning',
              });
            }
            else
            this.service.swalAlart.fire({
              title: 'Failed',
              text: 'Pin Chane Failed!',
              icon: 'error',
            });
        }).catch(error=>{
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Pin Change Failed!',
            icon: 'error',
          });
        })

      }

  }

  //trigger the snackbar
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onPinInput=(evt)=>{
    isNaN(+`${evt.target.value}`)
     if(isNaN(+`${evt.target.value}`))
     evt.target.value='';
  }


  openSetSecurityQuestionsFormDialog() {
    const dialogRef = this.dialog.open(setSecurityQuestionsComponent, {
      disableClose: true,
      width: '600px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }



}
