import { formatNumber } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from '../../service/dashboard.service';
import { QuoteBenefitsComponent } from '../quote-benefits/quote-benefits.component';

export interface QuoteElement {
  value: string;
  label: string;
}

@Component({
  selector: 'app-quote-summary-fleet',
  templateUrl: './quote-summary-fleet.component.html',
  styleUrls: ['./quote-summary-fleet.component.scss']
})
export class QuoteSummaryFleetComponent implements OnInit {


  constructor(
    public service: DashboardService, 
    public dialog :MatDialog, 
    public dialogRef: MatDialogRef<QuoteSummaryFleetComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) 
    public data: any,
    @Inject(LOCALE_ID) public locale: string
    ) {

      if(data){
        //console.log('Fire Summary',data)
        this.confirmedQuote=data.confirmedQuote;

        if(data.quote.hasOwnProperty('confirmedRenewal'))
        this.confirmedRenewal=data.quote.confirmedRenewal;
        else if(data.hasOwnProperty('confirmedRenewal'))
        this.confirmedRenewal=data.confirmedRenewal;

        if(data.quote.hasOwnProperty('isPolicy'))
        this.isPolicy=data.quote.isPolicy;

        if(data.quote.hasOwnProperty('hideMailStatus'))
        this.hideMailStatus=data.quote.hideMailStatus;

        if(data['flow']){
          //when the data is coming from travel quote
          this.flow=data['flow'];
          this.selectedQuote=data.quote;
          let email_item: any=null;

          if(data['flow']=='moto')
          email_item=data.quote.CUSTOMER_EMAIL;
          else
          email_item=this.selectedQuote.find((item: any)=>item.label=='Customer Email');

          if(email_item)
           this.customer_email=email_item.value;
        }

        if(data['flow']=='moto'){

          this.selectedQuote=[
            {value: data.policyInfo.date_updated,label:'Date Prepared'}
          ];

          if(data.policyInfo.prepared_by)
          this.selectedQuote=[...this.selectedQuote,{value: data.policyInfo.prepared_by,label:'Prepared By'}]

          this.selectedQuote=[
              ...this.selectedQuote,
            {value: `${data.policyInfo.customer_first_name?data.policyInfo.customer_first_name:''} ${data.policyInfo.customer_last_name}`.trim(),label:'Customer Name'},
            {value: data.policyInfo.customer_email,label:'Customer Email'},
            {value: data.policyInfo.client_code,label:'Customer Code'},
            {value: data.policyInfo.customer_phone,label:'Mobile Number'},
            {value: data.quote.year_of_manufacture,label:'Year of Manufacturing'},
            {value: `${data.quote.make} ${data.quote.quote_model}`,label:'Make & Model'},
            {value: `${data.quote.vehicle_registration}`,label:'RegNo.'},
            {value: data.quote.cubic_capacity,label:'Cubic Capacity'},
            {value: `${data.policyInfo.currency_code} ${formatNumber(data.quote.sum_insured , this.locale, '1.2-2') }`, label:'Sum Insured'},
            {value: `${(data.policyInfo.duration_type.toLowerCase()=='short term'|| data.policyInfo.duration_type.toLowerCase()=='shortrate'|| data.policyInfo.duration_type.toLowerCase()=='short rate')?data.policyInfo.quote_days:data.policyInfo.quote_days} ${(data.policyInfo.duration_type.toLowerCase()=='short term'|| data.policyInfo.duration_type.toLowerCase()=='shortrate')?'Day(s)':'Day(s)'}`,label:'Duration'},
            {value:(data.quote.excess_type_code=='ENA'?'NO':'YES'),label:'Excess Bought'},
            {value:`${data.policyInfo.currency_code} ${!data.quote.tppd_limit?0:data.quote.tppd_limit.toString().trim()}`,label:'Extra TPPD Bought'},
            {value:`${data.policyInfo.currency_code} ${!data.quote.additional_pa_amt?0:data.quote.additional_pa_amt.toString().trim()}`,label:'Additional PA'},
            {value:`${data.policyInfo.currency_code} ${data.quote.additional_loading_amt||0}`,label:'Additional Loading'},
            {value:data.quote.inception_date,label:'Start Date'},
            {value:data.quote.expiry_date,label:'Expiry Date'},
            {value:data.policyInfo.product_description.product_description,label:'Insurance Product'},
            // {value:(data.policyInfo.product=='201'?'COMPREHENSIVE':(data.policyInfo.product=='202'?'THIRD PARTY FIRE & THEFT':'THIRD PARTY'))+',<br/>'+data.policyInfo.product_description.product_description,label:'Insurance Product'}
          ];

          this.customer_email = data.policyInfo.customer_email
        }

        if(data['flow']){
          if(data['hide_foreign_currency']==1)
            this.total_premium1=`<span class="first">${'GHS'} ${data.total_premium}</span>`;
          else{
            this.total_premium1=`${'GHS'} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;
            this.total_premium1=`${'GHS'} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;
            this.total_premium2=data.foreign_currency && data.foreign_currency!='GHC' && data.foreign_currency!='GHS' ?`${data.foreign_currency.replace('GHC','GHS')} ${formatNumber(data.foreign_amount, this.locale, '1.2-2')}`:null;
          }
        }
        else
        this.total_premium1=`${data.quote.CURRENCY_CODE} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;

   
      }
    }

    confirmedQuote: boolean=false
    confirmedRenewal: boolean=false
    selectedQuote: QuoteElement[];
    total_premium1:string="";
    total_premium2:string="";
    isPolicy:boolean=false;
    hideMailStatus:boolean=false;
    customer_email:string="";
    flow:string="moto";
    displayedColumns: string[] = ['label', 'value'];

    query={
      filter:"",
      selectValue:"",
      filteredResults:[]
    }

  ngOnInit() {
    this.query.filter="";
  }
  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {confirmedQuote:true} });
  }
  editPolicy(){
    this.dialogRef.close({ event: 'close', data: {confirmedQuote:true,edit:true} });
  }
  showBenefits(){
    const ref = this.dialog.open(QuoteBenefitsComponent, {
      disableClose :true,
      width: '650px',
      data: { }
    });

    ref.afterClosed().subscribe(result => {});
  }
}
