import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-reset-pin',
  templateUrl: './reset-pin.component.html',
  styleUrls: ['./reset-pin.component.scss']
})
export class ResetPinComponent implements OnInit {

  constructor(public service: DashboardService, public dialogRef: MatDialogRef<ResetPinComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private router: Router,private _snackBar: MatSnackBar) {

    this.appuser=this.service.currentUser();

    if(data.module){
      console.log('module',data.module)
      this.payload.module=data.module;
    }
  }

  ngOnInit() {}

  accountType=[
    {name:'Customer',value:'client'},
    {name:'Intermediary',value:'agent'},
    {name:'Broker User',value:'broker-user'},
  ]

  step=1;
  appuser: any
  confirm_new_pin:""
  hasError=false;
  questions:any;
  payload: any={
    code:'',
    module:'',
    email:'',
    answer1:'',
    answer2:'',
    answer3:'',
    user_type:''
  }

  payloadBrokerUser: any={
    broker_user_code:'',
    module:'broker-user',
    broker_user_email:'',
    broker_user_answer1:'',
    broker_user_answer2:'',
    broker_user_answer3:'',
    user_type:''
  }

  onSubmit=()=>{
    console.log("module>>>>" + this.payload.module )

    if(this.payload.module == "broker-user"){
      this.resetPINBrokers()
    }else{
      this.resetPINAgentClient()
    }

  }





  resetPINAgentClient(){
    this.hasError=false;
    if(this.step==2){
        if(this.payload.module=='' || this.payload.answer1==''|| this.payload.answer2==''|| this.payload.answer3==''){
          this.hasError=true;
          this.service.swalAlart.fire({
            title: 'Validation Alert',
            text: 'Kindly complete the highlighted fields',
            icon: 'warning',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
          })
        }
        else
        {
           this.service.answerSecurityQuestions(
             {
               answer1:this.payload.answer1,
               answer2:this.payload.answer2,
               answer3:this.payload.answer3,
               agent_code:(this.payload.module=='agent'?this.payload.code:''),
               client_code:(this.payload.module=='client'?this.payload.code:''),
               module:this.payload.module}
               )
           .toPromise()
           .then((response:any)=>{
               if(response.responseCode=='000')
               this.service.swalAlart.fire({
                title: 'Successful',
                text: `A one time Pin (OTP) has been mailed to you (${this.payload.email})`,
                icon: 'success',
              });
              else if(response.hasOwnProperty('responseMessage'))
              this.service.swalAlart.fire({
                title: 'Failed',
                text: response.responseMessage,
                icon: 'error',
              });
              else
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Reset Pin Failed!',
                icon: 'error',
              });
           })
           .catch(error=>{
            this.service.swalAlart.fire({
              title: 'Failed',
              text: 'Pin Reset Failed!',
              icon: 'error',
            });
           })
        }
      }
      else if(this.step==1){
        if(this.payload.module=='' || this.payload.email==''){
          this.hasError=true;
          this.service.swalAlart.fire({
            title: 'Validation Alert',
            text: 'Kindly complete the highlighted fields',
            icon: 'warning',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
          })
        }
        else{
          this.service.swalAlart.showLoading();
          this.service.resetPin(
            {
              module: this.payload.module,
              email: this.payload.email
            }
              )
        .toPromise()
        .then((response: any)=>{
            if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
              this.questions=(response.hasOwnProperty('profile') && response.profile.length) ? response.profile[0]:null;
              this.payload.code=response.code;
              if(this.questions){
                this.service.swalAlart.close();
                this.step=2;
              }
            }
            else if(response.hasOwnProperty('responseCode') && response.responseCode=='W1012'){
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Wrong Email!',
                icon: 'warning',
              });
            }
            else if(response.hasOwnProperty('responseCode') && response.responseCode=='098'){
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Invalid Email!',
                icon: 'warning',
              });
            }
            else
            this.service.swalAlart.fire({
              title: 'Failed',
              text: 'Reset Pin Failed!',
              icon: 'error',
            });
        }).catch(error=>{
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Pin Reset Failed!',
            icon: 'error',
          });
         })
        }
      }

  }


  resetPINBrokers(){
    this.hasError=false;
    if(this.step==2){
        if(this.payload.module=='' || this.payload.answer1==''|| this.payload.answer2==''|| this.payload.answer3==''){
          this.hasError=true;
          this.service.swalAlart.fire({
            title: 'Validation Alert',
            text: 'Kindly complete the highlighted fields',
            icon: 'warning',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
          })
        }
        else
        {
           this.service.answerSecurityQuestionsBroker(
             {

              broker_user_answer1: this.payload.answer1,
              broker_user_answer2: this.payload.answer2,
              broker_user_answer3: this.payload.answer3,
              broker_user_code: this.payloadBrokerUser.broker_user_code,
              module: this.payload.module
            
              })
           .toPromise()
           .then((response:any)=>{
               if(response.responseCode=='000')
               this.service.swalAlart.fire({
                title: 'Successful',
                text: `A one time Pin (OTP) has been mailed to you (${this.payload.email})`,
                icon: 'success',
              });
              else if(response.hasOwnProperty('responseMessage'))
              this.service.swalAlart.fire({
                title: 'Failed',
                text: response.responseMessage,
                icon: 'error',
              });
              else
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Reset Pin Failed!',
                icon: 'error',
              });
           })
           .catch(error=>{
            this.service.swalAlart.fire({
              title: 'Failed',
              text: 'Pin Reset Failed!',
              icon: 'error',
            });
           })
        }
      }
      else if(this.step==1){
        if(this.payload.module=='' || this.payload.email==''){
          this.hasError=true;
          this.service.swalAlart.fire({
            title: 'Validation Alert',
            text: 'Kindly complete the highlighted fields',
            icon: 'warning',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
          })
        }
        else{
          this.payloadBrokerUser.broker_user_email = this.payload.email
          
          console.log("payload>>>>>" + this.payloadBrokerUser)

          this.service.swalAlart.showLoading();
          this.service.resetPinBroker(
            {
              module:this.payloadBrokerUser.module,
              broker_user_email:this.payloadBrokerUser.broker_user_email
            }
              )
        .toPromise()
        .then((response: any)=>{
            if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
                this.service.swalAlart.close();

                console.log("broker_user_code>>>>>" + response.objectData.broker_user_code)
                this.payloadBrokerUser.broker_user_code = response.objectData.broker_user_code
                
                this.step=2;
              
            }
            else if(response.hasOwnProperty('responseCode') && response.responseCode=='W1012'){
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Wrong Email!',
                icon: 'warning',
              });
            }
            else if(response.hasOwnProperty('responseCode') && response.responseCode=='098'){
              this.service.swalAlart.fire({
                title: 'Failed',
                text: 'Invalid Email!',
                icon: 'warning',
              });
            }
            else
            this.service.swalAlart.fire({
              title: 'Failed',
              text: response.responseMessage,
              icon: 'error',
            });
        }).catch(error=>{
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Pin Reset Failed!',
            icon: 'error',
          });
         })
        }
      }

  }















  //trigger the snackbar
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onPinInput=(evt)=>{
    isNaN(+`${evt.target.value}`)
     if(isNaN(+`${evt.target.value}`))
     evt.target.value='';
  }
}
