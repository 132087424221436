import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { CryptoUtils, Logger } from 'msal';
import { AgentSignUpComponent } from '../modules/dialogs/agent-sign-up/agent-sign-up.component';
import { InfoAlertComponent } from '../modules/dialogs/info-alert/info-alert.component';
import { SelectProductDialogComponent } from '../modules/dialogs/select-product-dialog/select-product-dialog.component';
import { SelectUserTypeComponent } from '../modules/dialogs/select-user-type/select-user-type.component';
import { SignupComponent } from '../modules/dialogs/signup/signup.component';
import { QuickFireQuoteComponent } from '../quick-fire-quote/quick-fire-quote.component';
import { QuickMotoQuoteComponent } from '../quick-moto-quote/quick-moto-quote.component';
import { QuickTravelQuoteComponent } from '../quick-travel-quote/quick-travel-quote.component';
import swal from 'sweetalert2'
import { DashboardService } from '../modules/service/dashboard.service';
import { ChangePinComponent } from '../modules/dialogs/change-pin/change-pin.component';

@Component({
  selector: 'app-login-page-staff',
  templateUrl: './login-page-staff.component.html',
  styleUrls: ['./login-page-staff.component.scss']
})


export class LoginPageStaffComponent implements OnInit {
  isIframe = false;
  loggedIn = false;
  appuser: any;
  userName: string;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private service: DashboardService,
  ) { }


  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.checkoutAccount();

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkoutAccount();
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));

  }

  checkoutAccount() {
    console.log('getAccount: ', this.authService.getAccount());
    this.loggedIn = !!this.authService.getAccount();
    console.log('loggedIn: ', this.loggedIn);

    if(this.loggedIn){
      this.userName = this.authService.getAccount().userName
      this.SCLogin()
    }
    
  }

  MSLogin() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup()
      ;
    }
  }

  MSLogout() {
    this.authService.logout();
  }



  SCLogin(){
    const payload = {
      staff_email: this.userName
    }
      swal.showLoading()
      this.service.staffLogin(payload)
        .toPromise()
        .then((response: any) => {
          swal.hideLoading()
    
          if (response.hasOwnProperty('responseCode') && response.responseCode === '000') {
            swal.fire({
              icon: 'success',
              text: 'Welcome to smartCover Dashboard',
              timer: 1000,
              showConfirmButton: false
            });

            this.appuser = this.service.currentUser(response.data, true);
            this.router.navigate(['dashboard']);
          
            console.log('appuser ===> ' + JSON.stringify(this.appuser))


            if (response.hasOwnProperty('account_activation_requested') && response.account_activation_requested == '1') {
              //save account activation state as 1 in localstorage
              localStorage.setItem('account_activation_requested', '1')
              this.changePin()

            } else if (response.hasOwnProperty('reset_requested') && response.reset_requested == '1') {
              this.changePin()
            }

          }
          else {
            swal.showLoading()
            swal.fire({
              title: '',
              text: response.responseMessage,
              icon: 'error',
            });
          }

        }).catch(error => {
          swal.fire({
            title: 'Login Failed',
            text: error,
            icon: 'error',
          });
        })
  }



  changePin() {
    const dialogRef = this.dialog.open(ChangePinComponent, {
      disableClose: true,
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }




}
