import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/modules/service/dashboard.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-widget-dlytransact',
  templateUrl: './dlytransact.component.html',
  styleUrls: ['./dlytransact.component.scss']
})
export class DlytransactComponent implements OnInit {
  falseAuth:boolean = true;

  total:any;
  transact:any;
  pending:any;
  transacttoday:any;
  policytoday:any;
  renewpolicy:any;
  stickersoldtoday:any;



  @ViewChild('element') element: ElementRef;
  private options = {
    content: 'This is a basic popup'
  }


  loading:boolean = false
  userdata :any//get user id
  loaderror:any = ''

  config = {
    content: 'This is a basic Popup'
  }

  constructor( private router:Router, private service:DashboardService) {
    this.userdata=this.service.currentUser()
  }

  ngOnInit() {
    console.log("daily transactions>>>>>")

      this.loading = true
      let payload: any={}

      if(this.userdata.role==='client'){
        payload={
          role:'client',
          client_code:this.userdata.code
        }
      }else if(this.userdata.role==='agent' || this.userdata.role==='broker-admin'){
        payload={
          role:'agent',
          agent_code:this.userdata.code
        }
      }else if(this.userdata.role==='broker-user'){
        payload={
          role:'broker-user',
          broker_user_code:this.userdata.code
        }

     
      }else if(this.userdata.role==='staff'){
        payload={
          role:'staff',
          staff_id: this.userdata.code
        }
     
      }
      else{}


    this.service.getAnaytics(payload).subscribe(data=>{
    this.loading = false
    console.log("daily transactions data>>>>>" + data)
    
        if(data.hasOwnProperty('today_transaction')){
          this.transacttoday = parseFloat(data['today_transaction'][0]['totalAmount'].replace(",","").trim())
        }
    }, error => {
      this.loading = false
      this.loaderror = 'Check your Internet!'
      swal.fire({
        title: 'Oops...',
        text: error.name,
        footer: ''
      });
    });


}



over(){
console.log("hover")

}






}
