import { NullAstVisitor } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/modules/service/dashboard.service';
import { PRIVILEDGES } from '../../../modules/service/roles.mockup';
import { PRIVILEDGESNEW } from '../../../modules/service/roles.mockup';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  falseAuth: boolean = false;

  appuser: any;
  menus: any = [];
  menusNew: any = [];
  user: any;

  constructor(private router: Router, private service: DashboardService) {
    this.appuser = this.service.currentUser();
    //this.appuser.role = "broker-admin"

    if (this.appuser) {
      this.menus = PRIVILEDGES.filter((menu) => {
        return menu.role === this.appuser.role;
      })
      this.menusNew = PRIVILEDGESNEW.filter((menus) => {
        return menus.role === this.appuser.role;
      })

    } else {
      window.localStorage.clear();
      this.router.navigate(['']);
    }
  }

  selectedIndex: number;
  selectedNumber: number;


  ngOnInit() {

    if (this.appuser.role == "agent") {
      this.user = "INTERMEDIARY"

    } else if (this.appuser.role == "client") {
      this.user = "CLIENT"

    } else if (this.appuser.role == "broker-user") {
      this.user = "BROKER USER"

    } else if (this.appuser.role == "broker-admin") {
      this.user = "BROKER ADMIN"

    } else if (this.appuser.role == "staff") {
      this.user = "STAFF"

    }
    else {
      this.user = ""
    }

    var str_count = localStorage.getItem("tabStatusFirst");
    var str_count2 = localStorage.getItem("tabStatusSecond");

    //console.log ( "one " + str_count)
    //console.log ("two" + str_count2)


    var str_countInt = parseInt(str_count);
    var str_countInt2 = parseInt(str_count2);

    // console.log ( "one " + str_countInt )
    //console.log(  "two" + str_countInt2 )

    if ((str_count == null) && (str_count2 == null)) {

      this.selectedIndex = 0;
      //console.log ( "first" )
    }
    else if ((str_countInt != 5) && (str_countInt2 == 5)) {

      this.selectedIndex = str_countInt;
      //console.log (  "second")
    }
    else {
      this.selectedNumber = str_countInt2;
      //console.log ( "third")
    }
  }


  routefn(routerLink: any) {
    this.router.navigate([routerLink])
  }

  select(index: number, routerLink: any) {

    if (['/commission'].includes(routerLink))
      return;
    this.selectedIndex = index;

    this.router.navigate([routerLink])
    this.selectedNumber = null
    localStorage.setItem("tabStatusFirst", this.selectedIndex.toString());
    localStorage.setItem("tabStatusSecond", "5");
  }

  selectNotif(index: number, routerLink: any) {
    this.selectedIndex = null
    this.selectedNumber = index;

    //console.log('ahh'+ this.selectedNumber)

    this.router.navigate([routerLink])
    localStorage.setItem("tabStatusFirst", "5");
    localStorage.setItem("tabStatusSecond", this.selectedNumber.toString());

  }


}
