import { Component, OnInit,  Output, EventEmitter  } from '@angular/core';
import swal from 'sweetalert2';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material';
import { DashboardService } from '../service/dashboard.service';
import { ChangePinComponent } from '../dialogs/change-pin/change-pin.component';
import { setSecurityQuestionsComponent } from '../dialogs/set-security-questions/set-security-questions.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  appuser: any

  constructor(
    private router: Router,
    public dialog:MatDialog,
    public service: DashboardService
    ) 
    {
      this.appuser=this.service.currentUser();
      if(this.appuser){
        var account_activation_requested = localStorage.getItem('account_activation_requested')
        var reset_requested = localStorage.getItem('reset_requested')
      
        if (account_activation_requested == '1'){
          this.changePin()
        }else if(reset_requested == '1'){
          this.changePin()
        }

        console.log("broker_admin_pin_set ===> " + this.appuser.broker_admin_pin_set)

        // let broker_user_pin_set = this.appuser.broker_user_pin_set
        // let broker_admin_pin_set = this.appuser.broker_admin_pin_set
        // let broker_user_answers_set = this.appuser.broker_user_answers_set
        // let broker_admin_answers_set = this.appuser.broker_admin_answers_set 

        // if(this.appuser.broker_user_pin_set == null){
        //   broker_user_pin_set = '0'
        // }

        // if(this.appuser.broker_admin_pin_set == null){
        //   broker_admin_pin_set = '0'
        // }

        // if(this.appuser.broker_user_answers_set == null){
        //   broker_user_answers_set = '0'
        // }

        // if(this.appuser.broker_admin_answers_set == null){
        //   broker_admin_answers_set = '0'
        // }


        // if(this.appuser.role == 'broker-user'){
        //   if(broker_user_pin_set == '0'){
        //     this.changePin()
  
        //   }else if(broker_user_answers_set == '0'){
        //     this.openSetSecurityQuestionsFormDialog()
  
        //   }
        // }else{

        
        //   if(broker_admin_pin_set == '0' ){
        //     console.log("appuser.role ===> " + broker_admin_pin_set)

        //     this.changePin()
  
        //   }else if(broker_admin_answers_set == '0'){
        //     this.openSetSecurityQuestionsFormDialog()
  
        //   }

        // }
    
    
       
      } else {
        window.localStorage.clear();
        this.router.navigate(['']);
      }

     
    }


  ngOnInit() {}


  logout(){

    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'logout ?',
      text: "You are logging out...",
      showCancelButton: true,
      confirmButtonText: 'Yes Confirm',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        // swalWithBootstrapButtons.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )


    window.localStorage.clear();
    this.router.navigate(['']);

      } else (
        /* Read more about handling dismissals below */
        result.dismiss === swal.DismissReason.cancel
      )

    })
}

userprofile(){
  // router here.
}



changePin(){
  const dialogRef = this.dialog.open(ChangePinComponent, {
    disableClose :true,
    width: '300px',
    data: { }
  });

  dialogRef.afterClosed().subscribe(result => {

  });
}


openSetSecurityQuestionsFormDialog() {
  const dialogRef = this.dialog.open(setSecurityQuestionsComponent, {
    disableClose: true,
    width: '600px',
    data: {}
  });

  dialogRef.afterClosed().subscribe(result => {
  });
}




}
