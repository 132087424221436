export let PRIVILEDGES = [

  //client level
  { role: 'client', name: 'Client Info', routerLink: "/profile", icon: 'dashboard' },
  { role: 'client', name: 'Purchase Policy', routerLink: '/products', icon: 'purchasepolicy' },
  { role: 'client', name: 'Renew Policy', routerLink: '/renewpolicy', icon: 'renewpolicy' },
  { role: 'client', name: 'My Quotes', routerLink: '/quotes', icon: 'quote' },
  { role: 'client', name: 'My Policies', routerLink: '/mypolicies', icon: 'mypolicies' },
  { role: 'client', name: 'My Documents', routerLink: '/policydocuments', icon: 'icons8-print-100' },
  { role: 'client', name: 'Transactions', routerLink: '/transactions', icon: 'soldstickers' },


  //Agent level
  { role: 'agent', name: 'Dashboard', routerLink: "/dashboard", icon: 'dashboard' },
  { role: 'agent', name: 'Purchase Policy', routerLink: '/products', icon: 'purchasepolicy' },
  { role: 'agent', name: 'Renew Policy', routerLink: '/renewpolicy', icon: 'renewpolicy' },
  { role: 'agent', name: 'My Quotes', routerLink: '/quotes', icon: 'quote' },
  { role: 'agent', name: 'My Policies', routerLink: '/mypolicies', icon: 'mypolicies' },
  { role: 'agent', name: 'My Documents', routerLink: '/policydocuments', icon: 'icons8-print-100' },
  { role: 'agent', name: 'Transactions', routerLink: '/transactions', icon: 'soldstickers' },
  { role: 'agent', name: 'Report', routerLink: '/report', icon: 'quote' },


  //Broker user level
  { role: 'broker-user', name: 'Dashboard', routerLink: "/dashboard", icon: 'dashboard' },
  { role: 'broker-user', name: 'Purchase Policy', routerLink: '/products', icon: 'purchasepolicy' },
  { role: 'broker-user', name: 'Renew Policy', routerLink: '/renewpolicy', icon: 'renewpolicy' },
  { role: 'broker-user', name: 'My Quotes', routerLink: '/quotes', icon: 'quote' },
  { role: 'broker-user', name: 'My Policies', routerLink: '/mypolicies', icon: 'mypolicies' },
  { role: 'broker-user', name: 'My Documents', routerLink: '/policydocuments', icon: 'icons8-print-100' },
  { role: 'broker-user', name: 'Transactions', routerLink: '/transactions', icon: 'soldstickers' },
  { role: 'broker-user', name: 'Report', routerLink: '/report', icon: 'quote' },


  //Broker admin level
  { role: 'broker-admin', name: 'Dashboard', routerLink: "/dashboard", icon: 'dashboard' },
  { role: 'broker-admin', name: 'My Quotes', routerLink: '/quotes', icon: 'quote' },
  { role: 'broker-admin', name: 'My Policies', routerLink: '/mypolicies', icon: 'mypolicies' },
  { role: 'broker-admin', name: 'My Documents', routerLink: '/policydocuments', icon: 'icons8-print-100' },
  { role: 'broker-admin', name: 'Transactions', routerLink: '/transactions', icon: 'soldstickers' },
  { role: 'broker-admin', name: 'Report', routerLink: '/report', icon: 'quote' },


  //Staff level
  { role: 'staff', name: 'Dashboard', routerLink: "/dashboard", icon: 'dashboard' },
  { role: 'staff', name: 'Purchase Policy', routerLink: '/products', icon: 'purchasepolicy' },
  { role: 'staff', name: 'Renew Policy', routerLink: '/renewpolicy', icon: 'renewpolicy' },
  { role: 'staff', name: 'My Quotes', routerLink: '/quotes', icon: 'quote' },
  { role: 'staff', name: 'My Policies', routerLink: '/mypolicies', icon: 'mypolicies' },
  { role: 'staff', name: 'My Documents', routerLink: '/policydocuments', icon: 'icons8-print-100' },
  { role: 'staff', name: 'Transactions', routerLink: '/transactions', icon: 'soldstickers' },
  { role: 'staff', name: 'Report', routerLink: '/report', icon: 'quote' },




]

//for bottom notifications and messages
export let PRIVILEDGESNEW = [

  { role: 'client', name: 'Notifications', routerLink: "/notifications", icon: 'notification' },
  { role: 'agent', name: 'Notifications', routerLink: "/notifications", icon: 'notification' },
  { role: 'broker-user', name: 'Notifications', routerLink: "/notifications", icon: 'notification' },
  { role: 'broker-admin', name: 'Notifications', routerLink: "/notifications", icon: 'notification' },
  { role: 'staff', name: 'Notifications', routerLink: "/notifications", icon: 'notification' },


]

