import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import Swal from 'sweetalert2';
import { DashboardService } from '../../service/dashboard.service';

interface Method{
  value: string;
  icon: string;
  viewValue: string;
}

@Component({
  selector: 'select-payment-platform',
  templateUrl: './select-payment-platform.component.html',
  styleUrls: ['./select-payment-platform.component.scss']
})
export class SelectPaymentPlatformComponent implements OnInit {

  method: string=null;
    methodData: Method[]= 
    [
      {
        value:'Mobile',
        icon:'assets/img/payment/2mdpi.png',
        viewValue:"Mobile Money"
      },
      {
        value:'Card',
        icon:'assets/img/payment/debit_card.png',
        viewValue:"Debit Card"
      }
    ];

    quotePayload:any;
    total_premium:0;
    appUser: any;
    allowCash: any;

  
  constructor(public service: DashboardService, public dialogRef: MatDialogRef<SelectPaymentPlatformComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.method = data.method;
      this.quotePayload = data.quotePayload;
      this.total_premium=data.total_premium;

      this.appUser=this.service.currentUser();
      this.allowCash = localStorage.getItem('allow_cash')

      console.log("role ->>> "  + this.appUser.role)
      console.log("allowCash ->>> "  + this.allowCash)

      if(this.appUser.role=='broker-admin' || this.appUser.role=='broker-user' || this.allowCash == 'true'){
        this.methodData=[
          ...this.methodData,
          {
            value:'Cheque',
            icon:'assets/img/payment/3mdpi.png',
            viewValue:"Cheque"
          },
          {
            value:'Cash',
            icon:'assets/img/payment/4mdpi.png',
            viewValue:"Cash"
          }
        ];
      }
      
    }

  
    

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {method: this.method,total_premium: this.total_premium} });
  }
}
