import { Component, LOCALE_ID, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { formatNumber } from '@angular/common';

interface Bank{
  id:number,
  bank_name:string
}

interface BankResponse{
    responseCode: string,
    responseMessage:string,
    code:string,
    data:string,
    account_activation_requested:string,
    reset_requested:string,
    agent_pin_set:string,
    profile : Bank[]
}

@Component({
  selector: 'app-cheque-payment',
  templateUrl: './cheque-payment.component.html',
  styleUrls: ['./cheque-payment.component.scss']
})
export class ChequePaymentComponent implements OnInit {
  appuser: any;
  responseCode: string;
  responseMessage: string;

  payload: any={
    agent_code:"",
    module:"agent",
    policy_sequence:"",
    transaction_type:"Cheque",
    cheque_no:"",
    bank_name:"",
    drawer_name:"",
    status_of_client:"new",
    policy_lob: "",
    platform:'webapp'
  };

  constructor(
    public service: DashboardService, 
    public dialogRef: MatDialogRef<ChequePaymentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private _snackBar: MatSnackBar,
    @Inject(LOCALE_ID) public locale: string
    ) {
      this.appuser = this.service.currentUser();

      this.quotePayload = data.quotePayload;

      this.total_premium =data.total_premium;
      this.formatted_premium=`${'GHS'} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;

      // this.formatted_premium=`${this.quotePayload.currency_code} ${formatNumber(data.total_premium, this.locale, '1.2-2')}`;


      if(this.appuser.role=='agent' || this.appuser.role=='broker-admin'){
        this.payload={
          ...this.payload,
          module:'agent',
          agent_code:this.quotePayload.agent_code,
          broker_user_code:'',
          policy_sequence:this.quotePayload.policy_sequence,
          status_of_client:this.quotePayload.status_of_client,
          policy_lob: data.policy_lob
        }
      }else if(this.appuser.role=='broker-user'){
        this.payload={
          ...this.payload,
          module:'broker-user',
          agent_code:this.appuser.broker_user_main_broker_code,
          broker_user_code:this.appuser.code,
          policy_sequence:this.quotePayload.policy_sequence,
          status_of_client: this.quotePayload.status_of_client,
          policy_lob: data.policy_lob
        }
      }else if (this.appuser.role=='staff'){

        if(data.policy_lob == '20'){
          this.payload={
            ...this.payload,
            module:'staff',
            staff: {
              staff_id:this.appuser.code
            },
            policy_sequence:this.quotePayload.policy_sequence,
            status_of_client: this.quotePayload.status_of_client,
            policy_lob:data.policy_lob
          }
        }else{
          this.payload={
            ...this.payload,
            module:'staff',
            staff_id:this.appuser.code,
            policy_sequence:this.quotePayload.policy_sequence,
            status_of_client: this.quotePayload.status_of_client,
            policy_lob:data.policy_lob
          }
        }
       
      }


      //list banks
      this.onFetchBanks();
    }

    method: string;

    quotePayload:any;
    total_premium: string="0";
    formatted_premium: string="";

    bankData: Bank[];
    errors={
        bank_name:false,
        cheque_no:false,
        drawer_name:false
    };



  ngOnInit() {}

  onSubmit=()=>{
    if(!this.payload.bank_name)
    this.errors.bank_name=true;
    else
    this.errors.bank_name=false;


    if(!this.payload.cheque_no)
    this.errors.cheque_no=true;
    else if(isNaN(+`${this.payload.cheque_no}`)){
      this.errors.cheque_no=true;
      this.alertSnackBar('cheque number must be a number','Note')
    }
    else if(this.payload.cheque_no.length<5)
    {
      this.errors.cheque_no=true;
      this.alertSnackBar('cheque number cannot be less than 5 characters','Note')
    }
    else if(this.payload.cheque_no.length>50)
    {
      this.errors.cheque_no=true;
      this.alertSnackBar('cheque number cannot be greater than 50 characters','Note')
    }
    else
    this.errors.cheque_no=false;


    if(!this.payload.drawer_name)
    this.errors.drawer_name=true;
    else
    this.errors.drawer_name=false;


    if(!this.payload.bank_name || !this.payload.cheque_no || !this.payload.drawer_name)
    this.service.swalAlart.fire({
      title: 'Validation Alert',
      text: 'Kindly complete the highlighted fields',
      icon: 'warning',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    })
    else{
      this.service.swalAlart.showLoading();
      this.service.subPayment(this.payload)
      .toPromise()
      .then((response: any)=>{
        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage
        
        if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
          this.quotePayload.module="PAID";
          this.service.swalAlart.close();

         console.log("policy_lob===> " + this.payload.policy_lob);
          
          if(this.payload.policy_lob === "20"){
            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.stickerUrl}'>Click here to print sticker now</a><br><br><a target='_blank' href='${response.certificateUrl}'>Click here to print certificate now</a>`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close({ event: 'close', data: {quotePayload:this.quotePayload,total_premium: this.total_premium} });
              }
               
             });

          }else if (this.payload.policy_lob === "50"){
              this.service.swalAlart.fire({
                title: 'Payment successful',
                html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a><br><br><a target='_blank' href='${response.travelDocUrl}'>Click here to print <b>travel documents</b></a>`,
                icon: 'success',
                confirmButtonText:'Done',
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((result)=>{
                if(result){
                  this.router.navigate(['transactions']);
                  this.dialogRef.close({ event: 'close', data: {quotePayload:this.quotePayload,total_premium: this.total_premium} });
                }
                 
               });


              }else{

                this.service.swalAlart.fire({
                  title: 'Payment successful',
                  html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a>`,
                  icon: 'success',
                  confirmButtonText:'Done',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then((result)=>{
                  if(result){
                    this.router.navigate(['transactions']);
                    this.dialogRef.close({ event: 'close', data: {quotePayload:this.quotePayload,total_premium: this.total_premium} });
                  }
                   
                 });
    
              }
        
        }else if(response.hasOwnProperty('responseCode') && response.responseCode=='001'){
          this.quotePayload.module="PAID";
          this.service.swalAlart.close();
          this.service.swalAlart.fire({
            title: 'Processing Payment...',
            html: 'Please wait. An sms and email of policy and sticker details will be sent to you after processing payment.',
            icon: 'success',
            confirmButtonText:'Done',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result)=>{
            if(result){
              this.router.navigate(['transactions']);
              this.dialogRef.close({ event: 'close', data: {quotePayload:this.quotePayload,total_premium: this.total_premium} });
            }
           });
        }
        else if(response.hasOwnProperty('responseMessage') && response.responseCode=='E31'){
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Vehicle information already exist under a policy. Please check vehicle registration number and try again.',
            icon: 'error',
          });
        }else if(response.hasOwnProperty('responseMessage') && response.responseCode=='E23'){
          this.service.swalAlart.fire({
            title: 'Failed',
            text: 'Invoice Already Paid',
            icon: 'error',
          });

        }else if(response.hasOwnProperty('responseMessage') && response.responseCode=='E01'){
            this.service.swalAlart.fire({
              title: 'Failed',
              text: this.responseMessage,
              icon: 'error',
            });
  
        }else if(response.hasOwnProperty('responseMessage') && response.responseCode=='E64'){
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
        
         }else {
          let errors=response.hasOwnProperty('responseMessage') && response.responseCode=='400 BAD_REQUEST'?JSON.parse(response.responseMessage):null;

          this.service.swalAlart.fire({
            title: 'Failed',
            text:errors && errors.errors.length>0?`MID::${errors.errors[0]}`: 'Payment failed!',
            icon: 'error',
          });
        }
      }).catch((error: any)=>{
       // console.log('error',error)
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Failed',
          text: 'Kindly check connection and retry!',
          icon: 'error',
        });
      })

    }
 }




  onInputChange($event=null){
    if(!this.payload.bank_name)
    this.errors.bank_name=true;
    else
    this.errors.bank_name=false;


    if(!this.payload.cheque_no)
    this.errors.cheque_no=true;
    else if(isNaN(+`${this.payload.cheque_no}`)){
      this.errors.cheque_no=true;
      this.alertSnackBar('cheque number must be a number','Note')
    }
    else if(this.payload.cheque_no.length<5)
    {
      this.errors.cheque_no=true;
      this.alertSnackBar('cheque number cannot be less than 5 characters','Note')
    }

    else if(this.payload.cheque_no.length>50)
    {
      this.errors.cheque_no=true;
      this.alertSnackBar('cheque number cannot be greater than 50 characters','Note')
    }
    else
    this.errors.cheque_no=false;


    if(!this.payload.drawer_name)
    this.errors.drawer_name=true;
    else
    this.errors.drawer_name=false;
  }


  onFetchBanks=()=>{
     this.service.getBanksList()
     .toPromise()
     .then((response:BankResponse)=>{
           if(response.responseCode=='000'){
               this.bankData=response.profile;
           }

          // console.log('banks',this.bankData)
     }).catch(error=>console.log('Banks fetch error'))
  }








 alertSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {
    duration: 2000,
  });
  }

}
