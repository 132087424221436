import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/modules/service/dashboard.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-widget-dlycommission',
  templateUrl: './dlycommission.component.html',
  styleUrls: ['./dlycommission.component.scss']
})
export class DlyCommissionComponent implements OnInit {
  falseAuth:boolean = true;

  total:any;
  transact:any;
  pending:any;
  transacttoday:any;
  policytoday:any;
  renewpolicy:any;
  stickersoldtoday:any;



  @ViewChild('element') element: ElementRef;
  private options = {
    content: 'This is a basic popup'
  }


  loading:boolean = false
  userdata :any //get users email
  loaderror:any = ''

  config = {
    content: 'This is a basic Popup'
  }

  constructor( private router:Router, private service:DashboardService) {
     this.userdata=this.service.currentUser();
  }

  ngOnInit() {

    this.loading = true
    let payload: any={}

    if(this.userdata.role==='client'){
      payload={
        role:'client',
        client_code:this.userdata.code
      }
    }else if(this.userdata.role==='agent' || this.userdata.role==='broker-admin' ){
      payload={
        role:'agent',
        agent_code:this.userdata.code
      }
    }else if(this.userdata.role==='broker-user'){
      payload={
        role:'broker-user',
        broker_user_code:this.userdata.code
      }
  
   
    }else if(this.userdata.role==='staff'){
      payload={
        role:'staff',
        staff_id: this.userdata.code
      }
   
    }
    else{}
 
      this.service.getAnaytics(payload).subscribe(data=>{
      this.loading = false
  //this.total = parseFloat(data['total_transactions'][0]['totalAmount'].replace(/,/g,''));
  //this.transacttoday = parseFloat(data['today_transaction'][0]['totalAmount'].replace(/,/g,''));
  //this.policytoday = data['today_policy'][0]['totalCount'];
  //this.renewpolicy  = data['today_policiesRenewals'][0]['totalCount'];
  //this.stickersoldtoday = data['today_sticker'][0]['totalCount'];
  //console.log(this.total, this.transact);

      }, error => {
        this.loading = false
        this.loaderror = 'Check your Internet!'
        swal.fire({
          title: 'Oops...',
          text: error.name,
          footer: ''
        });
      });


    }


    over(){
console.log("hover")




}






}
