import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { CashPaymentComponent } from '../modules/dialogs/cash-payment/cash-payment.component';
import { ChequePaymentComponent } from '../modules/dialogs/cheque-payment/cheque-payment.component';
import { DebitCardPaymentComponent } from '../modules/dialogs/debit-card-payment/debit-card-payment.component';
import { QuoteSummaryComponent } from '../modules/dialogs/quote-summary/quote-summary.component';
import { SelectPaymentMethodComponent } from '../modules/dialogs/select-payment-method/select-payment-method.component';
import { SignupComponent } from '../modules/dialogs/signup/signup.component';
import { DashboardService } from '../modules/service/dashboard.service';
interface CustomerProfile{
  CLIENT_CODE: string;
  CLIENT_TYPE?: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?:boolean;
}

interface ConstructionTypeResponse{
  responseCode: string,
  responseMessage: string,
  code: string,
  data: string,
  account_activation_requested: string,
  reset_requested: string,
  agent_pin_set: string,
  profile: string,
  responseData: ConstructionType[]
}
interface ConstructionType{
  TABLED_NAME: string;
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
}
interface Item{
  name: string;
  value: string;
}

interface Term{
  value: string;
  viewValue: string;
  days: string;
}
interface CurrencyResponse{
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Currency[];
}
interface Currency{
  CURRENCY: string;
  DATE_UPDATED: string;
  CURRENCY_VALUE: number;
}

interface Product{
  PRODUCT_DESC: string;
  PRODUCT_CURRENCY: string;
  PRODUCT_CODE: string;
}

interface ProductResponse{
  responseCode: string,
  responseMessage: string,
  code: null,
  data: null,
  account_activation_requested: null,
  reset_requested: null,
  agent_pin_set: null,
  profile:Array<Product>
}
@Component({
  selector: 'app-quick-fire-quote',
  templateUrl: './quick-fire-quote.component.html',
  styleUrls: ['./quick-fire-quote.component.scss']
})
export class QuickFireQuoteComponent implements OnInit {

  errors={}
  selectedMethod:string;

  //is processing
  processing=false;
  quote: any={
    days_label:"Day(s)",
    days: "365",
    start_date:new Date(),
    end_date:'',
    term:'01',
    loss_of_rent_value:'0',
    public_liability:'',
    construction_type:'',
    content_sum:'',
    building_sum:'',
    currency_code:'GHC',
    exchange_rate:'1'
  }

  terms: Array<Term>=[
    {value: '01', viewValue: 'One Year(365)', days: '365'},
    {value: '02', viewValue: 'Short Term', days: '1'},
    //{value: '03', viewValue: 'Prorata Term', days: '1'}
  ];

  //list of products
  products: Array<Product>=[]

  buildItems: Array<Item> = [];
  contentItems: Array<Item> = [];
  currencies: Array<Currency>=[];


  item_name: string="";
  item_value: string="";


  selectedProduct: Product;

  //list of construction types
  constructionTypes: ConstructionType[];
  selectedConstructionType: ConstructionType;

  //form step
  formStep=1;


  //errors

  //payload for submitting quote
  payload: any={};

  //flags
  is_person_accident:Boolean=false
  is_loss_of_rent:Boolean=false
  is_public_liability:Boolean=false

  constructor(private service: DashboardService,private _snackBar:MatSnackBar,
    public dialog: MatDialog,private router:Router,
    public quickFireDialogRef: MatDialogRef<QuickFireQuoteComponent>
    ) { }

  ngOnInit() {

    this.onLoadProducts()
    this.showCurrencies()
    this.onLoadConstructionTypes()


    this.buildItems.push({name:'',value:''});
    this.contentItems.push({name:'',value:''});

    this.quote.term='01';
  }

  //load currencies
  showCurrencies(){
    this.service.getCurrencies()
    .toPromise()
    .then((response :CurrencyResponse)=>{
      if(response.responseCode=='000'){
         response.responseData.map((currency :Currency)=>{
             this.currencies.push(currency);
         })
      }
    }).catch(err =>{
           console.log('Currencies load error',err);
      });
  }
  onCurrencySelected(code: string){
    let selectedCurrency= this.currencies.filter((currency: Currency)=>currency.CURRENCY==code);

    if(selectedCurrency.length){
      this.quote.exchange_rate=selectedCurrency[0].CURRENCY_VALUE.toString();
      this.quote.currency_code=code;
    }
  }
  onLoadProducts(){
    this.products=[];
    this.service.getProducts({policy_lob:'10'})
    .toPromise()
    .then((response: ProductResponse)=>{
        if(response.responseCode=='000'){
          this.products=response.profile
          this.service.swalAlart.close();
        }
    })
    .catch((error: any)=>{
      this.service.swalAlart.close();
      console.log('Error',error);
    })

  }
  onLoadConstructionTypes(){
    this.products=[];
    this.service.getConstructionTypes()
    .toPromise()
    .then((response: ConstructionTypeResponse)=>{
        if(response.responseCode=='000'){
          this.constructionTypes=response.responseData;
          //console.log('construction types',this.constructionTypes);
          this.service.swalAlart.close();
        }
    })
    .catch((error: any)=>{
      this.service.swalAlart.close();
      console.log('Error',error);
    })

  }

  //additional cover changed
  onlossOfRentChanged(changed: boolean){
     this.is_loss_of_rent=changed;
  }
  onPersonalAccidentChanged(changed: boolean){
     this.is_person_accident=changed;
  }
  onPublicLiabilityChanged(changed: boolean){
     this.is_public_liability=changed;
  }
  onCoverSelected(product_code: string){
    this.selectedProduct=this.products.find((cover: Product)=>cover.PRODUCT_CODE==product_code);
    if(product_code=='108')
    this.buildItems=[];
    else if(this.buildItems.length==0){
      this.quote.building_sum='';
      this.buildItems.push({value:'',name:''})
    }
  }

  onConstructionTypeSelected(construction_type: string){
    this.selectedConstructionType=this.constructionTypes.find((construction: ConstructionType)=>construction.TABLED_CODE==construction_type);
  }

  //set the dob start date
  dobStartAt=new Date(moment(moment().format('YYYY-MM-DD')).subtract(18,'years').format('YYYY-MM-DD'))

   //trigger the snackbar
  //trigger the snackbar
  alertSnackBar(message: string, action: string,duration:number=5000) {
    this._snackBar.open(message, action, {
      duration: duration,
    });
  }

  //run validation
  validateForm(input? :any) :boolean{
    let hasError=false;
    this.errors={
      customer_code:false,
      customer_phone:false,
      date_of_birth:false,

      cover_type_code:false,
      schedule_code:false,
      start_date:false,
      end_date:false,

      currency_code:false,
      customer_email:false,
      loss_of_rent:false
    }


    if(this.formStep==1){
      if((isNaN(+`${this.quote.customer_phone}`) || !this.service.validatePhone(this.quote.customer_phone))){
        hasError=true;
        this.errors={...this.errors,customer_phone:true};
      }

      if(this.quote.customer_email && !this.service.validateEmail(this.quote.customer_email)){
        hasError=true;
        this.errors={...this.errors,customer_email:true};
      }

      if(!(moment(this.quote.date_of_birth).isValid() && moment(moment().format('YYYY-MM-DD')).diff(moment(this.quote.date_of_birth).format('YYYY-MM-DD'),'years')>=18)){
        hasError=true;
        this.errors={...this.errors,date_of_birth:true};

         this.alertSnackBar(`Date of birth must be greater or equal to:${moment(this.dobStartAt).format('LL')}`,'Invalid Date');
      }
    }
    else if(this.formStep==2){
        if(!this.quote.cover_type_code)
        {
          hasError=true;
          this.errors={...this.errors,cover_type_code:true};
        }
        if(!this.quote.currency_code){
          hasError=true;
          this.errors={...this.errors,cover_type_code:true};
        }
        if(!this.quote.location){
          hasError=true;
          this.errors={...this.errors,location:true};
        }
        if(!this.quote.construction_type){
          hasError=true;
          this.errors={...this.errors,construction_type:true};
        }
    }
    else if(this.formStep==3){
        if(!this.quote.property_description){
          hasError=true;
          this.errors={...this.errors,property_description:true};
        }
        if(!this.quote.term){
          hasError=true;
          this.errors={...this.errors,term:true};
        }
        if(!this.quote.days){
          hasError=true;
          this.errors={...this.errors,days:true};
        }
        if(!this.quote.start_date){
          hasError=true;
          this.errors={...this.errors,start_date:true};
        }
        if(!this.quote.end_date){
          hasError=true;
          this.errors={...this.errors,end_date:true};
        }

        if((this.quote.cover_type_code=='101' || this.quote.cover_type_code=='102') &&  !this.quote.building_sum){
          hasError=true;
          this.errors={...this.errors,building_sum:true};
        }
        else if(this.quote.content_sum && this.contentTotal && this.contentTotal.toString()!=this.quote.content_sum)
        {
          hasError=true;
          this.errors={...this.errors,content_sum:true,content_items:true};

          //console.log('content errors',{contentTotal:this.contentTotal,content_sum:this.quote.content_sum})

          this.alertSnackBar("Total sum of content items should be equal to the sum insured.","Content Items Validation");
        }

        if(['108','102'].includes(this.quote.cover_type_code) &&  !this.quote.content_sum){
          hasError=true;
          this.errors={...this.errors,content_sum:true};
        }
        else if(this.quote.building_sum && this.buildingTotal && this.buildingTotal.toString()!=this.quote.building_sum)
        {
          hasError=true;
          this.errors={...this.errors,building_sum:true,building_items:true};

          this.alertSnackBar("Total Building Items Total must be equal to Building Sum Insured.","Building Items Validation");
        }
    }
    else if(this.formStep==4){
      if((this.quote.cover_type_code=='101' || this.quote.cover_type_code=='102') && this.quote.lost_of_rent && this.quote.building_sum && parseFloat(this.quote.lost_of_rent)>(0.1*parseFloat(this.quote.building_sum))){
        this.errors={...this.errors,building_sum:true,loss_of_rent:true};
        this.alertSnackBar(`Value for Loss of Rent cannot be more than 10% of the building value of ${this.quote.building_sum}`,"Lost of Rent Validation");
      }
    }

    //console.log('Error',hasError)
    return hasError;
  }
//on term selection
onDurationSelected(){
  //Get the associated days to the term
  let durations= this.terms.filter((duration: Term)=>duration.value==this.quote.term);

  if(durations.length>0)
  this.quote.days=durations[0].days;

  //recalculate the end date
  if(!isNaN(+`${this.quote.days}`) && this.quote.start_date)
    this.quote.end_date=moment(this.quote.start_date).subtract((this.quote.term=='02'?1:0),'days').add(parseInt(this.quote.days)>=364?364:parseInt(this.quote.days),(this.quote.term=='02'?'months':'days')).format('YYYY-MM-DD');

  //change the days label to months when the term changes to short term
  if(this.quote.term=='02'){
    this.quote.days_label='Months';
    let months=Math.round(Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'),'days'))/31);
    this.quote._months=this.quote.days=months?months:1;

    let days=Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'),'days'));
    this.quote._days=days?days:1;
  }
  else{
    this.quote.days_label='Days';
    let days=this.quote.days=Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'),'days'));
    this.quote._days=days?days:1;
    this.quote._months='';
  }
  if(this.quote.term=='01' && this.quote.days!='' && parseInt(this.quote.days)>=364)
  this.quote.days=365;
}
  //on end date change
  onEndDateChanged(date: Date){
    let start_date= this.quote.start_date?moment(this.quote.start_date).format('YYYY-MM-DD'):null;

    let days=0,months=0;
    if(start_date){
     if(this.quote.term=='02')
     {
       months=Math.round(moment(moment(date).format('YYYY-MM-DD')).add(1,'days').diff(start_date,'days')/31);
       this.quote.days=this.quote._months=this.quote._months=months;

       //if(this.quote.term=='02' && months==0){
        //this.quote.term='02';
        //this.quote.days_label="Days"
       //}
     }
      if(this.quote.term!='02')
     {
        days=moment(moment(date).format('YYYY-MM-DD')).diff(start_date,'days');
        this.quote.days=days.toString();
     }
    }

   if(days && days<365 && this.quote.term=='01'){
     months=moment(moment(date).format('YYYY-MM-DD')).diff(start_date,'months');

     if(months){
       this.quote.days_label="Months";
       this.quote.term='02';
       this.quote.days=months;
     }
     //else{
       //this.quote.days_label="Days";
       //this.quote.term='03';
     //}
   }
   else if(days==365)
   this.quote.term='01';

  }
  //on building item value typed
  contentTotal: number=0;
  onContentItem(index: number,$event){
   this.contentTotal=0;
    let content_sum=this.quote.content_sum?parseFloat(this.quote.content_sum):0;
    for(let i=0;i<this.contentItems.length; i++)
    this.contentTotal+=this.contentItems[i].value?parseFloat(this.contentItems[i].value):0;

    if( this.contentTotal>content_sum)
    this.contentItems[this.contentItems.length-1].value='';
  }

  //on building item value typed
  buildingTotal: number=0;
  onBuildingItem(index: number,$event){
    this.buildingTotal=0;
    let building_sum=this.quote.building_sum?parseFloat(this.quote.building_sum):0;
    for(let i=0;i<this.buildItems.length; i++)
    this.buildingTotal+=this.buildItems[i].value?parseFloat(this.buildItems[i].value):0;

    if(this.buildingTotal>building_sum)
    this.buildItems[this.buildItems.length-1].value='';
  }
  dobFilter(date: Date){
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'),'years')>=18;
  };
  //on proceed
  // on proceed
  onProceed(){
    if(this.validateForm())
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
       else if(this.formStep==4)
        this.onSubmit();
      else
         this.formStep++;
  }

  onPrevious(){
    if(this.formStep>1)
    this.formStep--;
  }

  renewal:boolean=false;
  //On Form submit
  onSubmit=()=>{

    let validationResponse=this.validateForm();

    if(validationResponse)
    this.service.swalAlart.fire({
      title: 'Validation Alert',
      text: 'Kindly complete the highlighted fields',
      icon: 'warning',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    })
    else{
      this.service.swalAlart.fire({
        title:"Do you want to proceed with quote?",
        icon:"question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons:true
      })
      .then((dialogResponse)=>{
        if(dialogResponse.hasOwnProperty('value') && dialogResponse.value==true) {
          //start of processing
          this.processing=true;
          let prepared_by=`${this.quote.customer_first_name} ${this.quote.customer_last_name}`.trim();

          this.payload={
            customer_first_name: this.quote.customer_first_name,
            customer_last_name: this.quote.customer_last_name,
            customer_other_names: this.quote.customer_other_names,
            customer_email: this.quote.customer_email,
            customer_phone: this.quote.customer_phone,
            prepared_by: prepared_by,
            status:'incomplete',
            module:'client',
            status_of_client:(this.renewal?'renew':'new'),
            customer_date_of_birth:moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
            customer_branch_code:null,
            customer_type_code:this.quote.customer_type_code,
            policy_lob: "10",
            platform: "Webapp",
            product:this.quote.cover_type_code,
            policy_agency:'502',
            policy_office:'502',
            fire_gen_desc: this.quote.property_description
          }


            this.payload={
              ...this.payload,
              agent_code:'',
              client_code:null,
              customer_first_name:this.quote.customer_first_name,
              customer_last_name:this.quote.customer_last_name,
              customer_phone: this.quote.customer_phone,
              customer_email:this.quote.customer_email,
              date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY')
            }


          this.service.swalAlart.showLoading();

          if(!this.renewal && this.quote.edit_mode)
          this.finalizeQuote(this.quote.policy_no)
          else
          this.service.submitFireQuote(this.payload)
          .toPromise()
          .then((response: any)=>{
            if(response.responseCode=='000'){
              this.service.swalAlart.close();
              this.finalizeQuote(response.responseData)
            }
            else if(response.responseCode=='E56')
            this.service.swalAlart.fire({
              title: 'Error',
              text:  "Sorry, we couldn't find any branch associated with this account. Kindly contact your branch manager.",
              icon: 'error',
            });
            else if(response.responseCode=='E57')
                this.service.swalAlart.fire({
                  title: 'Error',
                  text:  "Sorry, you cannot renew policy at this branch,kindly contact your branch office",
                  icon: 'error',
                });
          }).catch(error=>{
            this.processing=false;
            this.service.swalAlart.close();
            this.service.swalAlart.fire({
              title: 'Error',
              text: 'Server Error,retry later',
              icon: 'error',
            });
          })
        }
      })

    }
  }
//building array

buildingArray=()=>{
  let items=[];
  for(let i=0;i<this.buildItems.length;i++){
     if(this.buildItems[i].name && this.buildItems[i].value)
     items.push({item_no:(i+1),item_desc: this.buildItems[i].name,item_si:this.buildItems[i].value})
  }
  return items;
}
//content array
contentArray=()=>{
  let items=[];
  for(let i=0;i<this.contentItems.length;i++){
     if(this.contentItems[i].name && this.contentItems[i].value)
     items.push({item_no:(i+1),item_desc: this.contentItems[i].name,item_si:this.contentItems[i].value})
  }
  return items;
}
  finalizeQuote=(policy_no)=>{
    this.payload={
      prepared_by:this.payload.prepared_by,
      policy_sequence:policy_no,
      module:'client',
      agent_code:this.payload.agent_code,
      client_code:this.payload.client_code,
      status_of_client:this.renewal?'renew':"new",
      currency_code:this.quote.currency_code,
      fleet:null,
      exchange_rate:parseFloat(this.quote.exchange_rate).toFixed(2).toString(),

      customer_first_name:this.quote.customer_first_name,
      customer_last_name:this.quote.customer_last_name,
      customer_other_names:"",

      customer_email:this.quote.customer_email,

      customer_phone:this.quote.customer_phone,
      customer_date_of_birth:moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
      customer_branch_code:null,
      customer_type_code:this.quote.customer_type_code,

      inception_date:moment(this.quote.start_date).format('DD/MM/YYYY'),
      expiry_date:moment(this.quote.end_date).format('DD/MM/YYYY'),

      product:this.quote.cover_type_code,

      policy_lob: "10",

      building_sum_insured:(this.quote.cover_type_code=='101'||this.quote.cover_type_code=='102') && this.quote.building_sum?this.quote.building_sum.toString():0,
      content_sum_insured:(this.quote.cover_type_code=='102'||this.quote.cover_type_code=='108') &&this.quote.content_sum?this.quote.content_sum.toString():0,
      loss_of_rent_value:this.quote.loss_of_rent?parseFloat(this.quote.loss_of_rent):0.00,

      excess_type_code:(this.quote.excess_buy_back?'EIB':'ENA'),
      tppd_limit:this.quote.tppd_value?this.quote.tppd_value:"0",
      legacy_policy_sequence:this.quote.legacy_policy_number,

      intermediary_first_name:this.quote.customer_first_name,
      intermediary_last_name:this.quote.customer_last_name,
      intermediary_other_names:"",
      intermediary_phone:this.quote.customer_phone,
      intermediary_email:this.quote.customer_email,

      platform: "Webapp",

      intermediary_type_code:"04",
      intermediary_branch_code:"502",


      policy_agency:'502',
      policy_office:'502',

      additional_pl: this.quote.public_liability?parseFloat(this.quote.public_liability):0.00,
      fire_general_description:this.quote.property_description,
      buildingArray: this.buildingTotal? this.buildingArray():[],
      contentArray: this.contentTotal? this.contentArray():[],
      fire_construct_type: this.selectedConstructionType.TABLED_DESIGNATION,
      fire_construct_code: this.selectedConstructionType.TABLED_CODE,
      fire_location: this.quote.location,
      fire_gen_desc: this.quote.property_description
     }

    //end lagacy values for renewals
     if(this.quote.days_label.toLowerCase()=='months'||this.quote.days_label.toLowerCase()=='month(s)')
     this.payload={
       ...this.payload,
       months:this.quote.days
     }
     else
     this.payload={
      ...this.payload,
      days:this.quote.days
    }


     if(this.quote.term=='01')
     this.payload.duration_type= 'annual';
     else if(this.quote.term=='02')
     this.payload.duration_type= 'shortrate';


     this.service.swalAlart.showLoading();
     this.service.submitFireQuote(this.payload)
     .toPromise()
     .then((response: any)=>{
         this.quote.total_premium=response.responseConvertedValue?response.responseConvertedValue.toString().replace(",","").trim():'0';
         this.quote.foreign_currency=response.responseCurrencyValue;
         this.quote.foreign_amount=response.responseData.toString().replace(",","").trim();

         this.confirmQuote();
         this.service.swalAlart.close();
         this.processing=false;
     }).
     catch(error=>{
      this.processing=false;
      this.service.swalAlart.close()
       this.service.swalAlart.fire({
         title: 'Error',
         text: 'Server Error,retry later',
         icon: 'error',
       });
     })
  }

   // is payment confirmed from the summary dialoged?
   confirmedQuote:boolean=false;
   //confirm quote
   confirmQuote(){
    let summary=[
      {label:'Date Prepared',value:moment().format('DD/MM/YYYY')},

      {label:'Prepared By',value:this.payload.prepared_by},

      {label:'Customer Name',value:`${this.payload.customer_first_name} ${this.payload.customer_last_name}`},

      {label:'Customer Email',value:this.payload.customer_email},


      {label:'Customer Code',value:this.payload.client_code},


      {label:'Mobile Number',value:this.quote.customer_phone},

      {label:'Product Plan',value:this.selectedProduct.PRODUCT_DESC},

      {label:'Location of Building/Proprty',value: this.payload.fire_location },

      {label:'Construction Type',value:this.payload.fire_construct_type},

      {label:'Duration',value:(this.payload.duration_type.toLowerCase()=='annual'?'One Year(365)':this.payload.duration_type)},

      {label:'Building Sum Insured',value:(this.payload.building_sum_insured?this.payload.building_sum_insured:0)},

      {label:'Content Sum Insured',value:this.payload.content_sum_insured?this.payload.content_sum_insured:0},

      {label:'Loss of Rent',value:this.payload.loss_of_rent_value},

      {label:'Public Liability',value:this.payload.additional_pl},

      {label:'Start Date',value:this.payload.inception_date},

      {label:'End Date',value:this.payload.expiry_date}
    ];

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose :true,
      width: '500px',
      data:  {
        quote:summary,
        confirmedQuote:true,
        total_premium: this.quote.total_premium,
        foreign_currency:this.quote.foreign_currency,
        foreign_amount:this.quote.foreign_amount,
        flow:'fire',
        hide_foreign_currency:0
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.hasOwnProperty('data')){
        this.confirmedQuote = result.data.confirmedQuote;

        //ask client to register in order to proceed
        this.service.swalAlart.fire({
          icon:"question",
          html:"To make payment for this policy,you need to create an account.<br/>Do you wish to proceed?",
          showCancelButton:true,
          showConfirmButton:true,
          confirmButtonText:"Yes",
          cancelButtonText:"NO",
          reverseButtons:true
        })
        .then((result: SweetAlertResult)=>{
            //require registration on payment
            if(result.value)
               this.openSignUpDialog();
        })

      }
    });
  }

  openSignUpDialog(){
    const dialogRef = this.dialog.open(SignupComponent, {
      disableClose :true,
      width: '860px',
      data: {
        customer_first_name:this.quote.customer_first_name,
        customer_last_name:this.quote.customer_last_name,
        customer_email:this.quote.customer_email,
        customer_phone:this.quote.customer_phone,
        //idNumber:this.quote.passport_no,
        //idType: 'Passport',
        via_quick_quote:true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Response Data1',result)

      if(result.hasOwnProperty('data') && result.data['client_code']){
        console.log('Response Data2',result.data)
        this.quote.customer_code=this.payload.client_code=result.data['client_code']
        this.quote.customer_email=this.payload.customer_email=result.data['customer_email']
        this.quote.customer_phone=this.payload.customer_phone=result.data['customer_phone']

          //update the quote with the generated client_code
          this.service.swalAlart.showLoading();
          this.service.submitFireQuote(this.payload)
          .toPromise()
          .then((response: any)=>{
              //create a client profile

              let client={
                CLIENT_FIRST_NAME:this.quote.customer_first_name,
                CLIENT_CODE:result.data['client_code'],
                CLIENT_FAMILY_NAME:this.quote.customer_last_name,
                CLIENT_HOME_EMAIL:this.quote.customer_email,
                CLIENT_HOME_MOBILE:this.quote.customer_phone,
                CLIENT_DATE_OF_BIRTH:result.data['dob'],
                role:'client',
                agency:null
              }

              this.service.currentUser(client,true)
              this.choosePaymentMethod();
              this.service.swalAlart.close();
              this.processing=false;
          }).
          catch(error=>{
            console.log('submit error',error)
            this.processing=false;
            this.service.swalAlart.close()
            this.service.swalAlart.fire({
              title: 'Error',
              text: 'Server Error,retry later',
              icon: 'error',
            });
          })
      }
    });
  }

  //choose payment method
  choosePaymentMethod(){
    const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
      disableClose :true,
      width: '350px',
      data: { quotePayload: {...this.payload,currency_code:'GHS',client_code:this.quote.customer_code.toString()},method:'',total_premium:this.quote.total_premium,policy_lob: "10"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.hasOwnProperty('data')){
        this.confirmedQuote = result.data.confirmedQuote;
        this.selectedMethod=result.data.method;

        if(this.selectedMethod)
        this.confirmPin();

        this.validateForm();
      }
    });
  }

   //open cash Payment Dialog
   openMomoPaymentDialog(){
    const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
      disableClose :true,
      width: '350px',
      data: { quotePayload: {policy_sequence:this.payload.policy_sequence,client_code:this.quote.customer_code.toString(),status_of_client:this.payload.status_of_client,currency_code:'GHS'},total_premium:this.quote.total_premium,policy_lob: "10"}
    });
    dialogRef.afterClosed().subscribe(result => {

      if(result.hasOwnProperty('data')){

        if(result.data.hasOwnProperty('quotePayload'))
        this.confirmedQuote = result.data.quotePayload.module=='PAID';
        this.validateForm();

        if(this.confirmedQuote)
        {
          setTimeout(()=>{
            this.router.navigate(['transactions']);
          },3000)
        }
      }

    });
  }
      //open cash Payment Dialog
      openCashPaymentDialog(){
        const dialogRef = this.dialog.open(CashPaymentComponent, {
          disableClose :true,
          width: '350px',
          data: { quotePayload: {...this.payload,currency_code:'GHS',client_code:this.quote.customer_code.toString()},total_premium:this.quote.total_premium,policy_lob: "10"}
        });
        dialogRef.afterClosed().subscribe(result => {

          if(result.hasOwnProperty('data')){
            this.confirmedQuote = result.data.quotePayload.module=='PAID';
            this.validateForm();

            if(this.confirmedQuote)
            {
              setTimeout(()=>{
                this.router.navigate(['transactions']);
              },3000)
            }
          }

        });
      }
      //open Cheque Payment Dialog
      openChequePaymentDialog(){
        const dialogRef = this.dialog.open(ChequePaymentComponent, {
          disableClose :true,
          width: '600px',
          data: { quotePayload: {...this.payload,currency_code:'GHS',client_code:this.quote.customer_code.toString()},total_premium:this.quote.total_premium,policy_lob: "10"}
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result.hasOwnProperty('data')){
            this.confirmedQuote = result.data.quotePayload.module=='PAID';
            this.validateForm();

            if(this.confirmedQuote)
            {
              setTimeout(()=>{
                this.router.navigate(['transactions']);
              },3000)
            }
          }
        });
      }
      //confirm login pin to continue
confirmPin(){
  this.service.swalAlart.fire({
    //title: "Identity Confirmation",
    icon:"warning",
    allowOutsideClick:false,
    showCancelButton: true,
    inputPlaceholder: "Confirm your Pin",
    input:"password",
    reverseButtons: true
  })
  .then((result:SweetAlertResult)=>{

       if(result.value){
        let currentUser= this.service.currentUser();
        //prepare payload
        let loginPayload={};
        if (currentUser.role == "client")
            loginPayload={phone:currentUser.phone,pin:result.value,module:'client'};
        else
            loginPayload={agent_code:currentUser.code,pin:result.value,module:'agent'};

          Swal.showLoading()
          this.service.webLogin(loginPayload)
          .toPromise()
          .then((responseData: any)=>{
            Swal.hideLoading()

            if(responseData.hasOwnProperty('responseCode') && responseData.responseCode==='000'){
              if(this.selectedMethod=='Mobile')
              this.openMomoPaymentDialog();
              else if(this.selectedMethod=='Cash')
              this.openCashPaymentDialog();
              else if(this.selectedMethod=='Cheque')
              this.openChequePaymentDialog();

              this.quickFireDialogRef.close();
            }
            else{
              Swal.fire({
                title: 'Wrong credentials!',
                text: 'Please check and try again',
                icon: 'error',
              });
              setTimeout(()=>{this.confirmPin()},2000);
            }

          }).catch(error=>{
            Swal.fire({
              title: 'Connection Failed!',
              text: 'Login Failed',
              icon: 'error',
            });
            setTimeout(()=>{this.confirmPin()},2000);
          })
       }
       else
       this.alertSnackBar('No Pin was entered','User Not Verified');
     });
   }
}
