import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../modules/service/dashboard.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-widget-dlystickersales',
  templateUrl: './dlystickersales.component.html',
  styleUrls: ['./dlystickersales.component.scss']
})
export class DlystickersalesComponent implements OnInit {

  falseAuth:boolean = true;


  total:any;
  transact:any;
  pending:any;
  transacttoday:any;
  policytoday:any;
  renewpolicy:any;
  stickersoldtoday:any = 0;


  loading:boolean = false
  userdata :any //get users email
  loaderror:any = ''



  constructor( private router:Router, private service:DashboardService) {
    this.userdata=this.service.currentUser();
  }

  ngOnInit() {
    
    this.loading = true
      let payload: any={}

      if(this.userdata.role==='client'){
        payload={
          role:'client',
          client_code:this.userdata.code
        }
      }else if(this.userdata.role==='agent' || this.userdata.role==='broker-admin'){
        payload={
          role:'agent',
          agent_code:this.userdata.code
        }
      }else if(this.userdata.role==='broker-user'){
        payload={
          role:'broker-user',
          broker_user_code:this.userdata.code
        }
        
     
      }else if(this.userdata.role==='staff'){
        payload={
          role:'staff',
          staff_id: this.userdata.code
        }
     
      }
      else{}


      this.service.getAnaytics(payload).subscribe(data=>{
        this.loading = false

        this.stickersoldtoday = data['today_sticker'][0]['TOTALCOUNT'];
        this.stickersoldtoday = 0;


      }, error => {
        this.loading = false
        this.loaderror = 'Check your Internet!'
        swal.fire({
          title: 'Oops...',
          text: error.name,
          footer: ''
        });
      });



    }

}
