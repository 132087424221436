import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { ChartService } from './modules/service/chart.service';
import {MatSelectModule} from '@angular/material/select';
import {CurrencyPipe} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DisableRightClickService } from './modules/service/disable-right-click.service';
import { EnvServiceProvider } from './modules/service/env/env.service.provider';
import { MsalGuard, MsalInterceptor, MsalModule, MsalService} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    MatSelectModule,
    MatTooltipModule,
    MsalModule.forRoot({
      auth: {
        clientId: '72ec26f8-777c-4694-9fbd-5c5d75e4c6a1',
        authority: "https://login.microsoftonline.com/common/",
        validateAuthority: true,
        redirectUri: "https://staff-app.vanguardassurance.com/auth/staff/login",    //"https://test-app.vanguardassurance.com/auth/staff/login",
        postLogoutRedirectUri: "https://staff-app.vanguardassurance.com/auth/staff/login",
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
    {
      popUp: !isIE,
      consentScopes: [
        "user.read",
        "openid",
        "profile",
        "api://72ec26f8-777c-4694-9fbd-5c5d75e4c6a1/access_as_user"
      ],
      unprotectedResources: ["https://www.microsoft.com/en-us/"],
      protectedResourceMap,
      extraQueryParameters: {}
    }
  )
  ],
  providers: [
    EnvServiceProvider,
    ChartService, 
    CurrencyPipe,
    DisableRightClickService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
