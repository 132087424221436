import { Component, OnInit } from '@angular/core';
import {PRIVILEDGES} from '../modules/service/roles.mockup';
import {Router} from '@angular/router';
import {DashboardService} from '../modules/service/dashboard.service';
import swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { QuickMotoQuoteComponent } from '../quick-moto-quote/quick-moto-quote.component';
import { ResetPinComponent } from '../modules/dialogs/reset-pin/reset-pin.component';
import { SignupComponent } from '../modules/dialogs/signup/signup.component';
import { SelectUserTypeComponent } from '../modules/dialogs/select-user-type/select-user-type.component';
import { AgentSignUpComponent } from '../modules/dialogs/agent-sign-up/agent-sign-up.component';
import { ActivateCustomerComponent } from '../modules/dialogs/active-customer/activate-customer.component';
import { ChangePinComponent } from '../modules/dialogs/change-pin/change-pin.component';
import { SelectProductDialogComponent } from '../modules/dialogs/select-product-dialog/select-product-dialog.component';
import { QuickTravelQuoteComponent } from '../quick-travel-quote/quick-travel-quote.component';
/* import { ScKryptoService } from '../modules/service/ScKryptoService';
 */import { QuickFireQuoteComponent } from '../quick-fire-quote/quick-fire-quote.component';
import { setSecurityQuestionsComponent } from '../modules/dialogs/set-security-questions/set-security-questions.component';


interface Target {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {

  audience: Target[] = [
    {value: 'agent', viewValue: 'Intermediary'},
    {value: 'broker-user', viewValue: 'Broker-user'},
    {value: 'client', viewValue: 'Customer'}
  ];

  selectedValue: string = "";

  usr={
    email:'',
    password:'',
    role:''
  }

payload={
  agent_code:'',
    phone:'',
    pin:'',
    module:'agent'
}


payloadBrokerUser={
  broker_user_code: '',
  broker_user_pin: ''
}




usrType:any;
appuser:any;

public agentshow:boolean = false;
public customershow:boolean = false;

constructor(
  private router:Router, 
  private service: DashboardService,
  public dialog:MatDialog
  ) {}

  ngOnInit() {
    this.agentshow = true;
    this.customershow = false;
  }


  login(event){
    event.preventDefault();
    console.log("module>>>>>>" + this.payload.module)

    if (this.payload.module == "broker-user"){

      this.loginAsBrokerUser()

    }else{

      this.loginAsAgentClient()
    }


  }



  loginAsAgentClient(){
     //prepare payload
     if (this.payload.module == "client") {
      this.payload = { agent_code: '', phone: this.payload.phone, pin: this.payload.pin, module: 'client' };
      delete this.payload.agent_code;
    }
    else {
      this.payload = { phone: '', agent_code: this.payload.agent_code, pin: this.payload.pin, module: 'agent' };
      delete this.payload.phone;
    }

    //show the loading animation
    swal.showLoading()

    //initiate a login
    this.service.webLogin(this.payload)
      .toPromise()
      .then((data: any) => {
        swal.hideLoading()
        console.log("Profile>>>>>>" + JSON.stringify(data.profile[0]))

        if (data.hasOwnProperty('responseCode') && data.responseCode === '000') {
          swal.fire({
            icon: 'success',
            text: 'Welcome to smartCover Dashboard',
            timer: 1000,
            showConfirmButton: false
          });

          this.appuser = this.service.currentUser(data.profile[0], true);
         
          if (data.hasOwnProperty('account_activation_requested') && data.account_activation_requested == '1'){
            //save account activation state as 1 in localstorage
            localStorage.setItem('account_activation_requested', '1')
            this.changePin()

          }else if(data.hasOwnProperty('reset_requested') && data.reset_requested == '1'){
            this.changePin()
          }

      

          if (this.appuser.role == "agent")
            this.router.navigate(['dashboard']);
          else if (this.appuser.role == "client")
            this.router.navigate(['profile']);

        }
        else {
          swal.showLoading()
          swal.fire({
            title: 'Wrong credentials!',
            text: 'Please check and try again',
            icon: 'error',
          });
        }

      }).catch(error => {
        swal.fire({
          title: 'Connection Failed!',
          text: 'Login Failed',
          icon: 'error',
        });
      })


  }




  loginAsBrokerUser(){
    console.log(this.payloadBrokerUser)

    //show the loading animation
    swal.showLoading()

    //initiate a login
    this.service.webLoginAsBrokerUser(this.payloadBrokerUser)
    .toPromise()
    .then((data: any)=>{
      swal.hideLoading()

      if(data.hasOwnProperty('responseCode') && data.responseCode==='000'){
        swal.fire({
          icon: 'success',
           text: 'Welcome to smartCover Dashboard',
           timer: 1000,
          showConfirmButton: false
         });

          this.appuser = this.service.currentUser(data.data,true);
          this.router.navigate(['dashboard']);
 

       
      }
      else{
        swal.showLoading()
        swal.fire({
          title: 'Wrong credentials!',
          text: 'Please check and try again',
          icon: 'error',
        });
      }

    }).catch(error=>{
      swal.fire({
        title: 'Connection Failed!',
        text: 'Login Failed',
        icon: 'error',
      });
    })
  } 



  forgotpassword(){
    this.router.navigate(['/reset_manager_password'])
  }

  signUp(){
    this.router.navigate(['/customersignup'])
  }

  validatePin(event){
    let pin=!isNaN(+`${event.target.value}`)?parseInt(event.target.value):0;
    if(!pin)
    event.target.value='';
  }


  //open Quick Motor Quote Dialog
  openQuickMotoDialog(){
    const dialogRef = this.dialog.open(QuickMotoQuoteComponent, {
      disableClose :true,
      width: '510px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //open Quick Travel Quote Dialog
  openQuickTravelDialog(){
    const dialogRef = this.dialog.open(QuickTravelQuoteComponent, {
      disableClose :true,
      width: '510px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //open Quick Fire Quote Dialog
  openQuickFireDialog(){
    const dialogRef = this.dialog.open(QuickFireQuoteComponent, {
      disableClose :true,
      width: '510px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openResetPinDialog(){
    const dialogRef = this.dialog.open(ResetPinComponent, {
      disableClose :true,
      width: '300px',
      data: { module:''}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openCustomerActivationFormDialog(){
    const dialogRef = this.dialog.open(ActivateCustomerComponent, {
      disableClose :true,
      width: '300px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openUserTypeDialog(){
    const dialogRef = this.dialog.open(SelectUserTypeComponent, {
      disableClose :true,
      width: '300px',
      data: {user_type:this.payload.module}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.hasOwnProperty('data') && result.data.user_type=='client')
         this.openSignUpDialog();
      else if(result.hasOwnProperty('data') && result.data.user_type=='agent')
         this.openAgentSignUpDialog();
    });
  }

  openSignUpDialog(){
    const dialogRef = this.dialog.open(SignupComponent, {
      disableClose :true,
      width: '860px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //if(result.hasOwnProperty('data')){}
    });
  }

  openAgentSignUpDialog(){
    const dialogRef = this.dialog.open(AgentSignUpComponent, {
      disableClose :true,
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //if(result.hasOwnProperty('data')){}
    });
  }


  
  changePin() {
    const dialogRef = this.dialog.open(ChangePinComponent, {
      disableClose: true,
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  

  selectProduct(){
    // return ;
    const dialogRef = this.dialog.open(SelectProductDialogComponent, {
      disableClose :true,
      width: '300px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.data && result.data['product']=='Motor Insurance')
        this.openQuickMotoDialog();
      else if(result.data && result.data['product']=='Travel Insurance')
       this.openQuickTravelDialog();
      else if(result.data && result.data['product']=='Fire Insurance')
       this.openQuickFireDialog();
    });
  }


}
