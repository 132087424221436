import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../modules/service/dashboard.service';

interface Target {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-activate-account-page',
  templateUrl: './activate-account-page.component.html',
  styleUrls: ['./activate-account-page.component.scss']
})


export class ActivateAccountPageComponent implements OnInit {

  constructor(
    private service: DashboardService, 
    private router: Router, 
   ) {
  }

  activateOptions: Target[] = [
    { value: 'email', viewValue: 'Email' },
    { value: 'phone', viewValue: 'Phone' }
  ];


  payload = {
    search_parameter: 'phone',
    email: '',
    phone: ''
  }


  ngOnInit() { }
  onActivateCustomer(event) {
    event.preventDefault();
    this.service.swalAlart.showLoading();
    this.service.activateCustomer(this.payload)
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          this.service.swalAlart.fire({
            title: 'Successful',
            text: `We have sent account activation details to your email.`,
            timer: 2000,
          })
          // this.dialogRef.close({ event: 'close', data: {} });

        } else if (response.hasOwnProperty('responseCode') && response.responseCode == 'E22') {

          this.service.swalAlart.fire({
            title: '',
            text: `This account has already been activated. Kindly login or reset your PIN if forgotten.`,
            //timer:2000,
          })

        } else {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: `Account activation failed. Please try again!`,
            //timer:2000,
          })
        }
      })
      .catch(error => {
        this.service.swalAlart.fire({
          title: 'Failed',
          text: `Account activation failed. Please try again!`,
          //timer:2000,
        })
      })
      
  }


  selectOptionChange(value) {

    if (value == 'email') {
      this.payload.phone = ''
    } else {
      this.payload.email = ''
    }
  }


}
