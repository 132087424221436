import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../modules/service/dashboard.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { StickerService } from '../../../modules/service/stickerservice.service';
import { MatSelectChange } from '@angular/material';

interface DurationPeriod {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-widget-stickersalesgraph',
  templateUrl: './stickersalesgraph.component.html',
  styleUrls: ['./stickersalesgraph.component.scss']
})
export class StickersalesgraphComponent implements OnInit {

  selected = '30';

  selectedValue: string;

  days: DurationPeriod[] = [
    { value: '7', viewValue: 'Last 7 Days' },
    { value: '30', viewValue: 'Last 30 Days' },
    { value: 'months', viewValue: 'Last Year' }
  ];

  charts: any;
  userdata: any //get users email


  total: any;
  transact: any;
  pending: any;

  loaderror: any = ''

  sday: boolean = true;
  thday: boolean = false;
  yrday: boolean = false;

  loading: boolean = false;
  topperformstartdate: any;
  topperformenddate: any;
  selectmonth: any;

  periodchart: any = [];


  constructor(public service: DashboardService, public router: Router, public stickerservice: StickerService) {
    this.userdata = this.service.currentUser();
  }

  ngOnInit() {

    if (this.userdata.role == "agent" || this.userdata.role == "broker-user" || this.userdata.role == "broker-admin" || this.userdata.role == "staff") {

      var today = new Date(); //get todays date
      this.topperformenddate = today.toISOString().substring(0, 10) //convert todays date to format eg.2019-08.02 and set it to end date

      today.setDate(today.getDate() - 30) // subtract 7 days from todays date
      this.topperformstartdate = today.toISOString().substring(0, 10) //set that date to start date

      this.perfomanceDetails()

    } else {

    }



  }


  perfomanceDetails() {

    let minDate = new Date(this.topperformstartdate)
    let maxDate = new Date(this.topperformenddate)
    var days;

    days = (maxDate.getTime() - minDate.getTime()) / (1000 * 3600 * 24)
    // console.log("days" + days);



    if (days > 30) {

      days = "months";
    }

    // if (days === 7 || days === 30 ) {
    if (this.topperformstartdate === undefined || this.topperformenddate === undefined) {
      swal.fire({ icon: 'error', title: 'Oops...', text: 'Start Date or End Date was not selected.', footer: 'Please, make the necessary changes and try again.' });
    } else if (this.topperformstartdate > this.topperformenddate) {
      swal.fire({ icon: 'error', title: 'Oops...', text: 'Start Date is more than End Date.', footer: 'Please, make the necessary changes and try again.' });
    } else {
      this.loading = true;
      let payload: any = {}

      if(this.userdata.role==='client'){
        payload={
          role:'client',
          client_code:this.userdata.code,
          minDate: this.topperformstartdate, 
          maxDate: this.topperformenddate, 
          duration: days.toString()
        }
      }else if(this.userdata.role==='agent'){
        payload={
          role:'agent',
          agent_code:this.userdata.code,
          minDate: this.topperformstartdate, 
          maxDate: this.topperformenddate, 
          duration: days.toString()
        }
      }else if(this.userdata.role==='broker-user'){
        payload={
          role:'broker-user',
          broker_user_code:this.userdata.code,
          minDate: this.topperformstartdate, 
          maxDate: this.topperformenddate, 
          duration: days.toString()
        }

      }else if(this.userdata.role==='broker-admin'){
        payload={
          role:'broker-admin',
          broker_admin_main_code:this.userdata.broker_admin_main_code,
          minDate: this.topperformstartdate, 
          maxDate: this.topperformenddate, 
          duration: days.toString()
        }
      }else if(this.userdata.role==='staff'){
        payload={
          role: 'staff',
          staff_id: this.userdata.code,
          minDate: this.topperformstartdate, 
          maxDate: this.topperformenddate, 
          duration: days.toString()
        }
      }else{}
      
      this.service.getStickersalesgraph(payload)
        .subscribe(data => {
          this.loading = false;
          this.periodchart = data['responseData'];

          let labels = [], counts = [], amounts = [];
          data['responseData'].forEach(element => {
            if (element.hasOwnProperty('DAYNAME'))
              labels.push(element.DAYNAME);

            else if (element.hasOwnProperty('DATE_MONTH'))
              labels.push(element.DATE_MONTH);

            else if (element.hasOwnProperty('DATE_YEAR'))
              labels.push(element.DATE_YEAR);

            counts.push(element.TOTAL);
            amounts.push(parseFloat(element.TOTAL));
          });

          this.charts = this.stickerservice.getStickerChart(labels, counts, 'stickersalesgraph');

          // this.topperformenddate = ''
          // this.topperformstartdate = ''

        }, error => {
          this.loading = false;
          swal.fire({ icon: 'error', title: 'Oops...', text: error.message, footer: 'Please, make the necessary changes and try again.' });
        })
    }
    //}
    // else {
    //   return  swal.fire({ icon: 'error',title: 'Oops...',text: 'Interval Should be 7 or 30 days',footer: 'Please, make the necessary changes and try again.'});
    // }


  }



  changeRatio(event: MatSelectChange) {
    //console.log(event.value);
    if (event.value == "7") {
      //console.log("doing 7");
      this.day_7()
    }
    else if (event.value == "30") {
      //console.log("doing 30");
      this.day_30()
    }
    else {
      //console.log("doing 365");
      this.day_365()
    }
  }


  day_7() {
    this.sday = true;
    this.thday = false;
    this.yrday = false;

    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0, 10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - 7) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0, 10) //set that date to start date

    this.perfomanceDetails()
  }

  day_30() {
    this.sday = false;
    this.thday = true;
    this.yrday = false;
    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0, 10) //convert todays date to format eg.2019-08.02 and set it to end date
    today.setDate(today.getDate() - 30) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0, 10) //set that date to start date

    this.perfomanceDetails()
  }

  day_365() {
    this.sday = false;
    this.thday = false;
    this.yrday = true;
    var today = new Date(); //get todays date
    this.topperformenddate = today.toISOString().substring(0, 10) //convert todays date to format eg.2019-08.02 and set it to end date

    today.setDate(today.getDate() - 365) // subtract 7 days from todays date
    this.topperformstartdate = today.toISOString().substring(0, 10) //set that date to start date
    this.perfomanceDetails()
  }



}
