import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DashboardService } from '../service/dashboard.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CreateCustomerComponent } from '../dialogs/create-customer/create-customer.component';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';
import { ChequePaymentComponent } from '../dialogs/cheque-payment/cheque-payment.component';
import { CashPaymentComponent } from '../dialogs/cash-payment/cash-payment.component';
import { DebitCardPaymentComponent } from '../dialogs/debit-card-payment/debit-card-payment.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import swal, { SweetAlertResult } from 'sweetalert2';
import { MomoPaymentComponent } from '../dialogs/momo-payment/momo-payment.component';
import { FinalizeRetryMomoComponent } from '../dialogs/finalize-retry-momo/finalize-retry-momo.component';


interface branchLOVData {
  user_branch_desc: string;
  user_branch_username: string;
  user_branch_code: string;
}

interface brokerLOVData {
  agent_code: string;
  agent_family_name: string;
  agent_work_mobile: string;
  agent_work_email: string;
  agent_agency: string;
  agent_type: string;
}

interface agentLOVData {
  agent_code: string;
  agent_family_name: string;
  agent_work_mobile: string;
  agent_work_email: string;
  agent_agency: string;
  agent_type: string;
}



interface CustomerProfile {
  CLIENT_CODE: string;
  CLIENT_TYPE?: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?: boolean;
}
interface CustomerResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}
interface ConstructionTypeResponse {
  responseCode: string,
  responseMessage: string,
  code: string,
  data: string,
  account_activation_requested: string,
  reset_requested: string,
  agent_pin_set: string,
  profile: string,
  responseData: ConstructionType[]
}
interface ConstructionType {
  TABLED_NAME: string;
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
}
interface Item {
  name: string;
  value: string;
}

interface Term {
  value: string;
  viewValue: string;
  days: string;
}
interface CurrencyResponse {
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Currency[];
}
interface Currency {
  CURRENCY: string;
  DATE_UPDATED: string;
  CURRENCY_VALUE: number;
}

interface Product {
  PRODUCT_DESC: string;
  PRODUCT_CURRENCY: string;
  PRODUCT_CODE: string;
}

interface ProductResponse {
  responseCode: string,
  responseMessage: string,
  code: null,
  data: null,
  account_activation_requested: null,
  reset_requested: null,
  agent_pin_set: null,
  profile: Array<Product>
}



interface Target {
  value: string;
  viewValue: string;
}




@Component({
  selector: 'app-fire-quote',
  templateUrl: './fire-quote.component.html',
  styleUrls: ['./fire-quote.component.scss']
})



export class FireQuoteComponent implements OnInit {
  audience: Target[] = [
    { value: '5', viewValue: 'Agent' },
    { value: '2', viewValue: 'Broker' },
    { value: '1', viewValue: 'Customer' }
  ];

  selectedValue: string = "";
  module: 'client'

  appuser: any;

  terms: Array<Term> = [
    { value: '01', viewValue: 'One Year(365)', days: '365' },
    { value: '02', viewValue: 'Short Term', days: '1' },
    //{value: '03', viewValue: 'Prorata Term', days: '1'}
  ];

  buildItems: Array<Item> = [];
  contentItems: Array<Item> = [];
  currencies: Array<Currency> = [];
  //customer
  searchCustomerCtrl = new FormControl();

  item_name: string = "";
  item_value: string = "";


  selectedProduct: Product;
  filteredCustomers: CustomerProfile[];

  //list of construction types
  constructionTypes: ConstructionType[];
  selectedConstructionType: ConstructionType;

  //loadings
  isCustomerLoading: boolean = false;

  //is renewal
  renewal: boolean = false;

  //payload for submitting quote
  payload: any = {};
  filterClientPayload: any = {};


  isNewCustomer: true;

  //selected records from dialogs
  selectedCustomer: CustomerProfile = {
    CLIENT_CODE: "",
    CLIENT_TYPE: "",
    CLIENT_FAMILY_NAME: "",
    CLIENT_HOME_MOBILE: "",
    CLIENT_FIRST_NAME: "",
    CLIENT_HOME_EMAIL: "",
    CLIENT_DATE_OF_BIRTH: "",
    DISPLAY_NAME: "",
    DISPLAY_SHORT_NAME: "",
    isNewCustomer: false
  };

  //searchCustomerDialogValue :any;
  //prepare quote json
  confirmedQuote: boolean = false;
  errors = {}
  selectedMethod: string;

  //is processing
  processing = false;
  quote: any = {
    days_label: "Day(s)",
    days: "365",
    start_date: new Date(),
    end_date: '',
    term: '01',
    loss_of_rent_value: '0',
    public_liability: '',
    construction_type: '',
    content_sum: '',
    building_sum: '',
    currency_code: 'GHC',
    exchange_rate: '1',
    channel: "1",
    staff: {
      staff_id: "",
      staff_email: ""
    }
  }


  isOptional = false;


  todayDate = new Date();
  dateTxt: string;

  is_loss_of_rent: boolean;
  is_person_accident: boolean;
  is_public_liability: boolean;
  products: Array<Product> = [];


  paymentStatus: string;
  paymentChannel: string;

  quotePayloadBeforePayment:any


  sob: any = {
    agent_code: '',
    client_code: '',
    broker_code: '',
    agent_family_name: '',
    agent_work_email: '',
    policy_branch_name: 'HEAD OFFICE',
    policy_branch_code: '1001'
  }

  isAgentLOVLoading: boolean = false;
  AgentLOV: agentLOVData[];
  filteredAgentLOV: agentLOVData[];
  sob_agent_code = new FormControl();
  sob_broker_code = new FormControl();
  sob_policy_branch_code = new FormControl();

  filteredBrokerLOV: any[];
  BrokerLOV: any[];
  isBrokerLOVLoading: boolean = false

  filteredBranchLOV: any[];
  BranchLOV: any[];
  isBranchLOVLoading: boolean = false




  constructor(
    private service: DashboardService, 
    private router: Router, 
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<FireQuoteComponent>,
    public dialogRetryRef: MatDialog,
    private route: ActivatedRoute, 
    ) {

    this.appuser = this.service.currentUser();
    console.log("module>>>>" + this.appuser.role)

    if (!this.appuser)
      this.router.navigate(['']);

    this.renewal = false;

    this.selectedConstructionType = {
      TABLED_CODE: '',
      TABLED_DESIGNATION: '',
      TABLED_NAME: ''
    };

    this.selectedProduct = {
      PRODUCT_CODE: '',
      PRODUCT_CURRENCY: '',
      PRODUCT_DESC: ''
    }
    
  }

  dobStartAt = new Date(moment(moment().format('YYYY-MM-DD')).subtract(18, 'years').format('YYYY-MM-DD'))
  //dobStartAt = new Date(moment(moment().format('YYYY-MM-DD')).subtract(1, 'days').format('YYYY-MM-DD'))



  // get the selected editable quote
  populateEditableQuote(selectQuote) {
    //console.log('selected quote',selectQuote)
    this.quote.edit_mode = true;

    this.quote = {
      ... this.quote,
      policy_no: selectQuote.policy_sequence,
      customer_code: selectQuote.client_code,
      customer_first_name: (selectQuote.customer_first_name ? selectQuote.customer_first_name : selectQuote.customer_last_name),
      customer_last_name: selectQuote.customer_last_name,
      customer_email: selectQuote.customer_email,
      customer_phone: selectQuote.customer_phone,
      prepared_by: selectQuote.prepared_by,
      date_of_birth: this.service.strDateToDBFormat(selectQuote.customer_date_of_birth),

      start_date: this.service.strDateToDBFormat(selectQuote.inception_date),
      end_date: this.service.strDateToDBFormat(selectQuote.expiry_date),

      cover_type_code: selectQuote.product.toString(),
      construction_type: selectQuote.FIRE_CONSTRUCT_TYPE_CODE,
      currency_code: selectQuote.currency_code,
      location: selectQuote.FIRE_LOCATION,
      exchange_rate: selectQuote.exchange_rate,
      property_description: selectQuote.FIRE_GEN_DESC || selectQuote.product_description.product_description,
      building_sum: selectQuote.BUILDING_SUM_INSURED,
      content_sum: selectQuote.CONTENT_SUM_INSURED,

      loss_of_rent: selectQuote.LOSS_OF_RENT_VALUE,
      public_liability: selectQuote.FIRE_ADD_PL,

      days: selectQuote.quote_days,
      term: (selectQuote.duration_type == "shortrate" || selectQuote.duration_type == "short rate") ? '02' : '01',

      legacy_policy_number: selectQuote.legacy_policy_sequence
    }


    this.onStartDateChanged();
    //select construction type
    this.selectedConstructionType.TABLED_CODE = this.quote.construction_type
    this.selectedConstructionType.TABLED_DESIGNATION = selectQuote.FIRE_CONSTRUCT_TYPE_DESC


    //select product
    this.selectedProduct.PRODUCT_CODE = selectQuote.product_description.product_code,
    this.selectedProduct.PRODUCT_DESC = selectQuote.product_description.product_description,

    this.is_loss_of_rent = selectQuote.LOSS_OF_RENT_VALUE ? true : false
    this.is_public_liability = selectQuote.FIRE_ADD_PL ? true : false;

    //load fire items
    (this.renewal ? this.service.getFireLegacyItems({ client_code: selectQuote.CLIENT_CODE, agent_code: selectQuote.AGENT_CODE, legacy_policy_sequence: selectQuote.LEGACY_POLICY_SEQUENCE, module: this.appuser.role }) : this.service.getFireItems(selectQuote.POLICY_SEQUENCE))
      .toPromise()
      .then((response: any) => {
        if (response && response['responseData']) {

          // populate building items
          if (response['responseData'].buildingArray && response.responseData.buildingArray.length) {
            this.buildItems = [];
            response.responseData.buildingArray.map((item: any, index: number) => {
              this.buildingTotal += item.item_si ? parseFloat(item.item_si) : 0

              this.buildItems.push({ name: item.item_desc.toString(), value: item.item_si.toString() })
            })
          }

          // populate content items
          if (response['responseData'].contentArray && response.responseData.contentArray.length) {
            this.contentItems = [];
            response.responseData.contentArray.map((item: any, index: number) => {
              this.contentTotal += item.item_si ? parseFloat(item.item_si) : 0
              this.contentItems.push({ name: item.item_desc.toString(), value: item.item_si.toString() })
            })
          }
        }
      })
  }

  ngOnInit() {
    let selectedQuote = history.state.editableQuote;

    //check to see if the state was passed for renewal
    if (history.state.hasOwnProperty('renewal'))
      this.renewal = history.state.renewal;

    this.buildItems.push({ name: '', value: '' });
    this.contentItems.push({ name: '', value: '' });

    if (selectedQuote)
      this.searchCustomerCtrl = new FormControl({ value: selectedQuote.client_code ? selectedQuote.client_code : null, disabled: true });

    else if (this.appuser && this.appuser.role == 'client') {

      this.quote = {
        ... this.quote,
        customer_code: this.appuser.code,
        client_code: this.appuser.code.toString(),
        customer_first_name: this.appuser.first_name,
        customer_last_name: this.appuser.last_name,
        customer_phone: this.appuser.phone,
        customer_email: this.appuser.email,
        date_of_birth: this.service.strDateToDBFormat(this.appuser.dob)
      }

      this.searchCustomerCtrl = new FormControl({ value: this.quote.customer_code, disabled: true });
    }


    if (this.appuser.role == 'agent' || this.appuser.role == 'broker-user') {
      this.quote.agent_id = this.appuser.code
      this.quote.intermediary_first_name = this.appuser.first_name
      this.quote.intermediary_last_name = this.appuser.first_name
      this.quote.intermediary_email = this.appuser.email
      this.quote.managerEmail = null
      this.quote.prepared_by = this.appuser.first_name + " " + this.appuser.last_name


    } else if (this.appuser.role == 'client') {
      this.selectedCustomer = {
        CLIENT_CODE: this.appuser.client_id,
        CLIENT_FIRST_NAME: this.appuser.first_name,
        CLIENT_FAMILY_NAME: this.appuser.last_name,
        CLIENT_HOME_EMAIL: this.appuser.email,
        DISPLAY_SHORT_NAME: `${this.appuser.first_name} ${this.appuser.last_name}`,
        CLIENT_HOME_MOBILE: this.appuser.phone
      };

      this.quote = {
        ...this.quote,
        customer_first_name: this.selectedCustomer.CLIENT_FIRST_NAME,
        customer_last_name: this.selectedCustomer.CLIENT_FAMILY_NAME,
        customer_email: this.selectedCustomer.CLIENT_HOME_EMAIL,
        customer_phone: this.selectedCustomer.CLIENT_HOME_MOBILE,
        policy_no: this.appuser.policy_no,
      }

    } else if (this.appuser.role == 'staff') {
      this.quote.module = 'staff'
      this.quote.staff.staff_id = this.appuser.code,
        this.quote.staff.staff_email = this.appuser.email

      this.onLoadAgentLOV()
      this.onFilterAgentLOV()
      this.onLoadBrokerLOV()
      this.onFilterBrokerLOV()
      this.onLoadBranchLOV()




    } else { }






    this.selectedMethod = 'Mobile';
    this.showCurrencies();

    //load products
    this.onLoadProducts();

    //load construction types
    this.onLoadConstructionTypes();

    //preselect end date
    this.onDurationSelected();

    //on Customer Filter
    if (!selectedQuote)
      this.onFilterCustomer();
    else {
      //update editable quotes
      this.populateEditableQuote(selectedQuote)
      this.quote.edit_mode = true;
    }

    console.log("Quote>>>>" + JSON.stringify(this.quote));



    try{
      this.route.paramMap.subscribe(
        params => {
            this.paymentChannel = params.get('payment-channel');
            //this.paymentStatus = params.get('payment-status');
        }
  
      );

    }catch(e){
     // console.log(e)
    }

    // console.log("payment-channel====>" + this.paymentChannel)
    // console.log("payment-status====>" + this.paymentStatus)


    if(this.paymentChannel=='ecobankpay'){
      this.quotePayloadBeforePayment = JSON.parse(localStorage.getItem('quotePayload'))
      console.log("quotePayloadBeforePayment====>" + this.quotePayloadBeforePayment.policy_sequence)

      this.transactionStatus()

    }






  }




  addBuildRow() {
    let { name, value } = this.buildItems[this.buildItems.length - 1];

    //check and prevent adding new row when the total items exceeds the sum insured
    let total: number = 0;
    let building_sum = this.quote.building_sum ? parseFloat(this.quote.building_sum) : 0;
    for (let i = 0; i < this.buildItems.length; i++)
      total += this.buildItems[i].value ? parseFloat(this.buildItems[i].value) : 0;

    if (name != '' && value != '' && total < building_sum)
      this.buildItems.push({ name: '', value: '' });
    else if (name != '' && value != '' && total >= building_sum)
      this.alertSnackBar("Total sum of Items cannot exceeed Building Sum Insured Value", "Building Sum Limit");
  }
  deleteBuildRow(index) {
    if (this.buildItems.length == 1) {
      return false;
    } else {
      this.buildItems.splice(index, 1);
      return true;
    }
  }
  addContentRow() {
    let { name, value } = this.contentItems[this.contentItems.length - 1];

    //check and prevent adding new row when the total items exceeds the sum insured
    let total: number = 0;
    let content_sum = this.quote.content_sum ? parseFloat(this.quote.content_sum) : 0;
    for (let i = 0; i < this.contentItems.length; i++)
      total += this.contentItems[i].value ? parseFloat(this.contentItems[i].value) : 0;

    if (name != '' && value != '' && total < content_sum)
      this.contentItems.push({ name: '', value: '' });
    else if (name != '' && value != '' && total >= content_sum)
      this.alertSnackBar("Total sum of Items cannot exceeed Content Sum Insured Value", "Contents Sum Limit");
  }
  deleteContentRow(index) {
    if (this.contentItems.length == 1) {
      return false;
    } else {
      this.contentItems.splice(index, 1);
      return true;
    }
  }
  //Start Date dateFilters
  startDateFilter = (date: Date) => {
    return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days') >= 0;
  }
  //End Date dateFilters
  endDateFilter = (date: Date) => {
    let diff = moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.quote.start_date).format('YYYY-MM-DD'), 'days');
    return diff > 0 && diff <= 364;
  }
  onStartDateChanged() {
    let start_date = this.quote.start_date ? moment(this.quote.start_date).format('YYYY-MM-DD') : null;

    let days = parseInt(this.quote.days);

    if (days >= 365)
      days = 364;

    let end_date = moment(start_date).add(!isNaN(+`${days}`) ? days : 1, 'days').format('YYYY-MM-DD');

    this.quote.end_date = end_date;


    if (this.quote.start_date && this.quote.end_date) {
      if (this.quote.term == '01')
        this.quote.days = '365';
      else {
        this.quote.days = this.quote._days = moment(end_date).diff(start_date, 'days').toString();
        if (this.quote.term == '02')
          this.quote.days = this.quote._months = Math.round(moment(end_date).diff(start_date, 'days') / 31);
      }
    }
  }
  onEndDateChanged(date: Date) {
    let start_date = this.quote.start_date ? moment(this.quote.start_date).format('YYYY-MM-DD') : null;

    let days = 0, months = 0;
    if (start_date) {
      if (this.quote.term == '02') {
        months = Math.round(moment(moment(date).format('YYYY-MM-DD')).add(1, 'days').diff(start_date, 'days') / 31);
        this.quote.days = this.quote._months = this.quote._months = months;

        if (this.quote.term == '02' && months == 0) {
          this.quote.term = '03';
          this.quote.days_label = "Days"
        }
      }
      if (this.quote.term != '02') {
        days = moment(moment(date).format('YYYY-MM-DD')).diff(start_date, 'days');
        this.quote.days = days.toString();
      }
    }

    if (days && days < 365 && this.quote.term == '01') {
      months = moment(moment(date).format('YYYY-MM-DD')).diff(start_date, 'months');

      if (months) {
        this.quote.days_label = "Months";
        this.quote.term = '02';
        this.quote.days = months;
      }
      else {
        this.quote.days_label = "Days";
        this.quote.term = '03';
      }
    }
    else if (days == 365)
      this.quote.term = '01';

  }
  //on term selection
  onDurationSelected() {

    //Get the associated days to the term
    let durations = this.terms.filter((duration: Term) => duration.value == this.quote.term);

    if (durations.length > 0)
      this.quote.days = durations[0].days;

    //recalculate the end date
    if (!isNaN(+`${this.quote.days}`) && this.quote.start_date)
      this.quote.end_date = moment(this.quote.start_date).subtract((this.quote.term == '02' ? 1 : 0), 'days').add(parseInt(this.quote.days) >= 364 ? 364 : parseInt(this.quote.days), (this.quote.term == '02' ? 'months' : 'days')).format('YYYY-MM-DD');

    //change the days label to months when the term changes to short term
    if (this.quote.term == '02') {
      this.quote.days_label = 'Months';
      let months = Math.round(Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'), 'days')) / 31);
      this.quote._months = this.quote.days = months ? months : 1;

      let days = Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'), 'days'));
      this.quote._days = days ? days : 1;
    }
    else {
      this.quote.days_label = 'Days';
      let days = this.quote.days = Math.abs(moment(moment(this.quote.start_date).format('YYYY-MM-DD')).diff(moment(this.quote.end_date).format('YYYY-MM-DD'), 'days'));
      this.quote._days = days ? days : 1;
      this.quote._months = '';
    }
    if (this.quote.term == '01' && this.quote.days != '' && parseInt(this.quote.days) >= 364)
      this.quote.days = 365;

  }
  showCurrencies() {
    this.service.getCurrencies()
      .toPromise()
      .then((response: CurrencyResponse) => {
        if (response.responseCode == '000') {
          response.responseData.map((currency: Currency) => {
            this.currencies.push(currency);
          })
        }
      }).catch(err => {
        // console.log('Currencies load error',err);
      });
  }
  onCurrencySelected(code: string) {
    let selectedCurrency = this.currencies.filter((currency: Currency) => currency.CURRENCY == code);

    if (selectedCurrency.length) {
      this.quote.exchange_rate = selectedCurrency[0].CURRENCY_VALUE.toString();
      this.quote.currency_code = code;
    }
  }


  //autocomplete search customer
  onFilterCustomer() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCustomers = [];
          this.isCustomerLoading = true;
        }),
        switchMap(value => {
          if (value && value.length) {

            if (this.appuser.role == "agent") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                agent_code: this.appuser.code,
                search_parameter: value,
                module: 'agent'
              }

            } else if (this.appuser.role == "broker-user") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                broker_user_code: this.appuser.code,
                search_parameter: value,
                module: 'broker-user'
              }

            }else if (this.appuser.role == "staff") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                search_parameter: value,
                module: 'staff'
              }

            }

            console.log("filterClientPayload>>>>>>" + this.filterClientPayload)
            return this.service.searchCustomer(this.filterClientPayload)
              .pipe(
                finalize(() => {
                  this.isCustomerLoading = false
                }),
              )
          } else {
            this.isCustomerLoading = false
            return new Observable<[]>();
          }


        }
        )
      )
      .subscribe((response: CustomerResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {

          if (this.appuser.role == "staff") {
            response.responseData.map(r => {

              r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);
  
              let name_parts = [];
              if (r.CLIENT_FIRST_NAME)
                name_parts.push(r.CLIENT_FIRST_NAME);
  
              if (r.CLIENT_FAMILY_NAME)
                name_parts.push(r.CLIENT_FAMILY_NAME);
  
              if (r.CLIENT_HOME_MOBILE)
                name_parts.push(r.CLIENT_HOME_MOBILE);
  
              //if(r.CLIENT_CODE)
              //name_parts.push(`(${r.CLIENT_CODE})`);
  
              r.DISPLAY_NAME = name_parts.join(',');
  
              /*  let temp=name_parts[0];
               name_parts[0]=name_parts[name_parts.length-1];
               name_parts[name_parts.length-1]=temp;
  
               r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
              r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;
  
              r.isNewCustomer = false;
  
  
              this.filteredCustomers.push(r);
            })

          }else{
            response.profile.map(r => {

              r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);
  
              let name_parts = [];
              if (r.CLIENT_FIRST_NAME)
                name_parts.push(r.CLIENT_FIRST_NAME);
  
              if (r.CLIENT_FAMILY_NAME)
                name_parts.push(r.CLIENT_FAMILY_NAME);
  
              if (r.CLIENT_HOME_MOBILE)
                name_parts.push(r.CLIENT_HOME_MOBILE);
  
              //if(r.CLIENT_CODE)
              //name_parts.push(`(${r.CLIENT_CODE})`);
  
              r.DISPLAY_NAME = name_parts.join(',');
  
              /*  let temp=name_parts[0];
               name_parts[0]=name_parts[name_parts.length-1];
               name_parts[name_parts.length-1]=temp;
  
               r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
              r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;
  
              r.isNewCustomer = false;
  
  
              this.filteredCustomers.push(r);
            })
          }

       




        }




      });
  }





  //selected selected customer
  onSelectCustomer(customer: CustomerProfile) {
    this.errors = { ...this.errors, customer_code: false };

    this.selectedCustomer = customer;
    this.quote.customer_code = this.selectedCustomer.CLIENT_CODE
    this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME ? this.selectedCustomer.CLIENT_FIRST_NAME : this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
    this.quote.customer_email = this.selectedCustomer.CLIENT_HOME_EMAIL
    this.quote.date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH
    this.quote.customer_type_code = this.selectedCustomer.CLIENT_TYPE
  }

  
  //customer change event
  onCustomerChange(event) {
    if (!event.target.value) {
      this.selectedCustomer = {
        CLIENT_CODE: "",
        CLIENT_FAMILY_NAME: "",
        CLIENT_HOME_MOBILE: "",
        CLIENT_FIRST_NAME: "",
        CLIENT_HOME_EMAIL: "",
        CLIENT_DATE_OF_BIRTH: "",
        DISPLAY_NAME: "",
        DISPLAY_SHORT_NAME: "",
        isNewCustomer: false
      }
      this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME
      this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
      this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
      this.quote.customer_email = this.selectedCustomer.CLIENT_HOME_EMAIL
      this.quote.date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH
    }
  }
  //open customer creation
  openCustomerCreationDialog() {
    const dialogRef = this.dialog.open(CreateCustomerComponent, {
      disableClose: true,
      width: '800px',
      data: { newCustomerDetails: {} }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data') && result.data.hasOwnProperty('customer_code'))
        this.quote = {
          ...this.quote,
          customer_code: result.data.customer_code,
          type: result.data.type,
          customer_first_name: result.data.customer_first_name,
          customer_other_names: result.data.customer_other_names,
          customer_last_name: result.data.customer_last_name,
          date_of_birth: moment(result.data.date_of_birth).format('YYYY-MM-DD'),
          customer_email: result.data.customer_email,
          customer_phone: result.data.customer_phone,
          digital_address: result.data.digital_address,
          id_type: result.data.id_type,
          id_number: result.data.id_number,
          tin: result.data.tin
        }

      if (this.quote.type == '0')
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_last_name},${this.quote.customer_phone}`;
      else
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_phone}`;
    });
  }
  onLoadProducts() {
    this.products = [];
    this.service.getProducts({ policy_lob: '10' })
      .toPromise()
      .then((response: ProductResponse) => {
        if (response.responseCode == '000') {
          console.log('products>>>>>>', JSON.stringify(response.profile));
          this.products = response.profile
          this.service.swalAlart.close();
        }
      })
      .catch((error: any) => {
        this.service.swalAlart.close();
        //console.log('Error',error);
      })

  }
  onLoadConstructionTypes() {
    this.products = [];
    this.service.getConstructionTypes()
      .toPromise()
      .then((response: ConstructionTypeResponse) => {
        if (response.responseCode == '000') {
          this.constructionTypes = response.responseData;
          //console.log('construction types',this.constructionTypes);
          this.service.swalAlart.close();
        }
      })
      .catch((error: any) => {
        this.service.swalAlart.close();
        console.log('Error', error);
      })
  }

  //additional cover changed
  onlossOfRentChanged(changed: boolean) {
    this.is_loss_of_rent = changed;
  }
  onPersonalAccidentChanged(changed: boolean) {
    this.is_person_accident = changed;
  }
  onPublicLiabilityChanged(changed: boolean) {
    this.is_public_liability = changed;
  }
  onCoverSelected(product_code: string) {
    this.selectedProduct = this.products.find((cover: Product) => cover.PRODUCT_CODE == product_code);
    if (product_code == '108')
      this.buildItems = [];
    else if (this.buildItems.length == 0) {
      this.quote.building_sum = '';
      this.buildItems.push({ value: '', name: '' })
    }
  }

  onConstructionTypeSelected(construction_type: string) {
    this.selectedConstructionType = this.constructionTypes.find((construction: ConstructionType) => construction.TABLED_CODE == construction_type);
  }

  //On Form submit
  onSubmit = (event) => {
    event.preventDefault();

    let validationResponse = this.validateForm();

    if (validationResponse)
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      })
    else {
      this.service.swalAlart.fire({
        title: "Do you want to proceed with quote?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons: true
      })
        .then((dialogResponse) => {
          if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
            //start of processing
            this.processing = true;
            let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();

            this.payload = {
              customer_first_name: this.quote.customer_first_name,
              customer_last_name: this.quote.customer_last_name,
              customer_other_names: this.quote.customer_other_names,
              customer_email: this.quote.customer_email,
              customer_phone: this.quote.customer_phone,
              prepared_by: prepared_by,
              status: 'incomplete',
              module: this.appuser.role.toLowerCase(),
              status_of_client: (this.renewal ? 'renew' : 'new'),
              customer_date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
              customer_branch_code: this.appuser.agency,
              customer_type_code: this.quote.customer_type_code,
              policy_lob: "10",
              platform: "Webapp",
              product: this.quote.cover_type_code,
              policy_agency: this.appuser.agency || '502',
              policy_office: this.appuser.agency,
              fire_gen_desc: this.quote.property_description,
              intermediary_first_name: this.appuser.first_name ? this.appuser.first_name : this.appuser.last_name,
              intermediary_last_name: this.appuser.last_name ? this.appuser.last_name : this.appuser.first_name,
              intermediary_phone: this.appuser.phone,
              intermediary_email: this.appuser.email,
              channel: "",
              staff_id: "",
              staff_email: ""
            }

            if (this.appuser.role == 'agent') {
              this.payload = {
                ...this.payload,
                agent_code: this.appuser.code.toString(),
                client_code: this.quote.customer_code.toString(),
                broker_user_code: '',
              }
            }
            else if (this.appuser.role == 'client') {
              this.payload = {
                ...this.payload,
                agent_code: '',
                client_code: this.appuser.code.toString(),
                customer_first_name: this.appuser.first_name,
                customer_last_name: this.appuser.last_name,
                customer_phone: this.appuser.phone,
                customer_email: this.appuser.email,
                date_of_birth: this.service.strDateToDBFormat(this.appuser.dob),
                broker_user_code: '',
              }
            } else if (this.appuser.role == 'broker-user') {
              this.payload = {
                ...this.payload,
                agent_code: this.appuser.broker_user_main_broker_code.toString(),
                client_code: this.quote.customer_code.toString(),
                broker_user_code: this.appuser.code.toString(),
              }


             } else if (this.appuser.role == 'staff') {
                this.payload.staff_id = this.appuser.code,
                this.payload.staff_email = this.appuser.email,
                this.payload.channel = this.quote.channel,
                this.payload.agent_code = this.sob.agent_code,
                this.payload.client_code = this.quote.customer_code.toString(),
                this.payload.prepared_by = this.appuser.first_name
                this.payload.intermediary_first_name = this.sob.agent_family_name
                this.payload.intermediary_last_name = ''
                this.payload.intermediary_phone = ''
                this.payload.intermediary_email = this.sob.agent_work_email
                this.payload.policy_branch = this.sob.policy_branch_code
                this.payload.policy_agency = this.sob.policy_branch_code
                this.payload.policy_office = this.sob.policy_branch_code

                if (this.quote.channel == '5') {
                  this.payload.agent_code = this.sob.agent_code

                } else if (this.quote.channel == '2') {
                  this.payload.agent_code = this.sob.broker_code

                }

              }





            this.service.swalAlart.showLoading();

            if (!this.renewal && this.quote.edit_mode)
              this.finalizeQuote(this.quote.policy_no)
            else
              this.service.submitFireQuote(this.payload)
                .toPromise()
                .then((response: any) => {
                  if (response.responseCode == '000') {
                    this.service.swalAlart.close();
                    this.finalizeQuote(response.responseData)
                  }
                  else if (response.responseCode == 'E56')
                    this.service.swalAlart.fire({
                      title: 'Error',
                      text: "Sorry, we couldn't find any branch associated with this account. Kindly contact your branch manager.",
                      icon: 'error',
                    });
                  else if (response.responseCode == 'E57')
                    this.service.swalAlart.fire({
                      title: 'Error',
                      text: "Sorry, you cannot renew policy at this branch,kindly contact your branch office",
                      icon: 'error',
                    });
                }).catch(error => {
                  this.processing = false;
                  this.service.swalAlart.close();
                  this.service.swalAlart.fire({
                    title: 'Error',
                    text: 'Server Error,retry later',
                    icon: 'error',
                  });
                })
          }
        })

    }
  }



 //confirm login pin to continue
 confirmPin() {
  let currentUser = this.service.currentUser();
  
  if(currentUser.role == "staff"){

    if (this.selectedMethod == 'Mobile')
      this.openMomoPaymentDialog();
    else if (this.selectedMethod == 'Cash')
      this.openCashPaymentDialog();
    else if (this.selectedMethod == 'Cheque')
      this.openChequePaymentDialog();
    else if (this.selectedMethod == 'Card')
      this.openCardPaymentDialog();


  }else if (currentUser.role == "broker-user") {
    this.loginAsBrokerUser()
  } else {
    this.loginAsAgentClient()
  }
}


  loginAsAgentClient() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          if (currentUser.role == "client") {
            loginPayload = {
              phone: currentUser.phone,
              pin: result.value,
              module: 'client'
            }

          } else if (currentUser.role == "agent") {
            loginPayload = {
              agent_code: currentUser.code,
              pin: result.value,
              module: 'agent'
            }

          } else { }

          swal.showLoading()
          this.service.webLogin(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });

  }

  loginAsBrokerUser() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          loginPayload = {
            broker_user_code: currentUser.code,
            broker_user_pin: result.value,
            module: 'broker-user'
          }

          swal.showLoading()
          this.service.webLoginAsBrokerUser(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });
  }







  //choose payment method
  choosePaymentMethod() {
    const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, method: '', total_premium: this.quote.total_premium, policy_lob: "10" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        this.selectedMethod = result.data.method;

        if (this.selectedMethod)
          this.confirmPin();

          this.validateForm();

      }
    });
  }

  //open cash Payment Dialog
  openMomoPaymentDialog() {
    const dialogRef = this.dialog.open(MomoPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: { policy_sequence: this.payload.policy_sequence, client_code: this.quote.customer_code.toString(), status_of_client: this.payload.status_of_client, currency_code: 'GHS' }, total_premium: this.quote.total_premium, policy_lob: "10" }
    });

  }

  //open card Payment Dialog
  openCardPaymentDialog() {
    const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: { policy_sequence: this.payload.policy_sequence, client_code: this.quote.customer_code.toString(), status_of_client: this.payload.status_of_client, currency_code: 'GHS' }, total_premium: this.quote.total_premium, policy_lob: "10" }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }

    });
  }

  //open cash Payment Dialog
  openCashPaymentDialog() {
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, total_premium: this.quote.total_premium, policy_lob: "10" }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';
        this.validateForm();

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }

    });
  }
  //open Cheque Payment Dialog
  openChequePaymentDialog() {
    const dialogRef = this.dialog.open(ChequePaymentComponent, {
      disableClose: true,
      width: '600px',
      data: { quotePayload: { ...this.payload, currency_code: 'GHS', client_code: this.quote.customer_code.toString() }, total_premium: this.quote.total_premium, policy_lob: "10" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';
        this.validateForm();

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }
    });
    
  }
  finalizeQuote = (policy_no) => {
    this.payload = {
      prepared_by: this.payload.prepared_by,
      policy_sequence: policy_no,
      module: this.appuser.role,
      agent_code: this.payload.agent_code,
      client_code: this.payload.client_code,
      status_of_client: this.renewal ? 'renew' : "new",
      currency_code: this.quote.currency_code,
      fleet: null,
      exchange_rate: parseFloat(this.quote.exchange_rate).toFixed(2).toString(),
      customer_first_name: this.quote.customer_first_name,
      customer_last_name: this.quote.customer_last_name,
      customer_other_names: "",
      customer_email: this.quote.customer_email,
      customer_phone: this.quote.customer_phone,
      customer_date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
      customer_branch_code: this.appuser.agency,
      customer_type_code: this.quote.customer_type_code,
      inception_date: moment(this.quote.start_date).format('DD/MM/YYYY'),
      expiry_date: moment(this.quote.end_date).format('DD/MM/YYYY'),
      product: this.quote.cover_type_code,
      policy_lob: "10",
      building_sum_insured: (this.quote.cover_type_code == '101' || this.quote.cover_type_code == '102') && this.quote.building_sum ? this.quote.building_sum.toString() : 0,
      content_sum_insured: (this.quote.cover_type_code == '102' || this.quote.cover_type_code == '108') && this.quote.content_sum ? this.quote.content_sum.toString() : 0,
      loss_of_rent_value: this.quote.loss_of_rent ? parseFloat(this.quote.loss_of_rent) : 0.00,
      excess_type_code: (this.quote.excess_buy_back ? 'EIB' : 'ENA'),
      tppd_limit: this.quote.tppd_value ? this.quote.tppd_value : "0",
      legacy_policy_sequence: this.quote.legacy_policy_number,
      platform: "Webapp",
      intermediary_type_code: "04",
      intermediary_branch_code: "502",
      policy_agency: this.appuser.agency || '502',
      policy_office: this.appuser.agency,
      additional_pl: this.quote.public_liability ? parseFloat(this.quote.public_liability) : 0.00,
      fire_general_description: this.quote.property_description,
      buildingArray: this.buildingTotal ? this.buildingArray() : [],
      contentArray: this.contentTotal ? this.contentArray() : [],
      fire_construct_type: this.selectedConstructionType.TABLED_DESIGNATION,
      fire_construct_code: this.selectedConstructionType.TABLED_CODE,
      fire_location: this.quote.location,
      fire_gen_desc: this.quote.property_description,
      intermediary_first_name: this.appuser.first_name ? this.appuser.first_name : this.appuser.last_name,
      intermediary_last_name: this.appuser.last_name ? this.appuser.last_name : this.appuser.first_name,
      intermediary_phone: this.appuser.phone,
      intermediary_email: this.appuser.email,
      channel: "",
      staff_id: "",
      staff_email: ""
    }

    if (this.appuser.role == 'agent') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.code.toString(),
        client_code: this.quote.customer_code.toString(),
        broker_user_code: '',
      }
    }
    else if (this.appuser.role == 'client') {
      this.payload = {
        ...this.payload,
        agent_code: '',
        client_code: this.appuser.code.toString(),
        customer_first_name: this.appuser.first_name,
        customer_last_name: this.appuser.last_name,
        customer_phone: this.appuser.phone,
        customer_email: this.appuser.email,
        date_of_birth: this.service.strDateToDBFormat(this.appuser.dob),
        broker_user_code: '',
      }
    } else if (this.appuser.role == 'broker-user') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.broker_user_main_broker_code.toString(),
        client_code: this.quote.customer_code.toString(),
        broker_user_code: this.appuser.code.toString(),
      }

    } else if (this.appuser.role == 'staff') {
      this.payload.staff_id = this.appuser.code,
      this.payload.staff_email = this.appuser.email,
      this.payload.channel = this.quote.channel,
      this.payload.agent_code = this.sob.agent_code,
      this.payload.client_code = this.quote.customer_code.toString(),
      this.payload.prepared_by = this.appuser.first_name
      this.payload.intermediary_first_name = this.sob.agent_family_name
      this.payload.intermediary_last_name = ''
      this.payload.intermediary_phone = ''
      this.payload.intermediary_email = this.sob.agent_work_email
      this.payload.policy_branch = this.sob.policy_branch_code
      this.payload.policy_agency = this.sob.policy_branch_code
      this.payload.policy_office = this.sob.policy_branch_code

      if (this.quote.channel == '5') {
        this.payload.agent_code = this.sob.agent_code

      } else if (this.quote.channel == '2') {
        this.payload.agent_code = this.sob.broker_code

      }

    }



    //end lagacy values for renewals
    if (this.quote.days_label.toLowerCase() == 'months' || this.quote.days_label.toLowerCase() == 'month(s)')
      this.payload = {
        ...this.payload,
        months: this.quote.days
      }
    else
      this.payload = {
        ...this.payload,
        days: this.quote.days
      }


    if (this.quote.term == '01')
      this.payload.duration_type = 'annual';
    else if (this.quote.term == '02')
      this.payload.duration_type = 'shortrate';


    this.service.swalAlart.showLoading();
    this.service.submitFireQuote(this.payload)
      .toPromise()
      .then((response: any) => {
        this.quote.total_premium = response.responseConvertedValue ? response.responseConvertedValue.toString().replace(",", "").trim() : '0';
        this.quote.foreign_currency = response.responseCurrencyValue;
        this.quote.foreign_amount = response.responseData.toString().replace(",", "").trim();

        this.confirmQuote();
        this.service.swalAlart.close();
        this.processing = false;
      }).
      catch(error => {
        this.processing = false;
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Error',
          text: 'Server Error,retry later',
          icon: 'error',
        });
      })
  }
  //building array

  buildingArray = () => {
    let items = [];
    for (let i = 0; i < this.buildItems.length; i++) {
      if (this.buildItems[i].name && this.buildItems[i].value)
        items.push({ item_no: (i + 1), item_desc: this.buildItems[i].name, item_si: this.buildItems[i].value })
    }
    return items;
  }
  //content array
  contentArray = () => {
    let items = [];
    for (let i = 0; i < this.contentItems.length; i++) {
      if (this.contentItems[i].name && this.contentItems[i].value)
        items.push({ item_no: (i + 1), item_desc: this.contentItems[i].name, item_si: this.contentItems[i].value })
    }
    return items;
  }
  //run validation
  validateForm(input?: any): boolean {
    let hasError = false;
    this.errors = {
      customer_code: false,
      customer_phone: false,
      date_of_birth: false,

      cover_type_code: false,
      schedule_code: false,
      start_date: false,
      end_date: false,

      currency_code: false,
      customer_email: false,
      loss_of_rent: false
    }

    if (!this.quote.customer_code) {
      hasError = true;
      this.errors = { ...this.errors, customer_code: true };
    }
    if (isNaN(+`${this.quote.customer_phone}`) || !this.service.validatePhone(this.quote.customer_phone)) {
      hasError = true;
      this.errors = { ...this.errors, customer_phone: true };
    }
    if (!this.quote.currency_code || (this.quote.currency_code && this.quote.currency_code.trim().length == 0)) {
      hasError = true;
      this.errors = { ...this.errors, currency_code: true };
    }

    if (!this.quote.cover_type_code) {
      hasError = true;
      this.errors = { ...this.errors, cover_type_code: true };
    }

    if (!this.quote.construction_type) {
      hasError = true;
      this.errors = { ...this.errors, construction_type: true };
    }

    if (!this.quote.property_description) {
      hasError = true;
      this.errors = { ...this.errors, property_description: true };
    }

    if (this.quote.customer_email && !this.service.validateEmail(this.quote.customer_email)) {
      hasError = true;
      this.errors = { ...this.errors, customer_email: true };
    }

    if (!this.quote.location) {
      hasError = true;
      this.errors = { ...this.errors, location: true };
    }

    if (!this.quote.date_of_birth) {
      hasError = true;
      this.errors = { ...this.errors, date_of_birth: true };
    }

    if (!(moment(this.quote.start_date).isValid())) {
      hasError = true;
      this.errors = { ...this.errors, start_date: true };
    }

    if (!(moment(this.quote.end_date).isValid())) {
      hasError = true;
      this.errors = { ...this.errors, end_date: true };
    }

    if (!this.quote.location) {
      hasError = true;
      this.errors = { ...this.errors, location: true };
    }

    if (!this.quote.construction_type) {
      hasError = true;
      this.errors = { ...this.errors, construction_type: true };
    }

    if (this.quote.property_description && !this.quote.property_description) {
      hasError = true;
      this.errors = { ...this.errors, property_description: true };
    }

    if (this.quote.start_date && !this.quote.start_date) {
      hasError = true;
      this.errors = { ...this.errors, start_date: true };
    }

    if (this.quote.end_date && !this.quote.end_date) {
      hasError = true;
      this.errors = { ...this.errors, end_date: true };
    }

    if ((this.quote.cover_type_code == '101' || this.quote.cover_type_code == '102') && !this.quote.building_sum) {
      hasError = true;
      this.errors = { ...this.errors, building_sum: true };
    }
    else if (this.quote.content_sum && this.contentTotal && this.contentTotal.toString() != this.quote.content_sum) {
      hasError = true;
      this.errors = { ...this.errors, content_sum: true, content_items: true };
      this.alertSnackBar("Total sum of content items should be equal to the sum insured.", "Content Items Validation");
    }

    if (['108', '102'].includes(this.quote.cover_type_code) && !this.quote.content_sum) {
      hasError = true;
      this.errors = { ...this.errors, content_sum: true };
    }
    else if (this.quote.building_sum && this.buildingTotal && this.buildingTotal.toString() != this.quote.building_sum) {
      hasError = true;
      this.errors = { ...this.errors, building_sum: true, building_items: true };

      this.alertSnackBar("Total Building Items Total must be equal to Building Sum Insured.", "Building Items Validation");
    }

    if ((this.quote.cover_type_code == '101' || this.quote.cover_type_code == '102') && this.quote.lost_of_rent && this.quote.building_sum && parseFloat(this.quote.lost_of_rent) > (0.1 * parseFloat(this.quote.building_sum))) {
      this.errors = { ...this.errors, building_sum: true, loss_of_rent: true };
      this.alertSnackBar(`Value for Loss of Rent cannot be more than 10% of the building value of ${this.quote.building_sum}`, "Lost of Rent Validation");

    }


    return hasError;
  }
  //trigger the snackbar
  alertSnackBar(message: string, action: string, duration: number = 5000) {
    this._snackBar.open(message, action, {
      duration: duration,
    });
  }

  //on lost of rent input
  lost_of_rent_change($event) {
    this.quote.lost_of_rent = $event.target.value;
    if ((this.quote.cover_type_code == '101' || this.quote.cover_type_code == '102') && this.quote.lost_of_rent && this.quote.building_sum && parseFloat(this.quote.lost_of_rent) > (0.1 * parseFloat(this.quote.building_sum))) {
      this.alertSnackBar(`Value for Loss of Rent cannot be more than 10% of the building value of ${this.quote.building_sum}`, "Lost of Rent Validation", 2000);
      this.errors = { ...this.errors, building_sum: true, loss_of_rent: true };
    }
  }

  //prevent under age
  dobFilter(date: Date) {
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'), 'years') >= 18;
  }
  //confirm quote
  confirmQuote() {
    let summary = [
      { label: 'Date Prepared', value: moment().format('DD/MM/YYYY') },

      { label: 'Prepared By', value: this.payload.prepared_by },

      { label: 'Customer Name', value: `${this.payload.customer_first_name} ${this.payload.customer_last_name}` },

      { label: 'Customer Email', value: this.payload.customer_email },

      { label: 'Customer Code', value: this.payload.client_code },

      { label: 'Mobile Number', value: this.quote.customer_phone },

      { label: 'Product Plan', value: this.selectedProduct.PRODUCT_DESC },

      { label: 'Location of Building/Proprty', value: this.payload.fire_location },

      { label: 'Construction Type', value: this.payload.fire_construct_type },

      { label: 'Duration', value: (this.payload.duration_type.toLowerCase() == 'annual' ? 'One Year(365)' : this.payload.duration_type) },

      { label: 'Building Sum Insured', value: (this.payload.building_sum_insured ? this.payload.building_sum_insured : 0) },

      { label: 'Content Sum Insured', value: this.payload.content_sum_insured ? this.payload.content_sum_insured : 0 },

      { label: 'Loss of Rent', value: this.payload.loss_of_rent_value },

      { label: 'Public Liability', value: this.payload.additional_pl },

      { label: 'Start Date', value: this.payload.inception_date },

      { label: 'End Date', value: this.payload.expiry_date }
    ];

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        quote: summary,
        confirmedQuote: true,
        total_premium: this.quote.total_premium,
        foreign_currency: this.quote.foreign_currency,
        foreign_amount: this.quote.foreign_amount,
        flow: 'fire',
        hide_foreign_currency: 0
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        if (this.confirmedQuote)
          this.choosePaymentMethod();
      }
    });
  }

  //on building item value typed
  buildingTotal: number = 0;
  onBuildingItem(index: number, $event) {
    this.buildingTotal = 0;
    let building_sum = this.quote.building_sum ? parseFloat(this.quote.building_sum) : 0;
    for (let i = 0; i < this.buildItems.length; i++)
      this.buildingTotal += this.buildItems[i].value ? parseFloat(this.buildItems[i].value) : 0;

    if (this.buildingTotal > building_sum)
      this.buildItems[this.buildItems.length - 1].value = '';
  }
  //on building item value typed
  contentTotal: number = 0;
  onContentItem(index: number, $event) {
    this.contentTotal = 0;
    let content_sum = this.quote.content_sum ? parseFloat(this.quote.content_sum) : 0;
    for (let i = 0; i < this.contentItems.length; i++)
      this.contentTotal += this.contentItems[i].value ? parseFloat(this.contentItems[i].value) : 0;

    if (this.contentTotal > content_sum)
      this.contentItems[this.contentItems.length - 1].value = '';
  }




  transactionStatus(){
    let payload: any = {}

    if(this.appuser.role=='agent'){
      payload={
        agent_code:this.appuser.code,
        client_code:this.quotePayloadBeforePayment.client_code,
        module:'agent',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      }
    }else if(this.appuser.role=='broker-user'){
      payload={
        agent_code:this.appuser.broker_user_main_broker_code,
        broker_user_code:this.appuser.code,
        client_code:this.quotePayloadBeforePayment.client_code,
        module:'broker-user',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      
      }
  
    }else if(this.appuser.role=='client'){
      payload={
        client_code:this.appuser.code,
        module:'client',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      }

    }else{}
   

    this.service.swalAlart.showLoading()
    this.service.manualQuery(payload)
    .toPromise()
    .then((response:any)=>{
      this.service.swalAlart.close()
        if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
          this.service.swalAlart.close();

          console.log("policy_lob===> " + payload.policy_lob);
        
          if(payload.policy_lob === "20"){
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: response.hasOwnProperty('stickerUrl')? `SMS and Email sent with Policy details to print<br><br>Kindly print <a target='_blank' href='${response.stickerUrl}'>Sticker</a> and <a target='_blank' href='${response.certificateUrl}'>Certificate</a>`:`Payment completed successfully;your certificate has been mailed to you!<br><br/>Kindly click the <a target='_blank' href='${response.certificateUrl}'>Preview</a> link to view certificate`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            .then((result)=>{
            if(result){
              this.router.navigate(['transactions']);
              this.dialogRef.close()
            }
              
            });
          }else if (payload.policy_lob === "50"){
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a><br><br><a target='_blank' href='${response.travelDocUrl}'>Click here to print <b>travel documents</b></a>`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close();
              }
             });

          }else{
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a>`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close();
                
              }
             });
             
          }

       

        }else if(response.hasOwnProperty('responseCode') && response.responseCode=='E55'){

            this.openfinalizeMomoRetryPaymentDialog(payload)
            this.dialogRef.close();
          

        }else if (response.hasOwnProperty('responseCode') && response.responseCode=='001'){
          localStorage.removeItem('quotePayload')
          
          this.service.swalAlart.close();
          this.service.swalAlart.fire({
            title: 'Processing Payment...',
            html: 'Please wait. An sms and email of policy and sticker details will be sent to you after processing payment.',
            icon: 'success',
            confirmButtonText:'Done',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result)=>{
            if(result){
              this.router.navigate(['transactions']);
              this.dialogRef.close();
            }
           });

      }else if (response.hasOwnProperty('responseCode') && response.responseCode=='500'){
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Failed',
          text: 'Server error',
          icon: 'error',
        });
      }
      else{

      }
    })
  }

     
  openfinalizeMomoRetryPaymentDialog(payload){

    const dialogRef = this.dialogRetryRef.open(FinalizeRetryMomoComponent, {
      disableClose :true,
      width: '350px',
      data: { quotePayload: payload}
    });
    dialogRef.afterClosed().subscribe((result:any) => {

      if(result.hasOwnProperty('data')){
        result.data.quotePayload.module=this.service.currentUser().role.toLowerCase();
        //console.log('Data',result.data)
      }
    });

  }





  onLoadAgentLOV() {
    this.filteredAgentLOV = [];
    this.AgentLOV = [];

    this.service.getAgents({})
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          //      console.log("companyData>>>> " + JSON.stringify(response.responseData))

          response.responseData.map((response: agentLOVData) => {
            let agent: agentLOVData = { ...response, agent_code: `${response.agent_code}` };
            this.AgentLOV.push(agent);
            this.filteredAgentLOV.push(agent);
          })
        }
      });

  }

  onFilterAgentLOV() {
    this.sob_agent_code.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredAgentLOV = [];
          this.isAgentLOVLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              let payload = this.AgentLOV.filter((response: agentLOVData) => response.agent_code.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)


            })
              .pipe(
                finalize(() => {
                  this.isAgentLOVLoading = false
                }),
              )
          }
          else {
            this.isAgentLOVLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: agentLOVData) => {

            this.filteredAgentLOV.push({ ...response, agent_code: `${response.agent_code}` });
          })
      })
  }



  onAgentLOVChange(event) {
    if (!event.target.value) {
      console.log("sob.agent_code>>>>" + event.target.value)

    } else {

    }

  }


  onSelectAgent(agent: agentLOVData) {
    console.log("onSelectAgent>>>>> " + JSON.stringify(agent))
    this.sob.agent_code = agent.agent_code
    this.sob.agent_family_name = agent.agent_family_name
    this.sob.agent_work_email = agent.agent_work_email
    this.sob.policy_branch_code = agent.agent_agency

  }


  onLoadBrokerLOV() {
    this.filteredBrokerLOV = [];
    this.BrokerLOV = [];

    this.service.getBrokers({})
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          //      console.log("companyData>>>> " + JSON.stringify(response.responseData))

          response.responseData.map((response: brokerLOVData) => {
            let agent: brokerLOVData = { ...response, agent_code: `${response.agent_code}` };
            this.BrokerLOV.push(agent);
            this.filteredBrokerLOV.push(agent);
          })
        }
      });

  }

  onFilterBrokerLOV() {
    this.sob_broker_code.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredBrokerLOV = [];
          this.isBrokerLOVLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              let payload = this.BrokerLOV.filter((response: brokerLOVData) => response.agent_code.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isBrokerLOVLoading = false
                }),
              )
          }
          else {
            this.isBrokerLOVLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: agentLOVData) => {

            this.filteredBrokerLOV.push({ ...response, agent_code: `${response.agent_code}` });
          })
      })
  }

  onBrokerLOVChange(event) {
    if (!event.target.value) {
      console.log("sob.agent_code>>>>" + event.target.value)

    } else {

    }

  }

  onSelectBroker(broker: brokerLOVData) {
    this.sob.broker_code = broker.agent_code
    this.sob.agent_family_name = broker.agent_family_name
    this.sob.agent_work_email = broker.agent_work_email
    this.sob.policy_branch_code = broker.agent_agency
  }



  onLoadBranchLOV() {
    this.filteredBranchLOV = [];
    this.BranchLOV = [];

    let payload = {
      staff_id: this.appuser.code
    }

    this.service.getClientBranches(payload)
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.responseData.map((response: branchLOVData) => {
            let branch: branchLOVData = { ...response, user_branch_code: `${response.user_branch_code}` };
            this.BranchLOV.push(branch);
            this.filteredBranchLOV.push(branch);
          })
        }
      });

  }

  onFilterPolicyBranchLOV() {
    this.sob_policy_branch_code.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredBranchLOV = [];
          this.isBranchLOVLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              let payload = this.BranchLOV.filter((response: branchLOVData) => response.user_branch_code.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isBranchLOVLoading = false
                }),
              )
          }
          else {
            this.isBranchLOVLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: branchLOVData) => {

            this.filteredBranchLOV.push({ ...response, user_branch_code: `${response.user_branch_code}` });
          })
      })
  }

  onPolicyBranchLOVChange(event) {
    if (!event.target.value) {
      console.log("policy branch>>>>" + event.target.value)

    } else {

    }

  }

  onSelectPolicyBranch(branch: branchLOVData) {
    console.log("onSelectPolicyBranch")
    this.sob.policy_branch_code = branch.user_branch_code
    this.sob.policy_branch_name = branch.user_branch_desc

    console.log("this.sob.policy_branch_code>>>>" + this.sob.policy_branch_code)

  }



  onUserTypeSelected(sob) {
    console.log("sourceOfBusiness>>>> " + sob)

    this.sob_agent_code.setValue('')
    this.sob_broker_code.setValue('')
    this.sob_policy_branch_code.setValue('')
    this.sob.agent_code = '';
    this.sob.client_code = '';
    this.sob.broker_code = '';
    this.sob.agent_family_name = '';
    this.sob.agent_work_email = '';
    this.sob.policy_branch_code = '';

  }



}