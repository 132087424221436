import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AgentSignUpComponent } from '../modules/dialogs/agent-sign-up/agent-sign-up.component';
import { InfoAlertComponent } from '../modules/dialogs/info-alert/info-alert.component';
import { SelectProductDialogComponent } from '../modules/dialogs/select-product-dialog/select-product-dialog.component';
import { SelectUserTypeComponent } from '../modules/dialogs/select-user-type/select-user-type.component';
import { SignupComponent } from '../modules/dialogs/signup/signup.component';
import { QuickFireQuoteComponent } from '../quick-fire-quote/quick-fire-quote.component';
import { QuickMotoQuoteComponent } from '../quick-moto-quote/quick-moto-quote.component';
import { QuickTravelQuoteComponent } from '../quick-travel-quote/quick-travel-quote.component';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})


export class HomePageComponent implements OnInit {

  constructor(
    private router: Router,
    public dialog: MatDialog,
  ) { }


  ngOnInit() { }

  logIn() {
    this.router.navigate(["/login"])
  }

  signUp() {
    this.openUserTypeDialog()
  }

  activate() {
    this.router.navigate(["/activate-account"])
  }



  openUserTypeDialog() {
    const dialogRef = this.dialog.open(SelectUserTypeComponent, {
      disableClose: true,
      width: '300px',
      data: { user_type: 'client' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data') && result.data.user_type == 'client')
        this.openSignUpDialog();
      else if (result.hasOwnProperty('data') && result.data.user_type == 'agent')
        this.openAgentSignUpDialog();
    });
  }

  openSignUpDialog() {
    const dialogRef = this.dialog.open(SignupComponent, {
      disableClose: true,
      width: '860px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //if(result.hasOwnProperty('data')){}
    });
  }

  openAgentSignUpDialog() {
    const dialogRef = this.dialog.open(AgentSignUpComponent, {
      disableClose: true,
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //if(result.hasOwnProperty('data')){}
    });
  }


  selectProduct(){
    // return ;
    const dialogRef = this.dialog.open(SelectProductDialogComponent, {
      disableClose :true,
      width: '300px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.data && result.data['product']=='Motor Insurance')
        this.openQuickMotoDialog();
      else if(result.data && result.data['product']=='Travel Insurance')
       this.openQuickTravelDialog();
      else if(result.data && result.data['product']=='Fire Insurance')
       this.openQuickFireDialog();
    });
  }


    //open Quick Motor Quote Dialog
    openQuickMotoDialog(){
      const dialogRef = this.dialog.open(QuickMotoQuoteComponent, {
        disableClose :true,
        width: '510px',
        data: { }
      });
  
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  
    //open Quick Travel Quote Dialog
    openQuickTravelDialog(){
      const dialogRef = this.dialog.open(QuickTravelQuoteComponent, {
        disableClose :true,
        width: '510px',
        data: { }
      });
  
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  
    //open Quick Fire Quote Dialog
    openQuickFireDialog(){
      const dialogRef = this.dialog.open(QuickFireQuoteComponent, {
        disableClose :true,
        width: '510px',
        data: { }
      });
  
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  

    buyInfoAlert() {
      const dialogRef = this.dialog.open(InfoAlertComponent, {
        disableClose: false,
        width: '280px',
        data: { 
          dialogTitle: 'Buy Policy',
          dialogMessage: 'Log into your account to buy policy'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
  
    }

    renewInfoAlert() {
      const dialogRef = this.dialog.open(InfoAlertComponent, {
        disableClose: false,
        width: '280px',
        data: { 
          dialogTitle: 'Renew Policy',
          dialogMessage: 'Log into your account to renew policy'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
  
    }


}
