import { Component, OnInit,  ViewChild } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-claimsportal',
  templateUrl: './claimsportal.component.html',
  styleUrls: ['./claimsportal.component.scss']
})
export class ClaimsportalComponent implements OnInit {

  userdata;
  claimsinfo:any=[];
  searchdata;

  dataSource = new MatTableDataSource();
  displayedColumns = ['no' ,'clientID','policy_no', 'firstName', 'phone', 'Description','email','date_added']

  constructor(private service:DashboardService, private router:Router) { }


  @ViewChild(MatPaginator) paginator: MatPaginator;

  length: number;
  pageSize: number= 10;
  pageSizeOptions = [ 10, 20,50,100];


  ngOnInit() {

    this.userdata=JSON.parse(localStorage.getItem('userdata'))
    this.service.claimProcess(this.userdata.email, this.userdata.role).subscribe(data=>{
      //console.log(data);
     
      
      this.claimsinfo = data['responseData'];
      this.dataSource.data = this.claimsinfo;
      this.dataSource.paginator = this.paginator;
      //this.searchdata = this.quotes;
    })

  }

  search (query: string){
    this.searchdata = (query) ?this.claimsinfo.filter( data => data.client_id.toLowerCase().includes(query.toLowerCase()) ||data.policy_no.toLowerCase().includes(query.toLowerCase()) || data.lastName.toLowerCase().includes(query.toLowerCase()) || data.phone.toUpperCase().includes(query.toUpperCase())) : this.claimsinfo;
  }

    opendocument(documentname:any){
      swal.showLoading()
      swal.fire({
       icon: 'success',
        text: 'Loading documents completed',
        timer: 1000,
       showConfirmButton: false
      });
      window.open('https://smartcover-api-dev.azurewebsites.net/smartCover/api/claims/load-image/'+documentname)
    
  }

}
