import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';
import * as moment from 'moment';
import swal, { SweetAlertResult } from 'sweetalert2'
import Swal from 'sweetalert2'
import { CashPaymentComponent } from '../dialogs/cash-payment/cash-payment.component';
import { TravelPolicyExtension } from '../dialogs/travel-policy-extension/travel-policy-extension.component';
import { SelectUserTypeComponent } from '../dialogs/select-user-type/select-user-type.component';
import { UpdateTravelOptions } from '../dialogs/update-travel-dialog/update-travel-dialog.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import { ChequePaymentComponent } from '../dialogs/cheque-payment/cheque-payment.component';
import { DebitCardPaymentComponent } from '../dialogs/debit-card-payment/debit-card-payment.component';
import { MomoPaymentComponent } from '../dialogs/momo-payment/momo-payment.component';

interface CustomerProfile {
  CLIENT_CODE: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?: boolean;
}
interface CustomerResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}
@Component({
  selector: 'app-my-policies',
  templateUrl: './my-policies.component.html',
  styleUrls: ['./my-policies.component.scss']
})
export class MyPoliciesComponent implements OnInit {

  userdata;
  agent_id;
  policies: any = [];
  searchdata;

  selectedCustomer: CustomerProfile;
  filteredCustomers: CustomerProfile[];
  isCustomerLoading: boolean;

  searchCustomerCtrl = new FormControl();

  length: number = 0
  pageIndex: number = 0
  pageSize: number = 5
  pageSizeOptions = [5, 10, 20, 50, 100]
  pageChange = false;

  dataSource = new MatTableDataSource();
  displayedColumns = ['no', 'policy_sequence', 'customer_first_name', 'product_description', 'duration_type', 'total_premium', 'inception_date', 'expiry_date', 'date_updated', 'view', 'update', 'hold', 'cancel']

  payload: any = {
    module: "agent",
    page_size: "5",
    current_page: "0"
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  appuser: any;

  selectedMethod = '';
  confirmedQuote: boolean;

  constructor(
    private service: DashboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar) {

    this.appuser = this.service.currentUser();
    console.log("user-role:", this.appuser.role)

    // this.todayPolicies();
  }

  ngOnInit() {
    this.userdata = this.service.currentUser()
    this.agent_id = this.userdata.code;

    this.isCustomerLoading = false;


    if (this.userdata.role === 'client') {
      this.payload = {
        ...this.payload,
        module: 'client',
        client_code: this.userdata.code
      }
    } else if (this.userdata.role === 'agent') {
      this.payload = {
        ...this.payload,
        module: 'agent',
        agent_code: this.userdata.code
      }
    } else if (this.userdata.role === 'broker-user') {
      this.payload = {
        ...this.payload,
        module: 'broker-user',
        broker_user_code: this.userdata.code
      }
      this.displayedColumns = ['no', 'policy_sequence', 'customer_first_name', 'product_description', 'duration_type', 'total_premium', 'inception_date', 'expiry_date', 'date_updated', 'view', 'update', 'hold', 'cancel']

    } else if (this.userdata.role === 'broker-admin') {
      this.payload = {
        ...this.payload,
        module: 'agent',
        agent_code: this.userdata.broker_admin_main_code
      }
      this.displayedColumns = ['no', 'policy_sequence', 'customer_first_name', 'product_description', 'duration_type', 'total_premium', 'inception_date', 'expiry_date', 'date_updated', 'view', 'update', 'hold', 'cancel']


    } else if(this.appuser.role==='staff'){
      this.payload={
        ...this.payload,
        module:'staff',
        staff: {
          staff_id: this.appuser.code,
          staff_email: this.appuser.email
        }
      }
    }
    
    else { }


    this.service.swalAlart.showLoading();
    this.onMenuFilter('today');

  }




  //on Load policies
  loadPolicies = () => {
    if (this.userdata.role === 'client') {
      this.payload = {
        ...this.payload,
        module: 'client',
        client_code: this.userdata.code
      }
    } else if (this.userdata.role === 'agent') {
      this.payload = {
        ...this.payload,
        module: 'agent',
        agent_code: this.userdata.code
      }
    } else if (this.userdata.role === 'broker-user') {
      this.payload = {
        ...this.payload,
        module: 'broker-user',
        broker_user_code: this.userdata.code
      }
    } else { }


    this.service.swalAlart.showLoading();
    //this.service.getPolicyInfo(this.payload, "today")
    this.service.getPolicyInfo(this.payload, this.selectedMenuFilter.value)
      .toPromise()
      .then((data: any) => {

        console.log("content>>>" + data.pageData.content)
        this.policies = data.pageData.content;
        this.dataSource.data = data.pageData.content;
        this.pageSize = data.pageData.size;
        this.length = data.pageData.totalElements;

        this.searchdata = data.pageData.content;
        // this.dataSource.paginator = this.paginator;

        this.service.swalAlart.close();
      }).catch(error => {
        this.service.swalAlart.close();

        this.service.swalAlart.fire({
          title: 'Loading failed',
          text: 'Could not load my policies',
          icon: 'error'
        })
      })

  }






  //on change of policies filter
  selectedMenuFilter: any = {
    value: "today", label: "Today's Policies"
  }

  onMenuFilter(value) {
    if (value == 'today') {
      this.selectedMenuFilter = {
        value: "today", label: "Today's Policies"
      }
      //this.displayedColumns=this.displayedColumns.filter(item=>item!=='policy_sequence')
    }
    else {
      this.selectedMenuFilter = {
        value: "all", label: "All Policies"
      }
      //this.displayedColumns.splice(1, 0, 'policy_sequence')
    }

    console.log('Menu Item', this.selectedMenuFilter.value)

    this.loadPolicies()
  }

  search(query: string) {
    this.searchdata = (query) ? this.policies.filter(data => data.client_id.toLowerCase().includes(query.toLowerCase()) || data.last_name.toLowerCase().includes(query.toLowerCase()) || data.date_updated.toLowerCase().includes(query.toLowerCase()) || data.created_by.toLowerCase().includes(query.toLowerCase()) || data.email.toUpperCase().includes(query.toUpperCase())) : this.policies;
  }





  openQuoteSummaryDialog(policy_no: string) {

    const selectedPolicy = this.policies.find((r: any) => r.policy_sequence === policy_no);

    console.log('selected Quote', selectedPolicy)
    localStorage.setItem('motor-quote', JSON.stringify(selectedPolicy))


    if (selectedPolicy.policy_lob == '20') {
      if (this.selectedMenuFilter.value == "today") {
        localStorage.setItem('quote_activity', 'policy_info_temp')
      } else {
        localStorage.setItem('quote_activity', 'policy_info')
      }

      this.router.navigate(["/fleet-motor-quote"], {
        queryParams: {
          previous: 'policies'
        },
        queryParamsHandling: 'merge',
        skipLocationChange: false
      });

    } else if (selectedPolicy.policy_lob == '50') {
      this.openTravelSummary(selectedPolicy);

    } else if (selectedPolicy.policy_lob == '10') {
      this.openFireSummary(selectedPolicy);

    }


  }



  openFireSummary(selectedQuote) {
    let summary = [
      { label: 'Date Prepared', value: selectedQuote.date_updated },
      { label: 'Prepared By', value: selectedQuote.prepared_by },
      { label: 'Customer Name', value: `${selectedQuote.customer_first_name} ${selectedQuote.customer_last_name}` },
      { label: 'Customer Email', value: selectedQuote.customer_email },
      { label: 'Customer Code', value: selectedQuote.client_code },
      { label: 'Mobile Number', value: selectedQuote.customer_phone },
      { label: 'Product Plan', value: selectedQuote.product_description.product_description },
      { label: 'Location of Building/Proprty', value: selectedQuote.FIRE_LOCATION },
      { label: 'Construction Type', value: selectedQuote.FIRE_CONSTRUCT_TYPE_DESC },
      { label: 'Duration', value: (selectedQuote.duration_type == 'annual' ? 'One Year(365)' : selectedQuote.duration_type) },
      { label: 'Building Sum Insured', value: (selectedQuote.BUILDING_SUM_INSURED ? selectedQuote.BUILDING_SUM_INSURED : 0) },
      { label: 'Content Sum Insured', value: (selectedQuote.CONTENT_SUM_INSURED ? selectedQuote.CONTENT_SUM_INSURED : 0) },
      { label: 'Lost of Rent', value: selectedQuote.LOSS_OF_RENT_VALUE },
      { label: 'Public Liability', value: selectedQuote.FIRE_ADD_PL },
      { label: 'Start Date', value: selectedQuote.inception_date },
      { label: 'End Date', value: selectedQuote.expiry_date }
    ];

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'fire',
        hide_foreign_currency: 0,
        quote: summary,

        total_premium: selectedQuote.total_premium_cv,
        foreign_currency: selectedQuote.currency_code,
        foreign_amount: selectedQuote.total_premium,
        confirmedQuote: false
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  openTravelSummary(selectedQuote) {
    let summary = [
      { label: 'Date Prepared', value: selectedQuote.date_updated },
      { label: 'Prepared By', value: selectedQuote.prepared_by },
      { label: 'Customer Name', value: `${selectedQuote.customer_first_name} ${selectedQuote.customer_last_name}` },
      { label: 'Customer Email', value: selectedQuote.customer_email },
      { label: 'Customer Code', value: selectedQuote.client_code },
      { label: 'Mobile Number', value: selectedQuote.customer_phone },
      { label: 'ID Type', value: 'Passport' },
      { label: 'ID Number', value: selectedQuote.quote_objects[0].passport_num },
      { label: 'Plan Type', value: selectedQuote.product_description.product_description },
      //{label:'Coverage',value:''},
      { label: 'Destination', value: selectedQuote.quote_objects[0].destination_country },
      { label: 'Date of Departure', value: selectedQuote.inception_date },
      { label: 'Date of Return', value: selectedQuote.expiry_date }
    ]

    //console.log('summary',summary)
    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'travel',
        hide_foreign_currency: (selectedQuote.product_description.product_description == 'VANGUARD SAFE TRAVEL (VST)' ? 1 : 0),
        quote: summary,

        total_premium: selectedQuote.total_premium_cv,
        foreign_currency: selectedQuote.currency_code,
        foreign_amount: selectedQuote.total_premium,
        confirmedQuote: false
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }




  editQuoteSummaryDialog(policy_no: string) {
    const selectedQuote = this.policies.find((r: any) => r.policy_sequence == policy_no);

    if (selectedQuote.POLICY_LOB == '10')
      this.router.navigate(['/firequote'], { state: { editableQuote: selectedQuote } });
    else if (selectedQuote.DURATION_TYPE == 'Travel Term')
      this.router.navigate(['/travelquote'], { state: { editableQuote: selectedQuote } });
    else
      this.router.navigate(['/motorquote'], { state: { editableQuote: selectedQuote } });

  }



  handlePageChange(event) {
    console.log("pageIndex>>>> " + event.pageIndex)
    console.log("pageSize>>>> " + event.pageSize)

    // this.pageIndex = event.pageIndex
    // this.pageSize = event.pageSize

    this.payload.page_size = event.pageSize
    this.payload.current_page = event.pageIndex

    this.pageChange = true;
    this.loadPolicies();

  }



  cancelTravelPolicy(policy_no: string) {
    console.log("policy_sequence ===> " + policy_no)

    swal.fire({
      title: '',
      text: 'Do you want to refund this policy?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          const requestPayload = {
            policy_sequence: policy_no
          }

          swal.showLoading()
          this.service.cancelTravelPolicy(requestPayload)
            .toPromise()
            .then((data: any) => {
              swal.hideLoading()

              if (data.hasOwnProperty('responseCode') && data.responseCode === '000') {
                swal.fire({
                  title: '',
                  text: 'Travel policy refund successful',
                  icon: 'success',
                })
                  .then((result: SweetAlertResult) => {
                    if (result.value) {
                      this.service.swalAlart.showLoading();
                      this.onMenuFilter('today');
                    }
                  })

              } else {
                swal.fire({
                  title: '',
                  text: data.responseMessage,
                  icon: 'error',
                });
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Failed to cancel policy',
                icon: 'error',
              });
            })

        } else {
          result.dismiss === swal.DismissReason.cancel
        }

      })


  }


  updateTravelPolicy(policy_no: string) {

    const dialogRef = this.dialog.open(UpdateTravelOptions, {
      disableClose: false,
      width: '373px',
      data: { module: '' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("updateOption===>" + JSON.stringify(result))

      if (result.updateOption == "Change policy information") {
        const selectedQuote = this.policies.find((r: any) => r.policy_sequence == policy_no);
        if (selectedQuote.policy_lob == '50') {
          this.router.navigate(['/travelquote'], {
            state: { editableQuote: selectedQuote },
            queryParams: {
              previous: 'policies'
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false
          });
        }

      } else if (result.updateOption == "Extend date of arrival") {
        //Extend date of arrival
        const selectedQuote = this.policies.find((r: any) => r.policy_sequence == policy_no);
        const dialogRef = this.dialog.open(TravelPolicyExtension, {
          disableClose: true,
          width: '350px',
          data: {
            dateChange: 'extendArrival',
            currentArrivalDate: selectedQuote.expiry_date
          },
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(moment(result.data.end_date).format('DD/MM/YYYY'))

          let requestPayload = {
            "policy_sequence": policy_no,
            "expiry_date": moment(result.data.end_date).format('DD/MM/YYYY')
          }

          Swal.showLoading()
          this.service.extendPolicy(requestPayload)
            .toPromise()
            .then((res: any) => {
              Swal.close()

              if (res.responseCode == "000") {

                if (res.responseData == '0.00') {
                  Swal.fire({
                    title: 'Successful',
                    html: `Date of arrival extended.<br>Extension Amount: $ ${res.responseData}<br><br><a target='_blank' href='${res.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${res.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a>`,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false

                  }).then((result: SweetAlertResult) => {
                    if (result.value) {

                      this.service.swalAlart.showLoading();
                      this.onMenuFilter('today');

                    }
                  })

                } else {


                  Swal.fire({
                    title: 'Successful',
                    html: 'Date of arrival extended, make payment to confirm policy extension<br>New Premium: $' + res.responseData,
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, proceed',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false

                  }).then((result: SweetAlertResult) => {
                    if (result.value) {
                      this.payPremium(policy_no, res.policy_sequence, res.responseConvertedValue)

                      // this.service.swalAlart.showLoading();
                      // this.onMenuFilter('today');

                    }
                  })

                }

              } else {
                Swal.fire({
                  title: 'Failed',
                  text: res.responseMessage,
                  icon: 'error',
                });
              }


            }).catch(error => {
              Swal.fire({
                title: 'Connection Failed!',
                text: error,
                icon: 'error',
              });
            })

        });


      } else {

        //Reduce date of arrival
        const selectedQuote = this.policies.find((r: any) => r.policy_sequence == policy_no);
        const dialogRef = this.dialog.open(TravelPolicyExtension, {
          disableClose: true,
          width: '350px',
          data: {
            dateChange: 'reduceArrival',
            currentArrivalDate: selectedQuote.expiry_date
          },
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(moment(result.data.end_date).format('DD/MM/YYYY'))

          let requestPayload = {
            "policy_sequence": policy_no,
            "expiry_date": moment(result.data.end_date).format('DD/MM/YYYY')
          }

          Swal.showLoading()
          this.service.reducePolicy(requestPayload)
            .toPromise()
            .then((res: any) => {
              Swal.close()

              if (res.responseCode == "000") {
                Swal.fire({
                  title: 'Successful',
                  html: `Date of arrival reduced.<br>Refund Amount: $ ${res.responseData}<br><br><a target='_blank' href='${res.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${res.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a>`,
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  allowEscapeKey: false

                }).then((result: SweetAlertResult) => {
                  if (result.value) {

                    this.service.swalAlart.showLoading();
                    this.onMenuFilter('today');

                  }
                })

              } else {
                Swal.fire({
                  title: 'Failed',
                  text: res.responseMessage,
                  icon: 'error',
                });
              }


            }).catch(error => {
              Swal.fire({
                title: 'Connection Failed!',
                text: error,
                icon: 'error',
              });
            })

        });

      }


    });



  }


  putPolicyOnHold(policy_no: string) {
    console.log("putPolicyOnHold ===> " + policy_no)

    let requestPayload = {
      "policy_sequence": policy_no
    }


    Swal.showLoading()
    this.service.holdPolicy(requestPayload)
      .toPromise()
      .then((res: any) => {
        Swal.close()

        if (res.responseCode == "000") {
          Swal.fire({
            title: 'Successful',
            text: res.responseMessage,
            icon: 'success',

          }).then((result: SweetAlertResult) => {
            if (result.value) {
              this.service.swalAlart.showLoading();
              this.onMenuFilter('today');
            }
          })



        } else {
          Swal.fire({
            title: 'Failed',
            text: res.responseMessage,
            icon: 'error',
          });
        }


      }).catch(error => {
        Swal.fire({
          title: 'Connection Failed!',
          text: error,
          icon: 'error',
        });
      })

  }



  reactivatePolicy(policy_no: string) {

    const dialogRef = this.dialog.open(TravelPolicyExtension, {
      disableClose: true,
      width: '350px',
      data: {
        dateChange: 'departure'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(moment(result.data.start_date).format('DD/MM/YYYY'))

      let requestPayload = {
        "policy_sequence": policy_no,
        "inception_date": moment(result.data.start_date).format('DD/MM/YYYY')
      }

      Swal.showLoading()
      this.service.reactivatePolicy(requestPayload)
        .toPromise()
        .then((res: any) => {
          Swal.close()

          if (res.responseCode == "000") {
            Swal.fire({
              title: 'Successful',
              text: res.responseMessage,
              icon: 'success',

            }).then((result: SweetAlertResult) => {
              if (result.value) {
                this.service.swalAlart.showLoading();
                this.onMenuFilter('today');
              }
            })

          } else {
            Swal.fire({
              title: 'Failed',
              text: res.responseMessage,
              icon: 'error',
            });
          }


        }).catch(error => {
          Swal.fire({
            title: 'Connection Failed!',
            text: error,
            icon: 'error',
          });
        })

    });





  }


  payPremium(prevPolSequence, policy_sequence, convertedPremium) {
    const selectedQuote = this.policies.find((r: any) => r.policy_sequence == prevPolSequence);

    this.choosePaymentMethod(selectedQuote, policy_sequence, convertedPremium);

  }

  //choose payment method
  choosePaymentMethod(selectedQuote, policy_sequence, convertedPremium) {
    console.log("selectedQuote ===> " + JSON.stringify(selectedQuote))
    console.log("convertedPremium ===> " + convertedPremium)

    let payload: any = {
      policy_sequence: policy_sequence,
      currency_code: 'GHS',
      status_of_client: 'new'
    }

    if (this.userdata.role == 'agent' || this.userdata.role == 'broker-admin')
      payload = {
        ...payload,
        agent_code: this.userdata.code,
        client_code: selectedQuote.client_code,
        policy_sequence: policy_sequence
      };
    else
      payload = {
        ...payload,
        client_code: this.userdata.code
      };

    const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
      disableClose: true,
      width: '350px',
      data: {
        quotePayload: payload,
        method: this.selectedMethod,
        total_premium: convertedPremium,
        policy_lob: "50"
      }
    });

    console.log("quotePayload ===> " + JSON.stringify(payload))

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        this.selectedMethod = result.data.method;

        if (this.selectedMethod)
          this.confirmPin(policy_sequence, payload, convertedPremium);

      }
    });
  }



  //confirm login pin to continue
  confirmPin(policy_sequence, payload, convertedPremium) {
    let currentUser = this.service.currentUser();
    if (currentUser.role == "broker-user") {
      this.loginAsBrokerUser(policy_sequence, payload, convertedPremium)

    } else if (currentUser.role == "broker-admin") {
      this.loginAsBrokerAdmin(policy_sequence, payload, convertedPremium)

    }

    else {
      this.loginAsAgentClient(policy_sequence, payload, convertedPremium)
    }
  }

  loginAsAgentClient(policy_sequence, payload, convertedPremium) {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          if (currentUser.role == "client") {
            loginPayload = {
              phone: currentUser.phone,
              pin: result.value,
              module: 'client'
            }

          } else if (currentUser.role == "agent") {
            loginPayload = {
              agent_code: currentUser.code,
              pin: result.value,
              module: 'agent'
            }

          } else { }

          swal.showLoading()
          this.service.webLogin(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog(policy_sequence, payload, convertedPremium);
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin(policy_sequence, payload, convertedPremium) }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin(policy_sequence, payload, convertedPremium) }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });

  }


  loginAsBrokerUser(policy_sequence, payload, convertedPremium) {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          loginPayload = {
            broker_user_code: currentUser.code,
            broker_user_pin: result.value,
            module: 'broker-user'
          }

          swal.showLoading()
          this.service.webLoginAsBrokerUser(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog(policy_sequence, payload, convertedPremium);
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin(policy_sequence, payload, convertedPremium) }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin(policy_sequence, payload, convertedPremium) }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });
  }

  loginAsBrokerAdmin(policy_sequence, payload, convertedPremium) {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          loginPayload = {
            broker_admin_email: currentUser.email,
            broker_admin_pin: result.value,
            module: 'broker-admin'
          }

          swal.showLoading()
          this.service.webLoginAsBrokerAdmin(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog(policy_sequence, payload, convertedPremium);
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog(policy_sequence, payload, convertedPremium);
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin(policy_sequence, payload, convertedPremium) }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin(policy_sequence, payload, convertedPremium) }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });
  }





  //finalized momo payment
  //open cash Payment Dialog
  openMomoPaymentDialog(selectedQuote, payload, convertedPremium) {

    const dialogRef = this.dialog.open(MomoPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: {
        quotePayload: payload,
        total_premium: convertedPremium,
        policy_lob: "50"
      }
    });
  }
  //open card Payment Dialog
  openCardPaymentDialog(selectedQuote, payload, convertedPremium) {
    const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: {
        quotePayload: payload,
        total_premium: convertedPremium,
        policy_lob: "50"
      }
    });

    // dialogRef.afterClosed().subscribe(result => {

    //   if (result.hasOwnProperty('data')) {
    //     this.confirmedQuote = result.data.quotePayload.module == 'PAID';

    //     if (this.confirmedQuote) {
    //       setTimeout(() => {
    //         this.router.navigate(['transactions']);
    //       }, 3000)
    //     }
    //   }
    // });

  }
  //open cash Payment Dialog
  openCashPaymentDialog(policy_sequence, payload, convertedPremium) {
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: {
        quotePayload: payload,
        total_premium: convertedPremium,
        policy_lob: "50"
      }
    });

  }
  //open Cheque Payment Dialog
  openChequePaymentDialog(selectedQuote, payload, convertedPremium) {
    const dialogRef = this.dialog.open(ChequePaymentComponent, {
      disableClose: true,
      width: '600px',
      data: {
        quotePayload: payload,
        total_premium: convertedPremium,
        policy_lob: "50"
      }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result.hasOwnProperty('data')) {
    //     this.confirmedQuote = result.data.quotePayload.module == 'PAID';

    //     if (this.confirmedQuote) {
    //       setTimeout(() => {
    //         this.router.navigate(['transactions']);
    //       }, 3000)
    //     }
    //   }
    // });

  }


  //trigger the snackbar
  alertSnackBar(message: string, action: string, duration: number = 5000) {
    this._snackBar.open(message, action, {
      duration: duration,
    });
  }


}
